import { t } from 'react-switch-lang';

import StaffOffdayService from '../../staffOffday/staffOffday.service';
import { displayDateOnly } from '../../../util/FormaterHelper';

const generateTableColumns = () => [
  {
    name: t('Staff Name'),
    cell: row => row.User ? row.User.fullName : '',
    sortable: false,
    center: false,
    grow: 1,
  },
  {
    name: t('Offday Type'),
    selector: 'offdayType',
    sortable: false,
    center: true,
    grow: 1,
  },
  {
    name: t('Date'),
    selector: 'dateAt',
    cell: row => displayDateOnly(row.dateAt),
    sortable: false,
    center: true,
    grow: 1,
  },
  {
    name: t('Status'),
    selector: 'status',
    cell: row => StaffOffdayService.getStatusText(row.status),
    sortable: false,
    center: true,
    grow: 1,
  }
];

export default generateTableColumns;
