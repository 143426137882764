import dayjs from 'dayjs';

/**
 * get dayOfWeek text
 * @param {*} dayOfWeek
 */
export const dayOfWeekText = (dayOfWeek) => {
  switch (dayOfWeek) {
    case 1: return 'Senin';
    case 2: return 'Selasa';
    case 3: return 'Rabu';
    case 4: return 'Kamis';
    case 5: return 'Jumat';
    case 6: return 'Sabtu';
    case 0: return 'Minggu';

    default:
      return dayOfWeek;
  }
};

/**
 * display date time format for ui
 * @param {*} dateTime
 * @returns
 */
export const displayDateTime = (dateTime) => {
  try {
    // assume the dateTime is UTC time
    const result = dayjs(dateTime).utc().format('DD/MM/YYYY HH:mm');

    return result !== 'Invalid Date' ? result : '-';
  } catch (error) {
    console.error(error);
    return '';
  }
}

/**
 * display date only format for ui
 * @param {*} dateTime
 * @returns
 */
 export const displayDateOnly = (dateTime, useUtc = false) => {
  try {
    // assume the dateTime is UTC time
    const result = useUtc ? dayjs(dateTime).utc().format('DD/MM/YYYY') : dayjs(dateTime).format('DD/MM/YYYY');

    return result !== 'Invalid Date' ? result : '-';
  } catch (error) {
    console.error(error);
    return '';
  }
}

/**
 * display time only format for ui
 * @param {*} dateTime
 * @returns
 */
 export const displayTimeOnly = (dateTime) => {
  try {
    // assume the dateTime is UTC time
    const result = dayjs(dateTime).utc().format('HH:mm');

    return result !== 'Invalid Date' ? result : '-';
  } catch (error) {
    console.error(error);
    return '';
  }
}
