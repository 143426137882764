import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { Info } from 'react-feather';
import { t } from 'react-switch-lang';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import nl2br from 'react-nl2br';

import StaffOffdayService from '../../staffOffday/staffOffday.service';
import * as FormaterHelper from '../../../util/FormaterHelper';
import { showConfirmationAlert, showErrorAlert, showSuccessAlert} from '../../../app/alertResponse.handler';
import { updateStaffOffday } from '../../staffOffday/staffOffdaySlice';
import * as CONSTANTS from '../../../app/constants';

const EditModal = ({ isOpen, staffOffday, onClose })  => {
  const [offdayStat, setOffdayStat] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const loadStat = async () => {
      try {
        if (staffOffday && staffOffday.userId) {
          const stat = await StaffOffdayService.getStaffOffdayStat(staffOffday.userId);
          if (stat) {
            setOffdayStat(stat);
          }
        }
      } catch (error) {

      }
    }

    loadStat();
  }, [staffOffday]);

  const onSubmitHandler = (status) => {
    const confirmMessage = status === CONSTANTS.STAFF_OFFDAY_APPROVED ? 'Are you sure to approve this request?' : 'Are you sure to decline this request?';
    const action = status === CONSTANTS.STAFF_OFFDAY_APPROVED ? 'approve' : 'decline';
    showConfirmationAlert(confirmMessage)
      .then(({isConfirmed}) => {
        if (isConfirmed) {
          dispatch(updateStaffOffday({ id: staffOffday.id, action })).then((payload) => {
            const { error } = payload;
            if (error) {
              showErrorAlert(error, { defaultMessage: 'Update data is failed' });
            } else {
              showSuccessAlert('Data has been changed successfully')
                .then(() => {
                  onClose(true);
                });
            }
          })
          .catch((error) => {
            // TODO: need to show error here
            console.log(error);
          });
        }
      });
  }

  const showOffdayStat = () => {
    if (offdayStat) {
      const remainingOffday = offdayStat.numberOfLeavePerYear - offdayStat.personalOffdayStat?.approved;
      return (
        <Row>
          <Col sm="12" className="mt-3">
            <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
            <div className="float-left">
              <b>{t('Total Remaining Leave')}</b><br />
              {`${remainingOffday} ${t('Days')}`}
            </div>
          </Col>
        </Row>
      );
    }
  }

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader toggle={onClose}>
          {t('Staff Offday')}
        </ModalHeader>
        <ModalBody className="p-4">
          { staffOffday &&
          <>
            <Row>
              <Col sm="12" className="mt-3">
                <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                <b>{t('Staff Name')}</b><br />
                {staffOffday.User?.fullName}
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="mt-3">
                <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                <div className="float-left">
                  <b>{t('Offday Type')}</b><br />
                  <mark>{staffOffday.offdayType}</mark><br />
                  {staffOffday.offdayType === 'DL' && <em className="text-secondary">Dinas Luar tidak mengurangi jatah cuti pegawai</em>}
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="mt-3">
                <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                <b>{t('Date')}</b><br />
                {`${FormaterHelper.dayOfWeekText(dayjs.utc(staffOffday.dateAt).day())}, ${dayjs.utc(staffOffday.dateAt).format('DD/MM/YYYY')}`}
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="mt-3">
                <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                <div className="float-left">
                  <b>{t('Description')}</b><br />
                  {nl2br(staffOffday.description)}
                </div>
              </Col>
            </Row>
            {showOffdayStat()}
          </>
          }
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => onSubmitHandler(CONSTANTS.STAFF_OFFDAY_DECLINED)}>{t('buttons_Decline')}</Button>
          <Button color="success" onClick={() => onSubmitHandler(CONSTANTS.STAFF_OFFDAY_APPROVED)}>{t('buttons_Approve')}</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default EditModal;
