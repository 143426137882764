import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, CardBody, Row, Col, Form, FormGroup, Input, Label, Button} from 'reactstrap'
import { translate } from 'react-switch-lang';
import { Redirect } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { apiUrl } from '../../app/config';
import { setJwt, setError } from './authSlice';

import { DangerAlert } from '../../components/ui-kits/alert';

import BgLeftImageAsset from '../../assets/images/login/2.jpg';
import LogoImageAsset from '../../assets/app-images/logo/logo-web-login.png';

const LoginWithBgImage = (props)  => {
  const usernameRef = useRef();
  const passwordRef = useRef();

  const [isDisableForm, setIsDisableForm] = useState(false);
  const [isShowPasswordInput, setShowPasswordInput] = useState(false);
  const [username, setUsername] = useState('');
  const [password,setPassword] = useState('');

  const errorMessage = useSelector(state => state.auth.error);
  const authenticated = useSelector(state => state.auth.jwtToken !== undefined && state.auth.jwtToken !== null);

  const dispatch = useDispatch();

  useEffect(() => {
    usernameRef.current.focus();
  }, [])

  /**
   * Toggle Show Password Input
   */
  const HideShowPassword  = () => {
    setShowPasswordInput(!isShowPasswordInput);
  }

  /**
   * redirect to app page
   */
  const redirectToApp = () => {
    if (authenticated) {
      return <Redirect to={`${process.env.PUBLIC_URL}/`} />;
    }
  }

  /**
   * execute login action or call api for authentication
   */
  const loginHandler = async () => {
    try {
      dispatch(setError(null));
      setIsDisableForm(true);

      // TODO: move to reduxTruncAsync
      const url = `${apiUrl}/auth/authenticate`;
      const result = await axios.post(url, { username, password });

      dispatch(setJwt(result.data.access_token));

      return true;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(setError('Username or password is wrong!'));
      } else {
        toast.error('An error occured on server. Please try login or contact admin')
      }
    }

    setIsDisableForm(false);
  }

  /**
   * show alert component in ui
   */
  const showAlertView = () => {
    if (errorMessage) {
      return (
        <DangerAlert
          id="login-error-alert"
          text={errorMessage}
          isOpen={true}
          onClose={() => { dispatch(setError(null)) }}
        />
      );
    }
  }

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      if (username === '') {
        usernameRef.current.focus();
      } else if (password === '') {
        passwordRef.current.focus();
      } else {
        loginHandler();
      }
    }
  }

  return (
    <Container fluid={true}>
      {redirectToApp()}

      <Row>
        <Col xl="7" className="b-center bg-size" style={{ backgroundImage: `url(${BgLeftImageAsset})`,backgroundSize:"cover",backgroundPosition:"center",display:"block" }}>
          <img className="bg-img-cover bg-center" style={{ display:"none" }} src={BgLeftImageAsset} alt="loginpage"/>
        </Col>
        <Col xl="5" className="p-0">
          <div className="login-card">
            <div>
              <div>
                <a className="logo text-left" href="#javascript">
                  <img className="img-fluid for-light" src={LogoImageAsset} alt="looginpage"/>
                </a>
              </div>
              <Card>
                <CardBody className="login-main login-tab">
                  <h4>{props.t("Sign In")}</h4>
                  <p>{props.t("Enter your username & password to login")}</p>
                  { showAlertView() }
                  <Form className="theme-form">
                    <FormGroup>
                      <Label className="col-form-label">{props.t('Username')}</Label>
                      <Input
                        innerRef={usernameRef}
                        type="text"
                        placeholder={props.t('Input Username')}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        disabled={isDisableForm}
                        onKeyPress={handleKeyPress}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label">{props.t('Password')}</Label>
                      <Input
                        innerRef={passwordRef}
                        type={isShowPasswordInput ? "text" : "password" }
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder={props.t('Input Password')}
                        disabled={isDisableForm}
                        onKeyPress={handleKeyPress}
                      />
                      <div className="show-hide" onClick={() => HideShowPassword()}><span className={isShowPasswordInput ? "" : "show"}></span></div>
                    </FormGroup>
                    <FormGroup className="mb-0 mt-3">
                      <Button disabled={isDisableForm} onClick={loginHandler} color="primary" className="btn-block">{props.t('buttons_Login')}</Button>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Col>
      </Row>

      <ToastContainer />
    </Container>
  );
}

export default translate(LoginWithBgImage);
