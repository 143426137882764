import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu, ListGroup, ListGroupItem } from 'reactstrap';
import { t } from 'react-switch-lang';

import ImageViewerModal from './modals/ImageViewerModal';
import PdfViewerModal from './modals/PdfViewerModal';
import { LoadingInfo } from '../../components/ui-kits';
import AttachmentService from './attachment.service';
import * as FileSaverHelper from '../../util/FileSaverHelper';

const ContextMenu = ({ id, filename, originalname, canDownload, canRemove, onDownload, onRemove }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isShowDropdownMenu, setIsShowDropdownMenu] = useState(false);

  useEffect(() => {
    setIsShowDropdownMenu(canRemove || canDownload);
  }, [canRemove, canDownload]);

  const toggle = (e) => {
    // avoid triggering parent onclick
    e.stopPropagation();
    setDropdownOpen(prevState => !prevState);
  }

  return (
    <>
      { isShowDropdownMenu &&
        <Dropdown isOpen={dropdownOpen} size="sm" toggle={toggle} className="float-right">
          <DropdownToggle color="primary">
            <span className="fa fa-ellipsis-v"></span>
          </DropdownToggle>
          <DropdownMenu right>
            { canDownload && <DropdownItem onClick={() => onDownload(filename, originalname)}>{t('buttons_Download')}</DropdownItem> }
            { canRemove && <DropdownItem onClick={() => onRemove(id)}>{t('buttons_Delete')}</DropdownItem> }
          </DropdownMenu>
        </Dropdown>
      }
    </>
  );
}

const AttachmentList = ({ attachments, module, moduleId, canRemove, onRemove, loading }) => {
  const [isOpenImageViewModal, setIsOpenImageViewModal] = useState(false);
  const [isOpenPdfViewModal, setIsOpenPdfViewModal] = useState(false);
  const [filename, setFilename] = useState(null);
  const [isLoadingAPI, setIsLoadingAPI] = useState(false);

  const onCloseImageViewModal = () => {
    setIsOpenImageViewModal(false);
  }

  const onClosePdfViewModal = () => {
    setIsOpenPdfViewModal(false);
  }

  const onViewHandler = (mimetype, filename) => {
    setFilename(filename);

    if (mimetype === 'application/pdf') {
      setIsOpenPdfViewModal(true);
    } else {
      setIsOpenImageViewModal(true);
    }
  }

  const onDownloadButtonHandler = async (filename, originalname) => {
    try {
      setIsLoadingAPI(true);
      const result = await AttachmentService.downloadAttachment(module, moduleId, filename);
      FileSaverHelper.saveFileAs(result, originalname);
    } catch (error) {
      console.log(error);
    }
    setIsLoadingAPI(false);
  }

  const onRemoveButtonHandler = (id) => {
    if (onRemove) {
      onRemove(id);
    }
  }

  const showAttachmentList = () => {
    if (attachments.length === 0) {
      return (
        <ListGroup className="mb-3 col-12">
          <ListGroupItem className="list-group-item-action">
          <b>{loading ? t('Loading') : t('No attachment')}</b>
          </ListGroupItem>
        </ListGroup>
      )
    }

    return (
      <ListGroup className="mb-3 col-12">
        {attachments.map(({ filename, mimetype, originalname, id }, index) =>
          <ListGroupItem className="cursor-pointer list-group-item-action" key={id || index} onClick={() => onViewHandler(mimetype, filename)}>
            <i className="fa fa-file"></i>{originalname}
            <ContextMenu
              id={id}
              filename={filename}
              originalname={originalname}
              canRemove={canRemove}
              canDownload={true}
              onDownload={(filename, originalname) => onDownloadButtonHandler(filename, originalname)}
              onRemove={(id) => onRemoveButtonHandler(id)}
            />
          </ListGroupItem>
        )}
      </ListGroup>
    )
  }

  return (
    <>
      {showAttachmentList()}
      <ImageViewerModal isOpen={isOpenImageViewModal} onClose={onCloseImageViewModal} module={module} moduleId={moduleId} filename={filename} />
      <PdfViewerModal isOpen={isOpenPdfViewModal} onClose={onClosePdfViewModal} module={module} moduleId={moduleId} filename={filename} />
      <LoadingInfo loading={isLoadingAPI} />
    </>
  )
}

export default AttachmentList;
