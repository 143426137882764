import React from 'react';
import AlertWithIcon from './AlertWithIcon';
import { ThumbsDown } from 'react-feather';

const DangerAlert = (props) => {
  return (
    <AlertWithIcon
      color="danger dark"
      icon={<ThumbsDown />}
      { ...props }
    />
  )
}

export default DangerAlert;
