import React from 'react';
import { Button, Card, CardBody, CardFooter, Container, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { addTemple } from './templeSlice';
import { Breadcrumb } from '../../components/layout';
import { DistrictInput, ReligionInput, TempleTypeInput } from '../../components/ui-kits';
import { showErrorAlert, showSuccessAlert } from '../../app/alertResponse.handler';

const AddTemple = ({ t }) => {
  const { register, reset, watch, handleSubmit, errors } = useForm();
  const watchReligionName = watch("religionName", '');
  const dispatch = useDispatch();

  const onSubmitHandler = (data, e) => {
    dispatch(addTemple(data)).then(({ error }) => {
      if (error) {
        showErrorAlert(error, { defaultMessage: 'Add data is failed' })
      } else {
        showSuccessAlert('Data has been created successfully')
          .then(() => {
            reset(); // reset after form submit
          });
      }
    })
    .catch((error) => {
      // TODO: need to show error here
      console.log(error);
    });
  }

  return (
    <React.Fragment>
      <Breadcrumb parent={t('Temple')} headerTitle={t('Add Temple')} title={t('Add Temple')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
                  <FormGroup className="form-row">
                    <Label htmlFor="name">{t('Religion')} <span className="text-danger">(*)</span></Label>
                    <ReligionInput
                        className="form-control btn-square"
                        name="religionName"
                        innerRef={
                          register({
                            required: t('This field must be filled')
                          })
                        }
                      />
                    <span color="danger">{errors.religionName && errors.religionName.message}</span>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label htmlFor="code">{t('Temple Type')} <span className="text-danger">(*)</span></Label>
                    <TempleTypeInput
                      className="form-control btn-square"
                      name="templeType"
                      religionName={watchReligionName}
                      innerRef={
                        register({
                          required: t('This field must be filled')
                        })
                      }
                    />
                    <span color="danger">{errors.templeType && errors.templeType.message}</span>
                  </FormGroup>

                  <FormGroup className="form-row">
                    <Label htmlFor="name">{t('Temple Name')} <span className="text-danger">(*)</span></Label>
                    <Input
                      className="form-control btn-square"
                      name="name"
                      type="text"
                      innerRef={
                        register({
                          required: t('This field must be filled')
                        })
                      }
                    />
                    <span color="danger">{errors.name && errors.name.message}</span>
                  </FormGroup>

                  <FormGroup className="form-row">
                    <Label htmlFor="code">{t('District')} <span className="text-danger">(*)</span></Label>
                    <DistrictInput
                      className="form-control btn-square"
                      name="district"
                      innerRef={
                        register({
                          required: t('This field must be filled')
                        })
                      }
                    />
                    <span color="danger">{errors.district && errors.district.message}</span>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label htmlFor="code">{t('Sub-district')} <span className="text-danger">(*)</span></Label>
                    <Input
                      className="form-control btn-square"
                      name="subDistrict"
                      type="text"
                      innerRef={
                        register({
                          required: t('This field must be filled')
                        })
                      }
                    />
                    <span color="danger">{errors.subDistrict && errors.subDistrict.message}</span>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label htmlFor="code">{t('Address')}</Label>
                    <Input
                      className="form-control btn-square"
                      name="address"
                      type="text"
                      innerRef={register}
                    />
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label htmlFor="code">Nama Pengelola</Label>
                    <Input
                      className="form-control btn-square"
                      name="managerName"
                      type="text"
                      innerRef={register}
                    />
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label htmlFor="code">{t('Phone')}</Label>
                    <Input
                      className="form-control btn-square"
                      name="phone"
                      type="text"
                      innerRef={register}
                    />
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label htmlFor="code">{t('Holidays')}</Label>
                    <Input
                      className="form-control btn-square"
                      name="holidays"
                      type="textarea"
                      innerRef={register}
                    />
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label htmlFor="code">{t('History')}</Label>
                    <Input
                      className="form-control btn-square"
                      name="historyText"
                      type="textarea"
                      innerRef={register}
                    />
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label htmlFor="code">{t('Google Map Link')}</Label>
                    <Input
                      className="form-control btn-square"
                      name="gmapLink"
                      type="text"
                      innerRef={register}
                    />
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button color="secondary" className="mr-2" onClick={() => reset()}>{t('buttons_Cancel')}</Button>
                <Button color="primary" onClick={handleSubmit(onSubmitHandler)}>{t('buttons_Save')}</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default translate(AddTemple);
