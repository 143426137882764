import { t } from 'react-switch-lang';
import dayjs from 'dayjs';
import * as FormaterHelper from '../../../util/FormaterHelper';

const generateTableColumns = () => {
  return [
    {
      name: t('Date'),
      selector: (row) => `${FormaterHelper.dayOfWeekText(dayjs.utc(row.dateAt).day())}, ${dayjs.utc(row.dateAt).format('DD-MM-YYYY')}`,
      sortable: false,
      center: false,
      grow: 1,
    },
    {
      name: 'Nama Shift',
      selector: (row) => row.shiftName ? row.shiftName : '',
      sortable: false,
      center: false,
      grow: 1,
    },
    {
      name: 'Jam Masuk',
      selector: (row) => !row.isOffday ? dayjs.utc(row.workStartTime).format('HH:mm') : '-',
      sortable: false,
      center: false,
      width: '70px'
    },
    {
      name: 'Scan Masuk',
      selector: (row) => !row.isOffday && row.attendanceStartTime ? dayjs.utc(row.attendanceStartTime).format('HH:mm') : '-',
      sortable: false,
      center: false,
      width: '70px'
    },
    {
      name: 'Jam Keluar',
      selector: (row) => !row.isOffday ? dayjs.utc(row.workEndTime).format('HH:mm') : '-',
      sortable: false,
      center: false,
      width: '70px'
    },
    {
      name: 'Scan Keluar',
      selector: (row) => !row.isOffday && row.attendanceEndTime ? dayjs.utc(row.attendanceEndTime).format('HH:mm') : '-',
      sortable: false,
      center: false,
      width: '70px'
    },
    {
      name: 'Durasi Kerja',
      selector: (row) => !row.isOffday && !row.isDL && row.workDurationText ? row.workDurationText : '-',
      sortable: false,
      center: false,
      width: '70px'
    },
    {
      name: 'Libur Cuti',
      selector: (row) => row.isOffday && row.offdayType === 'personalOffday' ? <i className="fa fa-check"></i> : '',
      sortable: false,
      center: false,
      width: '70px'
    },
    {
      name: 'Libur Umum',
      selector: (row) => row.isOffday && row.offdayType === 'holiday' ? <i className="fa fa-check"></i> : '',
      sortable: false,
      center: false,
      width: '70px'
    },
    {
      name: 'Libur Rutin',
      selector: (row) => row.isOffday && row.offdayType === 'weeklyOffday' ? <i className="fa fa-check"></i> : '',
      sortable: false,
      center: false,
      width: '70px'
    },
    {
      name: 'Keterangan',
      selector: (row) => {
        if (row.isOffday) {
          return row.offdayDescription;
        }

        if (row.isDL) {
          return 'DL';
        }
      },
      sortable: false,
      center: false,
      grow: 1,
    },
  ];
};

export default generateTableColumns;
