import React, { useEffect, useState, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'sweetalert2';

import generateTableColumns from './settings/table-column';
import { Breadcrumb } from '../../components/layout';
import { DataTableWithPagination, LoadingInfo } from '../../components/ui-kits';
import { fetchUsers, activateUser, deactivateUser } from './userSlice';
import { EditPasswordModal, EditUserModal } from './modals';
import UserSearchFilter from './UserSearchFilter';
import * as CONSTANTS from '../../app/constants';

const UserIndex = (props) => {
  const users = useSelector(state => state.user.users);
  const totalUser = useSelector(state => state.user.totalUser);
  const isLoadingAPI = useSelector(state => state.user.loading);
  const loginUser = useSelector(state => state.auth.userInfo);

  const [isOpenEditPasswordModal, setIsOpenEditPasswordModal] = useState(false);
  const [isOpenEditUserModal, setIsOpenEditUserModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const [page, setPage] = useState(1);
  const [searchFilters, setSearchFilters] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsers({ page, pagesize: CONSTANTS.NUMBER_PER_PAGE, version: 2, ...searchFilters }));
  }, [dispatch, page, searchFilters]);

  const editHandler = (row) => {
    setSelectedRow(row);
    setIsOpenEditUserModal(true);
  }

  const editUserModalOnCloseHandler = () => {
    setIsOpenEditUserModal(false);
  }

  const editPasswordHandler = (row) => {
    setSelectedRow(row);
    setIsOpenEditPasswordModal(true);
  }

  const editPasswordModalOnCloseHandler = () => {
    setIsOpenEditPasswordModal(false);
  }

  const onRowClicked = (row) => {
    editHandler(row);
  }

  const tableColumns = useMemo(() => {
    const activateHandler = (row) => {
      SweetAlert.fire({
        title: props.t('Are you sure to activate this user?'),
        cancelButtonText: props.t('buttons_Cancel'),
        confirmButtonText: props.t('buttons_Activate'),
        reverseButtons: true,
        showCancelButton: true,
      }).then(({isConfirmed}) => {
        if (isConfirmed) {
          dispatch(activateUser(row.id));
        }
      });
    }

    const deactivateHandler = (row) => {
      SweetAlert.fire({
        title: props.t('Are you sure to deactivate this user?'),
        cancelButtonText: props.t('buttons_Cancel'),
        confirmButtonText: props.t('buttons_Deactivate'),
        reverseButtons: true,
        showCancelButton: true,
      }).then(({isConfirmed}) => {
        if (isConfirmed) {
          dispatch(deactivateUser(row.id));
        }
      });
    }

    return generateTableColumns(loginUser, activateHandler, deactivateHandler, editPasswordHandler);
  }, [dispatch, props, loginUser])

  const onSearchHandler = (filters) => {
    setSearchFilters(filters);
    setPage(1);
  }

  return (
    <React.Fragment>
      <Breadcrumb parent={props.t('User')} headerTitle={props.t('User Management')} title={props.t('Index')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <UserSearchFilter onSearch={onSearchHandler} />
            <Card>
              <CardBody>
                <DataTableWithPagination
                  data={users}
                  columns={tableColumns}
                  totalRow={totalUser}
                  onPageChange={(page) => setPage(page)}
                  onRowClicked={onRowClicked}
                  highlightOnHover={true}
                  pointerOnHover={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <EditUserModal isOpen={isOpenEditUserModal} user={selectedRow} onClose={editUserModalOnCloseHandler} />
        <EditPasswordModal isOpen={isOpenEditPasswordModal} user={selectedRow} onClose={editPasswordModalOnCloseHandler} />
      </Container>
      <LoadingInfo loading={isLoadingAPI} />
    </React.Fragment>
  )
}

export default translate(UserIndex);
