import React from 'react';
import { Input } from 'reactstrap';
import { useSelector } from 'react-redux';
import { t } from 'react-switch-lang';

const PositionInput = (props) => {
  const { excludeId, defaultLabel, ...otherProps } = props;

  const positions = useSelector((state) => {
    if (Array.isArray(state.position.positions)) {
      // exclude item if excludeId is defined
      const filtered = Array.isArray(state.position.positions)
        ? state.position.positions.filter(item => !excludeId || item.id !== excludeId) : [];

      // https://stackoverflow.com/questions/53420055/error-while-sorting-array-of-objects-cannot-assign-to-read-only-property-2-of/53420326
      return filtered.slice().sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (b.name > a.name) {
          return -1;
        }
        return 0;
      });
    }

    return [];
  });

  return (
    <Input
      type="select"
      { ...otherProps }
    >
      <option key={0} value="">{defaultLabel || t('Please Select')}</option>
      {positions.map(item =>
        <option key={item.id} value={item.id}>{item.name}</option>
      )};
    </Input>
  )
}

export default PositionInput;
