import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { translate } from 'react-switch-lang';

import { LetterMarkDoneModal as MarkDownModal, LetterForwardModal as ForwardModal } from './';
import { HistoryModal } from '../../letter/modals/';
import { LETTER_DONE } from '../../../app/constants';
import AttachmentService from '../../attachments/attachment.service';
import DetailLetter from '../../letter/DetailLetter';

const EditModal = ({ isOpen, letter, onClose, t })  => {
  const [isLoadingAttachment, setIsLoadingAttachment] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [isMarkDoneModalOpen, setIsMarkDoneModalOpen] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [isForwardModalOpen, setIsForwardModalOpen] = useState(false);

  useEffect(() => {
    const fetchAttachments = async () => {
      const { id } = letter ? letter : {};

      if (id) {
        setIsLoadingAttachment(true);
        try {
          const result = await AttachmentService.fetchAttachments('letter', id);
          setAttachments(result);
        } catch (error) {
          // TODO: do we need to show error here
        }

        setIsLoadingAttachment(false);
      }
    }

    fetchAttachments();
  }, [letter]);

  const onMarkDoneClickHandler = () => {
    setIsMarkDoneModalOpen(true);
  }

  const markDownModalOnCloseHandler = (success) => {
    setIsMarkDoneModalOpen(false);

    if (success) {
      onClose(true);
    }
  }

  const onHistoryClickHandler = () => {
    setIsHistoryModalOpen(true);
  }

  const historyModalOnCloseHandler = (success) => {
    setIsHistoryModalOpen(false);
  }

  const onForwardClickHandler = () => {
    setIsForwardModalOpen(true);
  }

  const forwardModalOnCloseHandler = (success) => {
    setIsForwardModalOpen(false);

    if (success) {
      onClose(true);
    }
  }

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={onClose} size="lg" centered style={{maxWidth: '1000px', width: '80%'}}>
        <ModalHeader toggle={onClose}>
          {t('Letter Disposition')}
        </ModalHeader>
        <ModalBody className="p-4">
          <DetailLetter letter={letter} attachments={attachments} isLoadingAttachment={isLoadingAttachment} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClose}>{t('buttons_Close')}</Button>
          <Button color="info" onClick={onHistoryClickHandler}>{t('buttons_Letter_Activity_Log')}</Button>
          { letter?.status !== LETTER_DONE &&
          <React.Fragment>
            <Button color="primary" onClick={onForwardClickHandler}>{t('buttons_Forward')}</Button>
            <Button color="success" onClick={onMarkDoneClickHandler}>{t('buttons_Mark_Done')}</Button>
          </React.Fragment>
          }
        </ModalFooter>
      </Modal>

      <MarkDownModal isOpen={isMarkDoneModalOpen} id={letter && letter.id} onClose={markDownModalOnCloseHandler} />
      <HistoryModal isOpen={isHistoryModalOpen} id={letter && letter.id} onClose={historyModalOnCloseHandler} />
      <ForwardModal isOpen={isForwardModalOpen} id={letter && letter.id} onClose={forwardModalOnCloseHandler} />
    </React.Fragment>
  );
}

export default translate(EditModal);
