import React from 'react';
import { Input } from 'reactstrap';
import { t } from 'react-switch-lang';

const StaffOffdayStatusInput = (props) => {
  const items = React.useMemo(() => {
    return [{
      id: 1,
      name: t('Approved')
    }, {
      id: 2,
      name: t('Declined')
    }];
  }, []);

  return (
    <Input
      type="select"
      { ...props }
    >
      <option key={0} value="0">{t('Please Select')}</option>
      {items.map(item =>
        <option key={item.id} value={item.id}>{item.name}</option>
      )};
    </Input>
  )
}

export default StaffOffdayStatusInput;
