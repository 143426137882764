import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { t } from 'react-switch-lang';
import { Info } from 'react-feather';

import { displayDateTime } from '../../../util/FormaterHelper';

const DetailModal = ({ isOpen, letterNumber, onClose })  => {
  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={onClose} size="lg" centered>
        <ModalBody className="p-4">
          { letterNumber &&
            <>
              <Row>
                <Col sm="8">
                <p><b>{t('Letter Number')}</b> { letterNumber.registrationNumber }</p>
                </Col>
                <Col sm="4" className="text-right">
                <div className="border-bottom">{ displayDateTime(letterNumber.createdAt) }</div>
                <small>{t('Input Date')}</small>
                </Col>
              </Row>

              <Row>
                <Col sm="12" className="mt-3">
                  <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                  <b>{t('Subject')}</b><br />
                  {letterNumber.subject}
                </Col>
              </Row>

              <Row>
                <Col sm="12" className="mt-3">
                  <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                  <b>{t('Receiver')}</b><br />
                  {letterNumber.receiverName}
                </Col>
              </Row>

              <Row>
                <Col sm="12" className="mt-3">
                  <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                  <b>{t('Creator')}</b><br />
                  {letterNumber.user.fullName}
                </Col>
              </Row>
            </>
          }
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClose}>{t('buttons_Close')}</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default DetailModal;
