/**
 * Configuration for app
 */
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

// Allowed mime type for attachment
// TODO: image/png, image/jpeg, .xls, .doc, .xlsx, .docx
const attachmentAcceptMimeType = '.pdf, image/png, image/jpeg';

module.exports = {
  apiUrl,
  attachmentAcceptMimeType,
}
