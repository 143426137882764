import React from 'react';
import { Button, Card, CardBody, CardFooter, Container, Col, Form, FormGroup, Label, Row, Input } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';

import { addMeeting } from './meetingSlice';
import { Breadcrumb } from '../../components/layout';
import { showErrorAlert, showHtmlSuccessAlert } from '../../app/alertResponse.handler';

const AddMeeting = ({ t }) => {
  const { register, reset, handleSubmit, control, errors } = useForm();
  const dispatch = useDispatch();

  const onSubmitHandler = (data, e) => {
    dispatch(addMeeting({ ...data })).then((payload) => {
      const { error } = payload;
      if (error) {
        showErrorAlert(error, { defaultMessage: 'Add data is failed' });
      } else {
        const { registrationNumber } = payload.payload;

        // get html message to show
        const htmlMessage = t('Data has been created successfully') + `<br>${t('Registration Number')}: <b>${registrationNumber}</b>`;

        showHtmlSuccessAlert(htmlMessage)
          .then(() => {
            clearForm();
          });
      }
    })
    .catch((error) => {
      // TODO: need to show error here
      console.log(error);
    });
  }

  /**
   * clear form after submit or just reset form
   */
  const clearForm = () => {
    reset();
  }

  return (
    <React.Fragment>
      <Breadcrumb parent={t('Meeting')} headerTitle={t('Add Meeting')} title={t('Add Meeting')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="subject">{t('Meeting Date')} <span className="text-danger">(*)</span></Label>
                    <Col sm="9">
                      <Controller
                        control={control}
                        render={({ onChange, onBlur, value, name, ref }) => (
                          <DatePicker
                            selected={value}
                            onChange={e => onChange(e)}
                            name={name}
                            className="form-control digits"
                            dateFormat="dd/MM/yyyy"
                            />
                        )}
                        name="meetingAt"
                        defaultValue=""
                        rules={{
                          required: t('This field must be filled'),
                        }}
                      />
                      <span className="text-danger">{errors.meetingAt && errors.meetingAt.message}</span>
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="subject">{t('Subject')} <span className="text-danger">(*)</span></Label>
                    <Col sm="9">
                      <Input
                        className="form-control btn-square"
                        name="subject"
                        type="text"
                        maxLength={150}
                        innerRef={
                          register({
                            required: t('This field must be filled')
                          })
                        }
                      />
                      <span color="danger">{errors.subject && errors.subject.message}</span>
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row mb-0">
                    <Label className="col-sm-3 col-form-label" htmlFor="description">{t('Description')} <span className="text-danger">(*)</span></Label><br />
                    <Col sm="9">
                      <Input
                        type="textarea"
                        rows="3"
                        placeholder={t('Input description for the meeting')}
                        name="description"
                        innerRef={
                          register({
                            required: t('This field must be filled')
                          })
                        }
                      />
                      <span color="danger">{errors.description && errors.description.message}</span>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button color="secondary" className="mr-2" onClick={() => clearForm()}>{t('buttons_Cancel')}</Button>
                <Button color="primary" onClick={handleSubmit(onSubmitHandler)}>{t('buttons_Save')}</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default translate(AddMeeting);
