import { Aperture, Award, Bookmark, Book, Coffee, Cpu, Home, Mail, List, Settings, Truck, Users } from 'react-feather';
import { t } from 'react-switch-lang';

const MENUITEMS = [
  {
    menutitle: t('Dashboard'),
    menucontent: t('Home'),
    isShowHeaderTitle: false,
    Items:[
      { path: `${process.env.PUBLIC_URL}/dashboard`, icon: Home, type: 'link', active: false, title: t('Dashboard') },
      {
        title: t('Task'), icon: List, type: 'sub', active: true, children: [
          // { path: `${process.env.PUBLIC_URL}/tasks/letter`, title: t('Letter Disposition'), type: 'link', badge: "badge badge-danger",badgetxt:"1" },
          { path: `${process.env.PUBLIC_URL}/tasks/letter`, title: t('Letter Disposition'), type: 'link', badgeVarName: 'letter' },
          { path: `${process.env.PUBLIC_URL}/tasks/meeting`, title: t('Meeting'), type: 'link', badgeVarName: 'meetingPoint' },
          { path: `${process.env.PUBLIC_URL}/tasks/document`, title: t('Document Agenda'), type: 'link', badgeVarName: 'document' },
          { path: `${process.env.PUBLIC_URL}/tasks/service-application`, title: t('Service Application'), type: 'link', badgeVarName: 'serviceApplication' },
          { path: `${process.env.PUBLIC_URL}/tasks/public-complaint`, title: t('Public Complaint'), type: 'link', badgeVarName: 'publicComplaint' },
          { path: `${process.env.PUBLIC_URL}/tasks/religious-consultation`, title: t('Religious Consultation'), type: 'link', badgeVarName: 'religiousConsultation' },
          { path: `${process.env.PUBLIC_URL}/tasks/staff-offday`, title: t('Staff Offday'), type: 'link', badgeVarName: 'staffOffday' },
        ]
      },
    ]
  },
  {
    menutitle: t('Master Data'),
    menucontent: t('Work Unit, Position, User, etc'),
    isShowHeaderTitle: true,
    accessRights: ['canManageWorkUnit', 'canManageCourier', 'canManagePosition', 'canManageUser'],
    Items:[
      {
        title: t('Work Unit'), icon: Aperture, type: 'sub', active: false, accessRights: ['canManageWorkUnit'], children: [
          { path: `${process.env.PUBLIC_URL}/work-units/add`, title: t('Add Work Unit'), type: 'link' },
          { path: `${process.env.PUBLIC_URL}/work-units`, title: t('Work Unit Management'), type: 'link' },
        ]
      },
      {
        title: t('Position'), icon: Award, type: 'sub', active: false, accessRights: ['canManagePosition'], children: [
          { path: `${process.env.PUBLIC_URL}/positions/add`, title: t('Add Position'), type: 'link' },
          { path: `${process.env.PUBLIC_URL}/positions`, title: t('Position Management'), type: 'link' },
        ]
      },
      {
        title: t('Users'), icon: Users, type: 'sub', active: false, accessRights: ['canManageUser'], children: [
          { path: `${process.env.PUBLIC_URL}/users/add`, title: t('Add User'), type: 'link' },
          { path: `${process.env.PUBLIC_URL}/users`, title: t('User Management'), type: 'link' },
        ]
      },
      {
        title: t('Courier'), icon: Truck, type: 'sub', active: false, accessRights: ['canManageCourier'], children: [
          { path: `${process.env.PUBLIC_URL}/couriers/add`, title: t('Add Courier'), type: 'link' },
          { path: `${process.env.PUBLIC_URL}/couriers`, title: t('Courier Management'), type: 'link' },
        ]
      },
      {
        title: t('Service Type'), icon: Cpu, type: 'sub', active: false, accessRights: ['canManageServiceType'], children: [
          { path: `${process.env.PUBLIC_URL}/service-type-applications`, title: t('Service Application'), type: 'link' },
          { path: `${process.env.PUBLIC_URL}/service-type-consultations`, title: t('Religious Consultation'), type: 'link' },
        ]
      },
    ]
  },
  {
    menutitle: t('Administration'),
    menucontent: t('Letter Disposition, Meeting'),
    isShowHeaderTitle: true,
    accessRights: ['canLetterAdministration', 'canMeetingAdministration', 'canDocumentAdministration', 'canLetterNumberAdministration', 'canTempleAdministration', 'canNewsAdministration'],
    Items:[
      {
        title: t('Letter Disposition'), icon: Mail, type: 'sub', active: false, accessRights: ['canLetterAdministration'], children: [
          { path: `${process.env.PUBLIC_URL}/letters/add`, title: t('Add Letter Disposition'), type: 'link' },
          { path: `${process.env.PUBLIC_URL}/letters`, title: t('Letter Disposition Management'), type: 'link' },
          { path: `${process.env.PUBLIC_URL}/letters-report`, title: t('Report'), type: 'link' },
        ]
      },
      {
        title: t('Meeting'), icon: Coffee, type: 'sub', active: false, accessRights: ['canMeetingAdministration'], children: [
          { path: `${process.env.PUBLIC_URL}/meetings/add`, title: t('Add Meeting'), type: 'link' },
          { path: `${process.env.PUBLIC_URL}/meetings`, title: t('Meeting Management'), type: 'link' },
          { path: `${process.env.PUBLIC_URL}/meetings-report`, title: t('Report'), type: 'link' },
        ]
      },
      {
        title: t('Document Agenda'), icon: Book, type: 'sub', active: false, accessRights: ['canDocumentAdministration'], children: [
          { path: `${process.env.PUBLIC_URL}/documents/add`, title: t('Add Document Agenda'), type: 'link' },
          { path: `${process.env.PUBLIC_URL}/documents`, title: t('Document Agenda Management'), type: 'link' },
          { path: `${process.env.PUBLIC_URL}/documents-report`, title: t('Report'), type: 'link' },
        ]
      },
      {
        title: t('Letter Number'), icon: Bookmark, type: 'sub', active: false, accessRights: ['canLetterNumberAdministration'], children: [
          { path: `${process.env.PUBLIC_URL}/letter-numbers/add`, title: t('Create Letter Number'), type: 'link' },
          { path: `${process.env.PUBLIC_URL}/letter-numbers`, title: t('Letter Number Management'), type: 'link' },
          { path: `${process.env.PUBLIC_URL}/letter-numbers-report`, title: t('Report'), type: 'link' },
        ]
      },
      {
        title: t('Temple'), icon: Bookmark, type: 'sub', active: false, accessRights: ['canTempleAdministration'], children: [
          { path: `${process.env.PUBLIC_URL}/temples/add`, title: t('Add Temple'), type: 'link' },
          { path: `${process.env.PUBLIC_URL}/temples`, title: t('Temple Management'), type: 'link' },
        ]
      },
      {
        title: t('News'), icon: Bookmark, type: 'sub', active: false, accessRights: ['canNewsAdministration'], children: [
          { path: `${process.env.PUBLIC_URL}/news/add`, title: t('Add News'), type: 'link' },
          { path: `${process.env.PUBLIC_URL}/news`, title: t('News Management'), type: 'link' },
        ]
      },
      {
        title: t('Attendance'), icon: Bookmark, type: 'sub', active: false, accessRights: ['canAttendanceAdministration'], children: [
          { path: `${process.env.PUBLIC_URL}/holidays`, title: t('Holiday Management'), type: 'link' },
          { path: `${process.env.PUBLIC_URL}/staff-offdays`, title: t('Staff Offday Management'), type: 'link' },
          { path: `${process.env.PUBLIC_URL}/attendances-report`, title: t('Report'), type: 'link' },
        ]
      },
    ]
  },
  {
    menutitle: t('Report'),
    menucontent: t('Report'),
    isShowHeaderTitle: true,
    accessRights: ['canIKMReportAdministration', 'canGuestBookReportAdministration', 'canServiceApplicationReportAdministration', 'canPublicComplaintReportAdministration', 'canReligiousConsultationReportAdministration'],
    Items:[
      { path: `${process.env.PUBLIC_URL}/satisfaction-indices-report`, icon: Bookmark, type: 'link', active: false, accessRights: ['canIKMReportAdministration'], title: 'IKLAN' },
      { path: `${process.env.PUBLIC_URL}/guest-books-report`, icon: Bookmark, type: 'link', active: false, accessRights: ['canGuestBookReportAdministration'], title: 'Butet' },
      { path: `${process.env.PUBLIC_URL}/service-applications-report`, icon: Bookmark, type: 'link', active: false, accessRights: ['canServiceApplicationReportAdministration'], title: t('Service Application') },
      { path: `${process.env.PUBLIC_URL}/public-complaints-report`, icon: Bookmark, type: 'link', active: false, accessRights: ['canPublicComplaintReportAdministration'], title: t('Public Complaint') },
      { path: `${process.env.PUBLIC_URL}/religious-consultations-report`, icon: Bookmark, type: 'link', active: false, accessRights: ['canReligiousConsultationReportAdministration'], title: t('Religious Consultation') },
      { path: `${process.env.PUBLIC_URL}/service-queues-report`, icon: Bookmark, type: 'link', active: false, accessRights: ['canServiceQueueReportAdministration'], title: t('Service Queue System') },
    ]
  },
  {
    menutitle: t('Setting'),
    menucontent: t('Umum, Kepegawaian'),
    isShowHeaderTitle: true,
    accessRights: ['canManageGeneralSetting'],
    Items:[
      { path: `${process.env.PUBLIC_URL}/setting`, icon: Settings, type: 'link', active: false, accessRights: ['canManageGeneralSetting'], title: t('General') },
    ]
  },
];

export default MENUITEMS;
