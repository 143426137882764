/**
 * can show menu by useraccess rights
 * @param {*} accessRights user access right (based on position)
 */
export const canShowMenuByAccessRight = (accessRights, userAccessRights) => {
  // if not an array or undefined
  if (!Array.isArray(accessRights)) {
    return true;
  }

  let result = false;
  accessRights.forEach((accessRight) => {
    if (userAccessRights[accessRight] === true) {
      result = true;
      return true;
    }
  });

  return result;
}
