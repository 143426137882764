import axios from 'axios';

import { apiUrl } from '../../app/config';

/**
 * fetch holidays data from api
 */
class HolidayService {
  /**
   * fetch holidays list
   */
  static async fetchHolidays() {
    const url = `${apiUrl}/holidays`;

    const response = await axios.get(url);
    return response.data;
  }

  /**
   * fetch holiday info
   * @param string|number id holidays.id or holidays.code
   */
  static async fetchHoliday(id) {
    const url = `${apiUrl}/holidays/${id}`;

    const response = await axios.get(url);
    return response.data;
  }

  static async addHoliday(data) {
    const url = `${apiUrl}/holidays`;

    const response = await axios.post(url, data);
    return response.data;
  }

  static async updateHoliday(id, data) {
    const url = `${apiUrl}/holidays/${id}`;

    const response = await axios.put(url, data);
    return response.data;
  }

  static async destroyHoliday(id) {
    const url = `${apiUrl}/holidays/${id}`;

    const response = await axios.delete(url);
    return response.data;
  }
}

export default HolidayService;
