import React, { useState } from 'react';
import { Button, Card, CardBody, CardFooter, Container, Col, Form, FormGroup, Label, Row, Input, ListGroup, ListGroupItem } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Dropzone from 'react-dropzone-uploader';

import { apiUrl, attachmentAcceptMimeType } from '../../app/config';
import { addLetter } from './letterSlice';
import { addTypeaheadSenders } from '../typeahead/typeaheadSlice';
import { Breadcrumb } from '../../components/layout';
import { CourierInput, SenderTypeaheadInput } from '../../components/ui-kits';
import { showErrorAlert, showHtmlSuccessAlert, showConfirmationAlert } from '../../app/alertResponse.handler';

const AddLetter = ({ t }) => {
  const [attachments, setAttachments] = useState([]);
  const [senderNameRef, setSenderNameRef] = useState(null);

  const jwtToken = useSelector(state => state.auth.jwtToken);

  const { register, reset, handleSubmit, control, errors, getValues } = useForm();
  const dispatch = useDispatch();

  // https://react-dropzone-uploader.js.org/docs/api#getuploadparams
  const getUploadParams = ({ file, meta }) => {
    const body = new FormData()
    body.append('file', file);

    return {
      url: `${apiUrl}/attachments/letter/0`,
      headers: {
        'Authorization' : `Bearer ${jwtToken}`
      },
      body,
    }
  }

  /**
   * handle response from upload attachment request
   */
  const handleChangeStatus = (file, status) => {
    if (status === 'done'){
      const response = JSON.parse(file.xhr.response);
      if (response?.filename) {
        setAttachments([...attachments, response]);
      }

      setTimeout(() => {
        file.remove()
      }, 5000);
    }
  };

  const onSubmitHandler = (data, e) => {
    // typeahead is returning array and item object ([{id, name}])
    let senderName = '';
    let isNewSenderName = false;
    if (Array.isArray(data.senderName) && data.senderName.length > 0) {
      senderName = data.senderName[0].name;
      isNewSenderName = data.senderName[0].customOption === true;
    }

    // TODO: we should show error here that senderName is empty
    if (!senderName) {
      return false;
    }

    // ask if the user really want to register without attachment
    if (attachments.length === 0) {
      showConfirmationAlert('Are you sure to register letter without attachment?')
        .then(({isConfirmed}) => {
          if (isConfirmed) {
            submitToApi(data, senderName, attachments, isNewSenderName);
          }
        });
    } else {
      submitToApi(data, senderName, attachments, isNewSenderName);
    }
  }

  const submitToApi = (data, senderName, attachments, isNewSenderName) => {
    dispatch(addLetter({ ...data, senderName, attachments })).then((payload) => {
      const { error } = payload;
      if (error) {
        showErrorAlert(error, { defaultMessage: 'Add data is failed' });
      } else {
        const { registrationNumber } = payload.payload;

        // get html message to show
        const htmlMessage = t('Data has been created successfully') + `<br>${t('Registration Number')}: <b>${registrationNumber}</b><br>${t('Write this registration number on the letter')}`;

        showHtmlSuccessAlert(htmlMessage)
          .then(() => {
            clearForm(); // reset after form submit

            // register new typeahead for sender name
            if (isNewSenderName) {
              dispatch(addTypeaheadSenders({ name: senderName }));
            }
          });
      }
    })
    .catch((error) => {
      // TODO: need to show error here
      console.log(error);
    });
  }

  /**
   * clear form after submit or just reset form
   */
  const clearForm = () => {
    reset();
    setAttachments([]);
    if (senderNameRef) {
      senderNameRef.clear();
    }
    // TODO: clear dropzone
  }

  const removeAttachment = (filename) => {
    const list = attachments.filter((attachment) => attachment.filename !== filename);
    setAttachments(list);
  }

  const showAttachmentList = () => {
    if (attachments.length === 0) {
      return (
        <ListGroup className="mb-3">
          <ListGroupItem className="list-group-item-action">
          <b>{t('No attachment')}</b>
          </ListGroupItem>
        </ListGroup>
      )
    }

    return (
      <ListGroup className="mb-3">
        {attachments.map(({ originalname, filename }) =>
          <ListGroupItem key={filename} className="list-group-item-action">
            <i className="fa fa-file"></i>{originalname}
            <button type="button" className="float-right" onClick={() => removeAttachment(filename)}>{t('buttons_Delete')}</button>
          </ListGroupItem>
        )}
      </ListGroup>
    )
  }

  return (
    <React.Fragment>
      <Breadcrumb parent={t('Letter Disposition')} headerTitle={t('Add Letter Disposition')} title={t('Add Letter Disposition')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="subject">{t('Subject')} <span className="text-danger">(*)</span></Label>
                    <Col sm="9">
                      <Input
                        className="form-control btn-square"
                        name="subject"
                        type="text"
                        innerRef={
                          register({
                            required: t('This field must be filled')
                          })
                        }
                      />
                      <span color="danger">{errors.subject && errors.subject.message}</span>
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="senderName">{t('Sender Name')} <span className="text-danger">(*)</span></Label>
                    <Col sm="9">
                      <Controller
                        control={control}
                        render={({ onChange, onBlur, value, name, ref }) => (
                          <SenderTypeaheadInput
                            onBlur={onBlur}
                            onChange={e => onChange(e)}
                            name={name}
                            getRef={setSenderNameRef}
                            />
                        )}
                        name="senderName"
                        defaultValue=""
                        rules={{
                          required: t('This field must be filled'),
                          validate: (value) => {
                            const currentValue = getValues('senderName');
                            if (Array.isArray(currentValue) && currentValue.length === 0) {
                              return t('This field must be filled');
                            }

                            return true;
                          },
                        }}
                      />
                      <span color="danger">{errors.senderName && errors.senderName.message}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="courierId">{t('Letter is received through')} <span className="text-danger">(*)</span></Label>
                    <Col sm="9">
                      <CourierInput
                        className="form-control btn-square"
                        name="courierId"
                        innerRef={
                          register({
                            required: t('This field must be filled')
                          })
                        }
                      />
                      <span color="danger">{errors.courierId && errors.courierId.message}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="attachment">{t('Attachments')} <span className="text-danger">(*)</span></Label>
                    <Col sm="9">
                      { showAttachmentList() }

                      <div className="dz-message needsclick">
                        <Dropzone
                          accept={attachmentAcceptMimeType}
                          getUploadParams={getUploadParams}
                          onChangeStatus={handleChangeStatus}
                          inputContent={`${t('Upload Document')} (PDF, PNG, JPG)`}
                        />
                      </div>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button color="secondary" className="mr-2" onClick={() => clearForm()}>{t('buttons_Cancel')}</Button>
                <Button color="primary" onClick={handleSubmit(onSubmitHandler)}>{t('buttons_Save')}</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default translate(AddLetter);
