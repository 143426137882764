import React from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';

import { updateMeeting } from '../meetingSlice';
import { showErrorAlert, showSuccessAlert } from '../../../app/alertResponse.handler';

const EditModal = ({ isOpen, meeting, onClose, t })  => {
  const { register, handleSubmit, control, errors } = useForm();
  const dispatch = useDispatch();

  const onSubmitHandler = (data) => {
    const { id } = meeting;

    dispatch(updateMeeting({ id, ...data })).then(({ error }) => {
      if (error) {
        showErrorAlert(error, { defaultMessage: 'Change data is failed' });
      } else {
        showSuccessAlert('Data has been changed successfully')
          .then(() => {
            onClose()
          });
      }
    })
    .catch((error) => {
      // TODO: need to show error here
      console.log(error);
    });
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalHeader toggle={onClose}>
        {t('Edit Meeting')}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
          <FormGroup className="form-row">
            <Label htmlFor="name">{t('Registration Number')} <span className="text-danger">(*)</span></Label>
            <Input
              className="form-control btn-square"
              name="name"
              type="text"
              disabled={true}
              defaultValue={meeting && meeting.registrationNumber}
            />
          </FormGroup>

          <FormGroup className="form-row">
            <Label htmlFor="subject">{t('Meeting Date')} <span className="text-danger">(*)</span></Label>
            <Controller
              control={control}
              render={({ onChange, onBlur, value, name, ref }) => (
                <div className="col-12 px-0">
                  <DatePicker
                    selected={value}
                    onChange={e => onChange(e)}
                    name={name}
                    className="form-control digits"
                    dateFormat="dd/MM/yyyy"
                    />
                </div>
              )}
              name="meetingAt"
              defaultValue={meeting && new Date(meeting.meetingAt)}
              rules={{
                required: t('This field must be filled'),
              }}
            />
            <span className="text-danger">{errors.meetingAt && errors.meetingAt.message}</span>
          </FormGroup>

          <FormGroup className="form-row">
            <Label htmlFor="subject">{t('Subject')} <span className="text-danger">(*)</span></Label>
            <Input
              className="form-control btn-square"
              name="subject"
              type="text"
              defaultValue={meeting && meeting.subject}
              maxLength={150}
              innerRef={
                register({
                  required: t('This field must be filled')
                })
              }
            />
            <span color="danger">{errors.subject && errors.subject.message}</span>
          </FormGroup>

          <FormGroup className="form-row">
            <Label htmlFor="description">{t('Description')} <span className="text-danger">(*)</span></Label><br />
            <Input
              type="textarea"
              rows="3"
              placeholder={t('Input description for the meeting')}
              name="description"
              defaultValue={meeting && meeting.description}
              innerRef={
                register({
                  required: t('This field must be filled')
                })
              }
            />
            <span color="danger">{errors.description && errors.description.message}</span>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>{t('buttons_Cancel')}</Button>
        <Button color="primary" onClick={handleSubmit(onSubmitHandler)}>{t('buttons_Save')}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default translate(EditModal);
