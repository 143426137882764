import React, { useEffect, useState, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'sweetalert2';
import { Link } from 'react-router-dom';

import generateTableColumns from './settings/table-column';
import { Breadcrumb } from '../../components/layout';
import { DataTableWithPagination, LoadingInfo } from '../../components/ui-kits';
import { fetchServiceTypeApplications, destroyServiceTypeApplication } from './serviceTypeApplicationSlice';
import { EditModal } from './modals';
import * as CONSTANTS from '../../app/constants';

const ServiceTypeApplicationIndex = (props) => {
  const serviceTypeApplications = useSelector(state => state.serviceTypeApplication.serviceTypeApplications);
  const totalServiceTypeApplication = useSelector(state => state.serviceTypeApplication.totalServiceTypeApplication);
  const isLoadingAPI = useSelector(state => state.serviceTypeApplication.loading);

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchServiceTypeApplications({ page, pagesize: CONSTANTS.NUMBER_PER_PAGE }));
  }, [dispatch, page]);

  const editHandler = (row) => {
    setSelectedRow(row);
    setIsOpenEditModal(true);
  }

  const editModalOnCloseHandler = () => {
    setIsOpenEditModal(false);
  }

  const serviceTypeApplicationPointHandler = (row) => {
    setSelectedRow(row);
  }

  const onRowClicked = (row) => {
    editHandler(row);
  }

  const tableColumns = useMemo(() => {
    const destroyHandler = (row) => {
      SweetAlert.fire({
        title: props.t('Are you sure to delete this staff offday?'),
        cancelButtonText: props.t('buttons_Cancel'),
        confirmButtonText: props.t('buttons_Delete'),
        reverseButtons: true,
        showCancelButton: true,
      }).then(({isConfirmed}) => {
        if (isConfirmed) {
          dispatch(destroyServiceTypeApplication(row.id)).then(({ error }) => {
            if (error) {
              // get proper error message from server
              const errorMessage = error && error.message ? props.t(error.message) : props.t('Delete data is failed');

              // trigger error alert
              SweetAlert.fire({
                title: props.t('Error Occured'), text: errorMessage, icon: 'error'
              });
            } else {
              SweetAlert.fire({
                title: props.t('Success'), text: props.t('Data has been deleted successfully'), icon: 'success'
              });
            }
          });
        }
      });
    }

    return generateTableColumns(editHandler, destroyHandler, serviceTypeApplicationPointHandler);
  }, [dispatch, props]);

  return (
    <React.Fragment>
      <Breadcrumb parent={props.t('Service Type')} headerTitle={props.t('Service Type Management')} title={props.t('Index')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTableWithPagination
                  data={serviceTypeApplications}
                  columns={tableColumns}
                  totalRow={totalServiceTypeApplication}
                  onPageChange={(page) => setPage(page)}
                  onRowClicked={onRowClicked}
                  highlightOnHover={true}
                  pointerOnHover={true}
                />

                <Row>
                  <Col sm="12" className="text-center mt-3">
                    <Link to={`${process.env.PUBLIC_URL}/service-type-applications/add`}>
                      <Button color="secondary" className="mx-auto">{props.t('buttons_Add_Service_Type')}</Button>
                    </Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <EditModal isOpen={isOpenEditModal} serviceTypeApplication={selectedRow} onClose={editModalOnCloseHandler} />
      </Container>
      <LoadingInfo loading={isLoadingAPI} />
    </React.Fragment>
  )
}

export default translate(ServiceTypeApplicationIndex);
