import React from 'react';
import DataTable from 'react-data-table-component';
import { t } from 'react-switch-lang';

import * as CONSTANTS from '../../app/constants';

const DataTableWithPagination = ({ columns, totalRow, data, onPageChange, ...otherProps }) => {
  return (
    <DataTable
      data={data}
      columns={columns}
      striped={true}
      center={true}
      selectableRows={false}
      selectableRowsHighlight
      noHeader
      pagination
      paginationServer
      paginationTotalRows={totalRow}
      paginationPerPage={CONSTANTS.NUMBER_PER_PAGE}
      paginationComponentOptions={{
        noRowsPerPage: true
      }}
      onChangePage={page => onPageChange(page)}
      noDataComponent={t('There are no records to display')}
      {...otherProps}
    />
  );
}

export default DataTableWithPagination;
