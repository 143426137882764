import { t } from 'react-switch-lang';

import { deleteJwt, setError } from '../features/auth/authSlice';

export const thunkHandler = async (asyncFn, thunkAPI) => {
  try {
    const response = await asyncFn;
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      await thunkAPI.dispatch(setError(t('Your login session is expired, please relogin')));
      await thunkAPI.dispatch(deleteJwt());
    }

    // if server has custom error message, then use this error message
    if (error.response?.data) {
      throw error.response.data;
    }

    // otherwise show default server error message
    throw new Error('Server failed to process this request');
  }
}
