import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';

import { updateStaffOffday } from '../staffOffdaySlice';
import { showErrorAlert, showSuccessAlert } from '../../../app/alertResponse.handler';
import UserInput from '../../user/UserInput';
import { OffdayTypeInput, StaffOffdayStatusInput } from '../../../components/ui-kits';

const EditModal = ({ isOpen, staffOffday, onClose, t })  => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userError, setUserError] = useState(undefined);
  const [selectedOffdayType, setSelectedOffdayType] = useState('Cuti');
  const { register, handleSubmit, control, errors } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (staffOffday && staffOffday.User) {
      setSelectedOffdayType(staffOffday.offdayType);
      setSelectedUsers([staffOffday.User]);
    }
  }, [staffOffday]);

  const onSubmitHandler = (data) => {
    if (!Array.isArray(selectedUsers) || selectedUsers.length === 0) {
      setUserError(t('This field must be filled'));
      return false;
    }

    const { id } = staffOffday;

    dispatch(updateStaffOffday({ id, userId: selectedUsers[0].id, offdayType: selectedOffdayType, ...data })).then(({ error }) => {
      if (error) {
        showErrorAlert(error, { defaultMessage: 'Change data is failed' });
      } else {
        showSuccessAlert('Data has been changed successfully')
          .then(() => {
            onClose()
          });
      }
    })
    .catch((error) => {
      // TODO: need to show error here
      console.log(error);
    });
  }

  const onChangeUserInput = (user) => {
    if (user) {
      setSelectedUsers([user]);
    }
  }

  const onChangeOffdayTypeInput = (value) => {
    setSelectedOffdayType(value);
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalHeader toggle={onClose}>
        {t('Edit StaffOffday')}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
          <FormGroup className="form-row">
            <Label htmlFor="dateAt">{t('User')} <span className="text-danger">(*)</span></Label>
            <UserInput users={selectedUsers} onChange={onChangeUserInput} />
            <span className="text-danger">{userError}</span>
          </FormGroup>

          <FormGroup className="form-row">
            <Label htmlFor="dateAt">{t('Date')} <span className="text-danger">(*)</span></Label>
            <Controller
              control={control}
              render={({ onChange, onBlur, value, name, ref }) => (
                <div className="col-12 px-0">
                  <DatePicker
                    selected={value}
                    onChange={e => onChange(e)}
                    name={name}
                    className="form-control digits"
                    dateFormat="dd/MM/yyyy"
                    />
                </div>
              )}
              name="dateAt"
              defaultValue={staffOffday && new Date(staffOffday.dateAt)}
              rules={{
                required: t('This field must be filled'),
              }}
            />
            <span className="text-danger">{errors.dateAt && errors.dateAt.message}</span>
          </FormGroup>

          <FormGroup className="form-row">
            <div className="col-12 pl-0">
              <Label htmlFor="title">{t('Offday Type')} <span className="text-danger">(*)</span></Label>
            </div>
            <OffdayTypeInput selectedValue={selectedOffdayType} onChange={onChangeOffdayTypeInput} />
          </FormGroup>

          <FormGroup className="form-row">
            <Label htmlFor="title">{t('Information')}</Label>
            <Input
              type="textarea"
              rows="3"
              placeholder={t('Input point description')}
              name="description"
              defaultValue={staffOffday && staffOffday.description}
              innerRef={register}
            />
          </FormGroup>

          <FormGroup className="form-row">
            <div className="col-12 pl-0">
              <Label htmlFor="title">{t('Status')} <span className="text-danger">(*)</span></Label>
            </div>
            <StaffOffdayStatusInput
              name="status"
              defaultValue={staffOffday && staffOffday.status}
              innerRef={register}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>{t('buttons_Cancel')}</Button>
        <Button color="primary" onClick={handleSubmit(onSubmitHandler)}>{t('buttons_Save')}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default translate(EditModal);
