import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import PositionService from './position.service';
import { thunkHandler } from '../../app/thunk.handler';

export const fetchPositions = createAsyncThunk('positions/fetchPositions', async (payload, thunkAPI) => {
  return thunkHandler(PositionService.fetchPositions(), thunkAPI);
});

export const activatePosition = createAsyncThunk('positions/activate', async (id, thunkAPI) => {
  return thunkHandler(PositionService.activatePosition(id), thunkAPI);
});

export const deactivatePosition = createAsyncThunk('positions/deactivate', async (id, thunkAPI) => {
  return thunkHandler(PositionService.deactivatePosition(id), thunkAPI);
});

export const addPosition = createAsyncThunk('positions/addPosition', async (payload, thunkAPI) => {
  return thunkHandler(PositionService.addPosition(payload), thunkAPI);
});

export const updatePosition = createAsyncThunk('positions/updatePosition', async (payload, thunkAPI) => {
  const { id, ...data } = payload;

  return thunkHandler(PositionService.updatePosition(id, data), thunkAPI);
});

const updatePositionsState = (positions, position) => {
  const updatePositions = positions.reduce((result, item) => {
    if (position.id && item.id === position.id) {
      result.push(position);
    } else {
      result.push(item);
    }

    return result;
  }, []);

  return updatePositions;
}

const positionSlice = createSlice({
  name: 'position',
  initialState: {
    positions: undefined,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchPositions.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchPositions.fulfilled]: (state, action) => {
      state.loading = false;
      state.positions = action.payload;
    },
    [fetchPositions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [activatePosition.pending]: (state, action) => {
      state.loading = true;
    },
    [activatePosition.fulfilled]: (state, action) => {
      state.loading = false;
      state.positions = updatePositionsState(state.positions, action.payload);
    },
    [activatePosition.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [deactivatePosition.pending]: (state, action) => {
      state.loading = true;
    },
    [deactivatePosition.fulfilled]: (state, action) => {
      state.loading = false;
      state.positions = updatePositionsState(state.positions, action.payload);
    },
    [deactivatePosition.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [addPosition.pending]: (state, action) => {
      state.loading = true;
    },
    [addPosition.fulfilled]: (state, action) => {
      state.loading = false;
      state.positions.unshift(action.payload);
    },
    [addPosition.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [updatePosition.pending]: (state, action) => {
      state.loading = true;
    },
    [updatePosition.fulfilled]: (state, action) => {
      state.loading = false;
      state.positions = updatePositionsState(state.positions, action.payload);
    },
    [updatePosition.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    }
  }
})

export default positionSlice.reducer;
