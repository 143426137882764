import React from 'react';
import { Button } from 'reactstrap';
import { t } from 'react-switch-lang';

const generateTableColumns = (editHandler, destroyHandler) => [
  {
    name: 'Id',
    selector: 'id',
    sortable: false,
    center: false,
    width: '65px',
  },
  {
    name: t('Registration Number'),
    selector: 'registrationNumber',
    sortable: false,
    center: false,
    grow: 1,
  },
  {
    name: t('Sender Name'),
    selector: 'senderName',
    sortable: false,
    center: false,
    grow: 1,
  },
  {
    name: t('Receiver Name'),
    selector: (row, index) => `${row.receiverUser ? row.receiverUser.fullName : ''}`,
    sortable: false,
    center: false,
    grow: 1,
  },
  {
    name: t('Actions'),
    sortable: false,
    cell: row => (
      <React.Fragment>
        <Button onClick={() => editHandler(row)} className="btn-square mr-1" color="primary" size="xs"><i className={`fa fa-pencil`}></i> {t('buttons_Edit')}</Button>
        <Button onClick={() => destroyHandler(row)} className="btn-square mr-1" color="danger" size="xs"><i className={`fa fa-trash`}></i> {t('buttons_Delete')}</Button>
      </React.Fragment>
    ),
    wrap: true,
    width: '200px'
  }
];

export default generateTableColumns;
