import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { updateServiceTypeApplication } from '../serviceTypeApplicationSlice';
import { showErrorAlert, showSuccessAlert } from '../../../app/alertResponse.handler';
import UserInput from '../../user/UserInput';
import { ReligionInput } from '../../../components/ui-kits';

const EditModal = ({ isOpen, serviceTypeApplication, onClose, t })  => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userError, setUserError] = useState(undefined);
  const [isHajjService, setIsHajjService] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (serviceTypeApplication) {
      if (serviceTypeApplication.User) {
        setSelectedUsers([serviceTypeApplication.User]);
      }
      setIsHajjService(serviceTypeApplication.isHajjService);
    }
  }, [serviceTypeApplication]);

  const onSubmitHandler = (data) => {
    if (!Array.isArray(selectedUsers) || selectedUsers.length === 0) {
      setUserError(t('This field must be filled'));
      return false;
    }

    const { id } = serviceTypeApplication;

    dispatch(updateServiceTypeApplication({ id, handlerUserId: selectedUsers[0].id, isHajjService, ...data })).then(({ error }) => {
      if (error) {
        showErrorAlert(error, { defaultMessage: 'Change data is failed' });
      } else {
        showSuccessAlert('Data has been changed successfully')
          .then(() => {
            onClose()
          });
      }
    })
    .catch((error) => {
      // TODO: need to show error here
      console.log(error);
    });
  }

  const onChangeUserInput = (user) => {
    if (user) {
      setSelectedUsers([user]);
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalHeader toggle={onClose}>
        {t('Edit Service Type')}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
          <FormGroup className="form-row">
            <Label htmlFor="name">{t('Name')} <span className="text-danger">(*)</span></Label>
            <Input
              className="form-control btn-square"
              name="name"
              type="text"
              defaultValue={serviceTypeApplication && serviceTypeApplication.name}
              innerRef={
                register({
                  required: t('This field must be filled')
                })
              }
            />
            <span color="danger">{errors.name && errors.name.message}</span>
          </FormGroup>
          <FormGroup className="form-row">
            <Label htmlFor="religion">{t('Religion')} <span className="text-danger">(*)</span></Label>
            <ReligionInput
                className="form-control btn-square"
                name="religion"
                defaultValue={serviceTypeApplication && serviceTypeApplication.religion}
                innerRef={
                  register({
                    required: t('This field must be filled')
                  })
                }
              />
            <span color="danger">{errors.religion && errors.religion.message}</span>
          </FormGroup>
          <FormGroup className="form-row">
            <Label htmlFor="dateAt">{t('Handler Staff')} <span className="text-danger">(*)</span></Label>
            <UserInput users={selectedUsers} onChange={onChangeUserInput} canEdit={true} />
            <span className="text-danger">{userError}</span>
          </FormGroup>
          <FormGroup className="form-row mb-0">
            <Label className="d-block" for="chk-is-hajj-service">
              <Input className="checkbox_animated" id="chk-is-hajj-service" type="checkbox" checked={isHajjService} onChange={(e) => setIsHajjService(!isHajjService)} /> {t('Hajj Service')}
            </Label>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>{t('buttons_Cancel')}</Button>
        <Button color="primary" onClick={handleSubmit(onSubmitHandler)}>{t('buttons_Save')}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default translate(EditModal);
