import React from 'react';
import { Button } from 'reactstrap';
import { t } from 'react-switch-lang';

import StaffOffdayService from '../staffOffday.service';
import { displayDateOnly } from '../../../util/FormaterHelper';

const generateTableColumns = (editHandler, destroyHandler, viewMeetingPointHandler) => [
  {
    name: 'Id',
    selector: 'id',
    sortable: false,
    center: false,
    width: '65px',
  },
  {
    name: t('Staff Name'),
    cell: row => row.User ? row.User.fullName : '',
    sortable: false,
    center: false,
    grow: 1,
  },
  {
    name: t('Offday Type'),
    selector: 'offdayType',
    sortable: false,
    center: false,
    grow: 1,
  },
  {
    name: t('Date'),
    selector: 'dateAt',
    cell: row => displayDateOnly(row.dateAt),
    sortable: false,
    center: false,
    grow: 1,
  },
  {
    name: t('Status'),
    selector: 'status',
    cell: row => StaffOffdayService.getStatusText(row.status),
    sortable: false,
    center: false,
    grow: 1,
  },
  {
    name: t('Actions'),
    sortable: false,
    cell: row => (
      <React.Fragment>
        <Button onClick={() => destroyHandler(row)} className="btn-square mr-1" color="danger" size="xs"><i className={`fa fa-trash`}></i> {t('buttons_Delete')}</Button>
      </React.Fragment>
    ),
    wrap: true,
    width: '120px'
  }
];

export default generateTableColumns;
