import React from 'react';
import { Input, Label } from 'reactstrap';
import { t } from 'react-switch-lang';

const ReportTypeSelectInput = (props) => {
  const { selectedValue, onChange } = props;

  return (
    <div className="m-checkbox-inline custom-radio-ml d-block">
      <div className="radio radio-primary">
        <Input id="dailyRadio" type="radio" name="timeScale" value="daily" checked={selectedValue === 'daily'} onChange={(e) => onChange(e.target.value)} />
        <Label className="mb-0" for="dailyRadio"><span className="digits"> {t('Daily')}</span></Label>
      </div>
      <div className="radio radio-primary">
        <Input id="monthlyRadio" type="radio" name="timeScale" value="monthly" checked={selectedValue === 'monthly'} onChange={(e) => onChange(e.target.value)} />
        <Label className="mb-0" for="monthlyRadio"><span className="digits"> {t('Monthly')}</span></Label>
      </div>
    </div>
  )
}

export default ReportTypeSelectInput;
