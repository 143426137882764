import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { translate } from 'react-switch-lang';

import { DocumentMarkDoneModal as MarkDownModal } from './';
import { HistoryModal } from '../../document/modals/';
import * as CONSTANTS from '../../../app/constants';
import DetailDocument from '../../document/DetailDocument';

const EditModal = ({ isOpen, document, onClose, t })  => {
  const [isMarkDoneModalOpen, setIsMarkDoneModalOpen] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);

  const onMarkDoneClickHandler = () => {
    setIsMarkDoneModalOpen(true);
  }

  const markDownModalOnCloseHandler = (success) => {
    setIsMarkDoneModalOpen(false);

    if (success) {
      onClose(true);
    }
  }

  const onHistoryClickHandler = () => {
    setIsHistoryModalOpen(true);
  }

  const historyModalOnCloseHandler = (success) => {
    setIsHistoryModalOpen(false);
  }

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={onClose} size="lg" centered style={{maxWidth: '1000px', width: '80%'}}>
        <ModalHeader toggle={onClose}>
          {t('Document Agenda')}
        </ModalHeader>
        <ModalBody className="p-4">
          <DetailDocument document={document} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClose}>{t('buttons_Close')}</Button>
          <Button color="info" onClick={onHistoryClickHandler}>{t('buttons_Document_Activity_Log')}</Button>
          { document?.status !== CONSTANTS.STATUS_DONE &&
          <React.Fragment>
            <Button color="success" onClick={onMarkDoneClickHandler}>{t('buttons_Mark_Done')}</Button>
          </React.Fragment>
          }
        </ModalFooter>
      </Modal>

      <MarkDownModal isOpen={isMarkDoneModalOpen} id={document && document.id} onClose={markDownModalOnCloseHandler} />
      <HistoryModal isOpen={isHistoryModalOpen} id={document && document.id} onClose={historyModalOnCloseHandler} />
    </React.Fragment>
  );
}

export default translate(EditModal);
