import React from 'react';
import { Row, Col } from 'reactstrap';
import { t } from 'react-switch-lang';
import { Info } from 'react-feather';
import nl2br from 'react-nl2br';

import { StatusInfo, ThreadList } from '../../components/ui-kits';
import { displayDateTime } from '../../util/FormaterHelper';

const DetailPublicComplaint = (props) => {
  const { publicComplaint, isLoadingThread, threads } = props;

  return (
    <>
      { publicComplaint &&
        <Row>
          <Col sm="7">
            <Row>
              <Col sm="8">
              <p><b>{t('Registration Number')}</b> { publicComplaint.registrationNumber }</p>
              <StatusInfo status={publicComplaint.status} doneText={t('status_TL')} />
              </Col>
              <Col sm="4" className="text-right">
              <div className="border-bottom">{ displayDateTime(publicComplaint.createdAt) }</div>
              <small>{t('Input Date')}</small>
              </Col>
            </Row>

            <Row>
              <Col sm="12" className="mt-3">
                <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                <b>{t('Full Name')}</b><br />
                {publicComplaint.fullName}
              </Col>
            </Row>

            <Row>
              <Col sm="12" className="mt-3">
                <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                <b>{t('Address')}</b><br />
                {publicComplaint.address}
              </Col>
            </Row>

            <Row>
              <Col sm="12" className="mt-3">
                <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                <b>{t('Phone')}</b><br />
                {publicComplaint.phone}
              </Col>
            </Row>

            <Row>
              <Col sm="12" className="mt-3">
                <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                <b>{t('Email')}</b><br />
                {publicComplaint.email}
              </Col>
            </Row>

            <Row>
              <Col sm="12" className="mt-3">
                <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                <b>{t('Question/Problem')}</b><br />
                {nl2br(publicComplaint.comment)}
              </Col>
            </Row>
          </Col>
          <Col sm="5">
            <ThreadList
              loading={isLoadingThread}
              threads={threads}
            />
          </Col>
        </Row>
      }
    </>
  )
}

export default DetailPublicComplaint;
