import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { t } from 'react-switch-lang';
import nl2br from 'react-nl2br';
import { useSelector } from 'react-redux';
import { Info } from 'react-feather';
import Dropzone from 'react-dropzone-uploader';

import { StatusInfo } from '../../components/ui-kits';
import AttachmentList from '../attachments/AttachmentList';
import { apiUrl, attachmentAcceptMimeType } from '../../app/config';
import AttachmentService from '../attachments/attachment.service';
import { showErrorAlert, showConfirmationAlert } from '../../app/alertResponse.handler';
import { displayDateOnly } from '../../util/FormaterHelper';

const DetailDocument = (props) => {
  const jwtToken = useSelector(state => state.auth.jwtToken);

  const { document, canUploadAttachment, canRemoveAttachment } = props;
  const [isLoadingAttachment, setIsLoadingAttachment] = useState(false);
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    const fetchAttachments = async () => {
      const { id } = document ? document : {};

      if (id) {
        setIsLoadingAttachment(true);
        try {
          const result = await AttachmentService.fetchAttachments('document', id);
          setAttachments(result);
        } catch (error) {
          // TODO: do we need to show error here
        }

        setIsLoadingAttachment(false);
      }
    }

    fetchAttachments();
  }, [document]);

  // https://react-dropzone-uploader.js.org/docs/api#getuploadparams
  const getUploadParams = ({ file, meta }) => {
    const body = new FormData()
    body.append('file', file);

    return {
      url: `${apiUrl}/attachments/document/${document?.id || 0}`,
      headers: {
        'Authorization' : `Bearer ${jwtToken}`
      },
      body,
    }
  }

  /**
   * handle response from upload attachment request
   */
  const handleChangeStatus = (file, status) => {
    if (status === 'done'){
      const response = JSON.parse(file.xhr.response);
      if (response?.filename) {
        setAttachments([...attachments, response]);
      }

      setTimeout(() => {
        file.remove()
      }, 5000);
    }
  }

  const onRemoveAttachment = (id) => {
    showConfirmationAlert('Are you sure to delete attachment?')
      .then(async ({isConfirmed}) => {
        if (isConfirmed) {
          try {
            await AttachmentService.destroyAttachment('document', document?.id, id);

            const list = attachments.filter((attachment) => attachment.id !== id);
            setAttachments(list);
          } catch (error) {
            showErrorAlert(error, { defaultMessage: 'Delete attachment is failed' });
          }
        }
      });
  }

  return (
    <>
    { document &&
      <Row>
        <Col sm="7">
        { document &&
            <>
              <Row>
                <Col sm="8">
                <p><b>{t('Registration Number')}</b> { document.registrationNumber }</p>
                <StatusInfo status={document.status} />
                </Col>
                <Col sm="4" className="text-right">
                <div className="border-bottom">{ displayDateOnly(document.receivedAt) }</div>
                <small>{t('Received Date')}</small>
                </Col>
              </Row>

              <Row>
                <Col sm="12" className="mt-3">
                  <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                  <b>{t('Sender Name')}</b><br />
                  {document.senderName}
                </Col>
              </Row>

              <Row>
                <Col sm="12" className="mt-3">
                  <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                  <b>{t('Receiver')}</b><br />
                  {document.receiverUser?.fullName}
                </Col>
              </Row>

              { document?.returnedAt &&
              <Row>
                <Col sm="12" className="mt-3">
                  <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                  <div className="float-left">
                  <b>{t('Returned Date')}</b><br />
                  { displayDateOnly(document.returnedAt) }
                  </div>
                </Col>
              </Row>
              }

              { document?.returnToName &&
              <Row>
                <Col sm="12" className="mt-3">
                  <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                  <div className="float-left">
                  <b>{t('Return To Name')}</b><br />
                  { document.returnToName }
                  </div>
                </Col>
              </Row>
              }

              { document?.finishedAt &&
              <Row>
                <Col sm="12" className="mt-3">
                  <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                  <div className="float-left">
                  <b>{t('Finished Date')}</b><br />
                  { displayDateOnly(document.finishedAt) }
                  </div>
                </Col>
              </Row>
              }

              { document?.responseText &&
              <Row>
                <Col sm="12" className="mt-3">
                  <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                  <div className="float-left">
                  <b>{t('Last Respond')}</b><br />
                  {nl2br(document.responseText)}
                  </div>
                </Col>
              </Row>
              }
            </>
          }
        </Col>
        <Col sm="5">
          <AttachmentList
            attachments={attachments}
            module="document"
            moduleId={document && document.id}
            canRemove={canRemoveAttachment}
            onRemove={onRemoveAttachment}
            loading={isLoadingAttachment}
          />

          { canUploadAttachment &&
            <div className="dz-message needsclick col-12">
              <Dropzone
                accept={attachmentAcceptMimeType}
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                inputContent={`${t('Upload Document')} (PDF, PNG, JPG)`}
              />
            </div>
          }
        </Col>
      </Row>
    }
    </>
  )
}

export default DetailDocument;
