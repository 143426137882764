import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { t } from 'react-switch-lang';

import DetailDocument from '../DetailDocument';
import AttachmentService from '../../attachments/attachment.service';

const DetailModal = ({ isOpen, document, onClose })  => {
  const [isLoadingAttachment, setIsLoadingAttachment] = useState(false);
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    const fetchAttachments = async () => {
      const { id } = document ? document : {};

      if (id) {
        setIsLoadingAttachment(true);
        try {
          const result = await AttachmentService.fetchAttachments('document', id);
          setAttachments(result);
        } catch (error) {
          // TODO: do we need to show error here
        }

        setIsLoadingAttachment(false);
      }
    }

    fetchAttachments();
  }, [document]);

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={onClose} size="lg" centered>
        <ModalBody className="p-4">
          <DetailDocument document={document} attachments={attachments} isLoadingAttachment={isLoadingAttachment} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClose}>{t('buttons_Close')}</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default DetailModal;
