import React from 'react';
import { Button } from 'reactstrap';
import { t } from 'react-switch-lang';

import { STATUS_ACTIVE } from '../../../app/constants';

const renderStatus = (row) => {
  const { isActive } = row;

  if (isActive === STATUS_ACTIVE) {
    return <i className="fa fa-check fa-lg text-success"></i>
  }

  return <span className="fa fa-times fa-lg text-danger"></span>
}

const generateTableColumns = (editHandler, destroyHandler) => [
  {
    name: 'Id',
    selector: 'id',
    sortable: false,
    center: false,
    width: '65px',
  },
  {
    name: t('Title'),
    selector: 'title',
    sortable: false,
    center: false,
    grow: 1,
    wrap: true,
  },
  {
    name: t('Content'),
    selector: 'content',
    sortable: false,
    center: false,
    grow: 1,
    wrap: true,
  },
  {
    name: 'Active',
    selector: 'isActive',
    cell: (row) => renderStatus(row),
    sortable: false,
    center: true,
    width: '100px',
  },
  {
    name: t('Actions'),
    sortable: false,
    cell: row => (
      <React.Fragment>
        <Button onClick={() => editHandler(row)} className="btn-square mr-1" color="primary" size="xs"><i className={`fa fa-pencil`}></i> {t('buttons_Edit')}</Button>
        <Button onClick={() => destroyHandler(row)} className="btn-square mr-1" color="danger" size="xs"><i className={`fa fa-trash`}></i> {t('buttons_Delete')}</Button>
      </React.Fragment>
    ),
    wrap: true,
    width: '200px'
  }
];

export default generateTableColumns;
