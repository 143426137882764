const tableStyles = {
  cells: {
    style: {
      paddingTop: '10px',
      paddingLeft: '10px', // override the cell padding for data cells
      paddingRight: '10px',
      paddingBottom: '10px',
    },
  },
};

export default tableStyles;
