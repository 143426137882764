import React from 'react';
import { Link } from 'react-router-dom';
import { LogIn, User, Maximize } from 'react-feather';
import { translate } from 'react-switch-lang';
import { useSelector } from 'react-redux';

import ManImageAsset from '../../../assets/images/dashboard/profile.jpg';

const Rightbar = ({ t }) => {
  const loginUser = useSelector(state => state.auth.userInfo);

  /**
   * set full screen mode
   */
  const goFullScreen = () => {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  return (
    <React.Fragment>
      <div className="nav-right col-8 pull-right right-header p-0">
        <ul className="nav-menus">
          <li className="maximize"><a className="text-dark" href="#javascript" onClick={goFullScreen}><Maximize /></a></li>
          <li className="profile-nav onhover-dropdown p-0">
            <div className="media profile-media">
              <img className="b-r-10" src={ManImageAsset} alt="" />
              <div className="media-body"><span>{loginUser ? `${loginUser.firstname} ${loginUser.lastname}`.trim() : ''}</span>
                <p className="mb-0 font-roboto">{loginUser?.Position?.name} <i className="middle fa fa-angle-down"></i></p>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
              <li><Link to={`${process.env.PUBLIC_URL}/my-account`}><User /><span>{t('My Account')}</span></Link></li>
              <li><Link to={`${process.env.PUBLIC_URL}/logout`}><LogIn /><span>{ t('Logout') }</span></Link></li>
            </ul>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}
export default translate(Rightbar);
