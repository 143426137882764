import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useForm } from 'react-hook-form';

import { showErrorAlert, showSuccessAlert } from '../../../app/alertResponse.handler';
import MeetingService from '../../meeting/meeting.service';
import UserInput from '../../user/UserInput';

const EditModal = ({ isOpen, meetingId, meetingPoint, onClose, t })  => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userError, setUserError] = useState(undefined);
  const { register, handleSubmit, errors } = useForm();

  useEffect(() => {
    if (meetingPoint && meetingPoint.personInCharge) {
      setSelectedUsers([meetingPoint.personInCharge]);
    }
  }, [meetingPoint]);

  const onSubmitHandler = async (data) => {
    if (!Array.isArray(selectedUsers) || selectedUsers.length === 0) {
      setUserError(t('This field must be filled'));
      return false;
    }

    const { id } = meetingPoint ? meetingPoint : {};

    const { description } = data;
    const personInChargeUserId = selectedUsers[0].id;

    try {
      let result;
      if (id > 0) {
        // edit mode
        result = await MeetingService.editMeetingPoint(meetingId, meetingPoint.id, { personInChargeUserId, description });
      } else {
        // add mode
        result = await MeetingService.addMeetingPoint(meetingId, { personInChargeUserId, description });
      }

      showSuccessAlert('Data has been saved successfully')
        .then(() => {
          onClose(result)
        });
    } catch (error) {
      showErrorAlert(error, { defaultMessage: 'Change data is failed' });
    }
  }

  const onChangeUserInput = (user) => {
    if (user && user.id) {
      setSelectedUsers([user]);
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalHeader toggle={onClose}>
        {t('Add Meeting Point')}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
          <FormGroup className="form-row">
            <Label htmlFor="subject">{t('Person In Charge')} <span className="text-danger">(*)</span></Label>
            <UserInput users={selectedUsers} onChange={onChangeUserInput} canEdit={true} />
            <span className="text-danger">{userError}</span>
          </FormGroup>

          <FormGroup className="form-row">
            <Label htmlFor="description">{t('Information')} <span className="text-danger">(*)</span></Label><br />
            <Input
              type="textarea"
              rows="3"
              name="description"
              defaultValue={meetingPoint && meetingPoint?.description}
              innerRef={
                register({
                  required: t('This field must be filled')
                })
              }
            />
            <span color="danger">{errors.description && errors.description.message}</span>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>{t('buttons_Cancel')}</Button>
        <Button color="primary" onClick={handleSubmit(onSubmitHandler)}>{t('buttons_Save')}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default translate(EditModal);
