import React from 'react';
import { Row, Col } from 'reactstrap';
import { t } from 'react-switch-lang';
import { Info } from 'react-feather';
import nl2br from 'react-nl2br';

import { StatusInfo, ThreadList } from '../../components/ui-kits';
import { displayDateTime } from '../../util/FormaterHelper';

const DetailReligiousConsultation = (props) => {
  const { religiousConsultation, isLoadingThread, threads } = props;

  return (
    <>
      { religiousConsultation &&
        <Row>
          <Col sm="7">
            <Row>
              <Col sm="8">
              <p><b>{t('Registration Number')}</b> { religiousConsultation.registrationNumber }</p>
              <StatusInfo status={religiousConsultation.status} doneText={t('status_TL')} />
              </Col>
              <Col sm="4" className="text-right">
              <div className="border-bottom">{ displayDateTime(religiousConsultation.createdAt) }</div>
              <small>{t('Input Date')}</small>
              </Col>
            </Row>

            <Row>
              <Col sm="12" className="mt-3">
                <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                <b>{t('Full Name')}</b><br />
                {religiousConsultation.fullName}
              </Col>
            </Row>

            <Row>
              <Col sm="12" className="mt-3">
                <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                <b>{t('Address')}</b><br />
                {religiousConsultation.address}
              </Col>
            </Row>

            <Row>
              <Col sm="12" className="mt-3">
                <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                <b>{t('Phone')}</b><br />
                {religiousConsultation.phone}
              </Col>
            </Row>

            <Row>
              <Col sm="12" className="mt-3">
                <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                <b>{t('Email')}</b><br />
                {religiousConsultation.email}
              </Col>
            </Row>

            <Row>
              <Col sm="12" className="mt-3">
                <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                <b>{t('Religion')}</b><br />
                {religiousConsultation.religion}
              </Col>
            </Row>

            <Row>
              <Col sm="12" className="mt-3">
                <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                <b>{t('Service Type')}</b><br />
                {religiousConsultation.serviceType}
              </Col>
            </Row>

            <Row>
              <Col sm="12" className="mt-3">
                <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                <b>{t('Question')}</b><br />
                {nl2br(religiousConsultation.question)}
              </Col>
            </Row>
          </Col>
          <Col sm="5">
            <ThreadList
              loading={isLoadingThread}
              threads={threads}
            />
          </Col>
        </Row>
      }
    </>
  )
}

export default DetailReligiousConsultation;
