import { t } from 'react-switch-lang';

export const getWorkUnitName = (workUnitId, workUnits) => {
  if (Array.isArray(workUnits)) {
    const workUnit = workUnits.filter((workUnit) => workUnit.id === workUnitId);
    if (workUnit.length > 0) {
      return workUnit[0].name;
    }
  }

  return t('Unknown');
}

export const getPositionName = (positionId, positions) => {
  if (Array.isArray(positions)) {
    const position = positions.filter((position) => position.id === positionId);
    if (position.length > 0) {
      return position[0].name;
    }
  }

  return t('Unknown');
}

export const getCourierName = (courierId, couriers) => {
  if (Array.isArray(couriers)) {
    const courier = couriers.filter((courier) => courier.id === courierId);
    if (courier.length > 0) {
      return courier[0].name;
    }
  }

  return t('Unknown');
}
