import React, { useEffect, useState, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'sweetalert2';

import generateTableColumns from './settings/table-column';
import { Breadcrumb } from '../../components/layout';
import { DataTableWithPagination, LoadingInfo } from '../../components/ui-kits';
import { fetchLetters, destroyLetter } from './letterSlice';
import { EditModal, DetailModal } from './modals';
import { getCourierName } from '../../util/ModelReferenceHelper';
import LetterSearchFilter from './LetterSearchFilter';
import * as CONSTANTS from '../../app/constants';

const LetterIndex = (props) => {
  const letters = useSelector(state => state.letter.letters);
  const totalLetter = useSelector(state => state.letter.totalLetter);
  const isLoadingAPI = useSelector(state => state.letter.loading);
  const couriers = useSelector(state => state.courier.couriers);

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenDetailModal, setIsOpenDetailModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const [page, setPage] = useState(1);
  const [searchFilters, setSearchFilters] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLetters({ page, pagesize: CONSTANTS.NUMBER_PER_PAGE, ...searchFilters }));
  }, [dispatch, page, searchFilters]);

  const editHandler = (row) => {
    setSelectedRow(row);
    setIsOpenEditModal(true);
  }

  const editModalOnCloseHandler = () => {
    setIsOpenEditModal(false);
  }

  const detailHandler = (row) => {
    setSelectedRow(row);
    setIsOpenDetailModal(true);
  }

  const detailModalOnCloseHandler = () => {
    setIsOpenDetailModal(false);
  }

  const onRowClicked = (row) => {
    detailHandler(row);
  }

  const onSearchHandler = (filters) => {
    setSearchFilters(filters);
    setPage(1);
  }

  const tableColumns = useMemo(() => {
    const destroyHandler = (row) => {
      SweetAlert.fire({
        title: props.t('Are you sure to delete this letter?'),
        cancelButtonText: props.t('buttons_Cancel'),
        confirmButtonText: props.t('buttons_Delete'),
        reverseButtons: true,
        showCancelButton: true,
      }).then(({isConfirmed}) => {
        if (isConfirmed) {
          dispatch(destroyLetter(row.id)).then(({ error }) => {
            if (error) {
              // get proper error message from server
              const errorMessage = error && error.message ? props.t(error.message) : props.t('Delete data is failed');

              // trigger error alert
              SweetAlert.fire({
                title: props.t('Error Occured'), text: errorMessage, icon: 'error'
              });
            } else {
              SweetAlert.fire({
                title: props.t('Success'), text: props.t('Data has been deleted successfully'), icon: 'success'
              });
            }
          });
        }
      });
    }

    return generateTableColumns(editHandler, destroyHandler, (courierId) => getCourierName(courierId, couriers));
  }, [dispatch, props, couriers]);

  return (
    <React.Fragment>
      <Breadcrumb parent={props.t('Letter Disposition')} headerTitle={props.t('Letter Disposition Management')} title={props.t('Index')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <LetterSearchFilter onSearch={onSearchHandler} />
            <Card>
              <CardBody>
                <DataTableWithPagination
                  data={letters}
                  columns={tableColumns}
                  totalRow={totalLetter}
                  onPageChange={(page) => setPage(page)}
                  onRowClicked={onRowClicked}
                  highlightOnHover={true}
                  pointerOnHover={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <EditModal isOpen={isOpenEditModal} letter={selectedRow} onClose={editModalOnCloseHandler} />
        <DetailModal isOpen={isOpenDetailModal} letter={selectedRow} onClose={detailModalOnCloseHandler} />
      </Container>
      <LoadingInfo loading={isLoadingAPI} />
    </React.Fragment>
  )
}

export default translate(LetterIndex);
