import React, { useState } from 'react';
import { Button, Card, CardBody, CardFooter, Container, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Dropzone from 'react-dropzone-uploader';
import DatePicker from 'react-datepicker';

import { apiUrl, attachmentAcceptMimeType } from '../../app/config';
import { addDocument } from './documentSlice';
import { addTypeaheadSenders } from '../typeahead/typeaheadSlice';
import { Breadcrumb } from '../../components/layout';
import { SenderTypeaheadInput } from '../../components/ui-kits';
import { showErrorAlert, showHtmlSuccessAlert, showConfirmationAlert } from '../../app/alertResponse.handler';
import AttachmentList from '../attachments/AttachmentList';
import UserInput from '../user/UserInput';

const AddDocument = ({ t }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userError, setUserError] = useState(undefined);
  const [attachments, setAttachments] = useState([]);
  const [senderNameRef, setSenderNameRef] = useState(null);

  const jwtToken = useSelector(state => state.auth.jwtToken);

  const { reset, handleSubmit, control, errors, getValues } = useForm();
  const dispatch = useDispatch();

  // https://react-dropzone-uploader.js.org/docs/api#getuploadparams
  const getUploadParams = ({ file, meta }) => {
    const body = new FormData()
    body.append('file', file);

    return {
      url: `${apiUrl}/attachments/document/0`,
      headers: {
        'Authorization' : `Bearer ${jwtToken}`
      },
      body,
    }
  }

  /**
   * handle response from upload attachment request
   */
  const handleChangeStatus = (file, status) => {
    if (status === 'done'){
      const response = JSON.parse(file.xhr.response);
      if (response?.filename) {
        setAttachments([...attachments, response]);
      }

      setTimeout(() => {
        file.remove()
      }, 5000);
    }
  };

  const onSubmitHandler = (data, e) => {
    if (!Array.isArray(selectedUsers) || selectedUsers.length === 0) {
      setUserError(t('This field must be filled'));
      return false;
    }

    // typeahead is returning array and item object ([{id, name}])
    let senderName = '';
    let isNewSenderName = false;
    if (Array.isArray(data.senderName) && data.senderName.length > 0) {
      senderName = data.senderName[0].name;
      isNewSenderName = data.senderName[0].customOption === true;
    }

    const receiverUserId = selectedUsers[0].id;

    // TODO: we should show error here that senderName is empty
    if (!senderName || !receiverUserId) {
      return false;
    }

    // ask if the user really want to register without attachment
    if (attachments.length === 0) {
      showConfirmationAlert('Are you sure to register document without attachment?')
        .then(({isConfirmed}) => {
          if (isConfirmed) {
            submitToApi(data, senderName, receiverUserId, attachments, isNewSenderName);
          }
        });
    } else {
      submitToApi(data, senderName, receiverUserId, attachments, isNewSenderName);
    }
  }

  const submitToApi = (data, senderName, receiverUserId, attachments, isNewSenderName) => {
    dispatch(addDocument({ ...data, senderName, receiverUserId, attachments })).then((payload) => {
      const { error } = payload;
      if (error) {
        showErrorAlert(error, { defaultMessage: 'Add data is failed' });
      } else {
        const { registrationNumber } = payload.payload;

        // get html message to show
        const htmlMessage = t('Data has been created successfully') + `<br>${t('Registration Number')}: <b>${registrationNumber}</b><br>${t('Write this registration number on the document')}`;

        showHtmlSuccessAlert(htmlMessage)
          .then(() => {
            clearForm(); // reset after form submit

            // register new typeahead for sender name
            if (isNewSenderName) {
              dispatch(addTypeaheadSenders({ name: senderName }));
            }
          });
      }
    })
    .catch((error) => {
      // TODO: need to show error here
      console.log(error);
    });
  }

  const onChangeUserInput = (user) => {
    if (user && user.id) {
      setSelectedUsers([user]);
    }
  }

  /**
   * clear form after submit or just reset form
   */
  const clearForm = () => {
    reset();
    setAttachments([]);
    if (senderNameRef) {
      senderNameRef.clear();
    }
    // TODO: clear dropzone
  }

  const onRemoveAttachment = (filename) => {
    const list = attachments.filter((attachment) => attachment.filename !== filename);
    setAttachments(list);
  }

  return (
    <React.Fragment>
      <Breadcrumb parent={t('Document Agenda')} headerTitle={t('Add Document Agenda')} title={t('Add Document Agenda')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="subject">{t('Received Date')} <span className="text-danger">(*)</span></Label>
                    <Col sm="9">
                      <Controller
                        control={control}
                        render={({ onChange, onBlur, value, name, ref }) => (
                          <DatePicker
                            selected={value}
                            onChange={e => onChange(e)}
                            name={name}
                            className="form-control digits"
                            dateFormat="dd/MM/yyyy"
                            />
                        )}
                        name="receivedAt"
                        defaultValue=""
                        rules={{
                          required: t('This field must be filled'),
                        }}
                      />
                      <span className="text-danger">{errors.receivedAt && errors.receivedAt.message}</span>
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="senderName">{t('Sender Name')} <span className="text-danger">(*)</span></Label>
                    <Col sm="9">
                      <Controller
                        control={control}
                        render={({ onChange, onBlur, value, name, ref }) => (
                          <SenderTypeaheadInput
                            onBlur={onBlur}
                            onChange={e => onChange(e)}
                            name={name}
                            getRef={setSenderNameRef}
                            />
                        )}
                        name="senderName"
                        defaultValue=""
                        rules={{
                          required: t('This field must be filled'),
                          validate: (value) => {
                            const currentValue = getValues('senderName');
                            if (Array.isArray(currentValue) && currentValue.length === 0) {
                              return t('This field must be filled');
                            }

                            return true;
                          },
                        }}
                      />
                      <span color="danger">{errors.senderName && errors.senderName.message}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="receiverUser">{t('Receiver')} <span className="text-danger">(*)</span></Label>
                    <Col sm="9">
                      <UserInput users={selectedUsers} onChange={onChangeUserInput} canEdit={true} />
                      <span className="text-danger">{userError}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="attachment">{t('Attachments')} <span className="text-danger">(*)</span></Label>
                    <Col sm="9">
                      <AttachmentList
                        attachments={attachments}
                        canRemove={true}
                        onRemove={onRemoveAttachment}
                      />

                      <div className="dz-message needsclick">
                        <Dropzone
                          accept={attachmentAcceptMimeType}
                          getUploadParams={getUploadParams}
                          onChangeStatus={handleChangeStatus}
                          inputContent={`${t('Upload Document')} (PDF, PNG, JPG)`}
                        />
                      </div>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button color="secondary" className="mr-2" onClick={() => clearForm()}>{t('buttons_Cancel')}</Button>
                <Button color="primary" onClick={handleSubmit(onSubmitHandler)}>{t('buttons_Save')}</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default translate(AddDocument);
