import React, { useEffect, useState, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { translate } from 'react-switch-lang';
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'sweetalert2';

import generateTableColumns from './settings/table-column';
import { Breadcrumb } from '../../components/layout';
import { fetchTemples, destroyTemple } from './templeSlice';
import { EditModal } from './modals';

const TempleIndex = (props) => {
  const temples = useSelector(state => state.temple.temples);

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const dispatch = useDispatch();

  const editHandler = (row) => {
    setSelectedRow(row);
    setIsOpenEditModal(true);
  }

  const editModalOnCloseHandler = () => {
    setIsOpenEditModal(false);
  }

  const tableColumns = useMemo(() => {
    const deleteHandler = (row) => {
      SweetAlert.fire({
        title: props.t('Are you sure to destroy this temple?'),
        cancelButtonText: props.t('buttons_Cancel'),
        confirmButtonText: props.t('buttons_Delete'),
        reverseButtons: true,
        showCancelButton: true,
      }).then(({isConfirmed}) => {
        if (isConfirmed) {
          dispatch(destroyTemple(row.id));
        }
      });
    }

    return generateTableColumns(editHandler, deleteHandler);
  }, [dispatch, props])

  useEffect(() => {
    dispatch(fetchTemples());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Breadcrumb parent={props.t('Temple')} headerTitle={props.t('Temple Management')} title={props.t('Index')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTable
                  data={temples}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  selectableRows={false}
                  selectableRowsHighlight
                  noHeader
                  pagination
                  paginationServer
                  noDataComponent={props.t('There are no records to display')}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <EditModal isOpen={isOpenEditModal} temple={selectedRow} onClose={editModalOnCloseHandler} />
      </Container>
    </React.Fragment>
  )
}

export default translate(TempleIndex);
