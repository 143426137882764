import React, { useState, useMemo } from 'react';
import { Container, Button, Row, Col, Card, CardBody, FormGroup, Label } from 'reactstrap';
import { t } from 'react-switch-lang';
import DatePicker from 'react-datepicker';
import { toast, ToastContainer } from 'react-toastify';
import dayjs from 'dayjs';

import generateTableColumns from './settings/reportIndex-table-column';
import { Breadcrumb } from '../../components/layout';
import { DataTableWithPagination, ReportTypeSelectInput } from '../../components/ui-kits';
import SatisfactionIndexService from './satisfactionIndex.service';
import { saveXlsFile } from '../../util/FileSaverHelper';
import * as CONSTANTS from '../../app/constants';

const ReportIndex = (props) => {
  const [timeScale, setTimeScale] = useState('daily');
  const [startDate, setStartDate] = useState(dayjs().startOf('month').toDate());
  const [endDate, setEndDate] = useState(new Date());
  const [reportRows, setReportRows] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [page, setPage] = useState(1);
  const [isRunningReport, setIsRunningReport] = useState(false);

  const tableColumns = useMemo(() => {
    const keyName = timeScale === 'monthly' ? 'Bulan' : 'Tanggal';
    return generateTableColumns(keyName);
  }, [timeScale])

  const onChangeReportType = (value) => {
    setTimeScale(value);
  }

  const onStartDateChange = (value) => {
    setStartDate(value);
  }

  const onEndDateChange = (value) => {
    setEndDate(value);
  }

  const onExportButtonClick = async () => {
    try {
      const result = await SatisfactionIndexService.reportIndex(timeScale, {
        start: dayjs(startDate).format('DD-MM-YYYY'),
        end: dayjs(endDate).format('DD-MM-YYYY'),
        format: 'xls',
        page,
        pagesize: CONSTANTS.NUMBER_PER_PAGE
      });

      saveXlsFile(result, 'iklan.xlsx');
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // dispatch(setError('Username or password is wrong!'));
      } else {
        toast.error('An error occured on server. Please try login or contact admin')
      }
    }
  }

  const onPageChange = async (page) => {
    try {
      setPage(page);

      const { result, total } = await SatisfactionIndexService.reportIndex(timeScale, {
        start: dayjs(startDate).format('DD-MM-YYYY'),
        end: dayjs(endDate).format('DD-MM-YYYY'),
        format: 'json',
        page,
        pagesize: CONSTANTS.NUMBER_PER_PAGE
      });

      setIsRunningReport(true);
      setReportRows(result);
      setTotalRow(total);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // dispatch(setError('Username or password is wrong!'));
      } else {
        toast.error('An error occured on server. Please try login or contact admin')
      }
    }
  }

  return (
    <React.Fragment>
      <Breadcrumb parent={t('Satisfaction Index')} headerTitle={t('Satisfaction Index')} title={t('Report')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <FormGroup className="form-row mb-0">
                  <Label className="col-sm-3" htmlFor="description">{t('Report Type')}</Label><br />
                  <Col sm="9">
                    <ReportTypeSelectInput
                      selectedValue={timeScale}
                      onChange={onChangeReportType}
                    />
                  </Col>
                </FormGroup>

                <FormGroup className="form-row mb-1">
                  <Label className="col-sm-3 col-form-label" htmlFor="description">{t('Start Date')}</Label>
                  <Col sm="3">
                    <DatePicker
                      selected={startDate}
                      onChange={e => onStartDateChange(e)}
                      className="form-control digits"
                      dateFormat="dd/MM/yyyy"
                      />
                  </Col>
                </FormGroup>

                <FormGroup className="form-row mb-2">
                  <Label className="col-sm-3 col-form-label" htmlFor="description">{t('End Date')}</Label><br />
                  <Col sm="3">
                    <DatePicker
                      selected={endDate}
                      onChange={e => onEndDateChange(e)}
                      className="form-control digits"
                      dateFormat="dd/MM/yyyy"
                      />
                  </Col>
                </FormGroup>
                <FormGroup className="form-row mb-0">
                  <Col sm={{ size: 9, offset: 3}}>
                    <Button className="mr-1" color="success" onClick={() => onPageChange(1)}>{t('buttons_Run_Report')}</Button>
                    <Button color="dark" onClick={() => onExportButtonClick('xls')}>{t('buttons_Xls_Export')}</Button>
                  </Col>
                </FormGroup>
              </CardBody>
            </Card>

            { isRunningReport &&
            <Card>
              <CardBody>
                <DataTableWithPagination
                  data={reportRows}
                  columns={tableColumns}
                  totalRow={totalRow}
                  onPageChange={page => onPageChange(page)}
                />
              </CardBody>
            </Card>
            }
          </Col>
        </Row>
      </Container>

      <ToastContainer />
    </React.Fragment>
  )
}

export default ReportIndex;
