import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { t } from 'react-switch-lang';
import nl2br from 'react-nl2br';
import { useSelector } from 'react-redux';
import { Info, Calendar } from 'react-feather';
import Dropzone from 'react-dropzone-uploader';

import { StatusInfo } from '../../components/ui-kits';
import AttachmentList from '../attachments/AttachmentList';
import { apiUrl, attachmentAcceptMimeType } from '../../app/config';
import AttachmentService from '../attachments/attachment.service';
import { showErrorAlert, showConfirmationAlert } from '../../app/alertResponse.handler';
import { displayDateOnly } from '../../util/FormaterHelper';

const DetailMeetingPoint = (props) => {
  const jwtToken = useSelector(state => state.auth.jwtToken);

  const { meetingPoint, canUploadAttachment, canRemoveAttachment } = props;
  const [isLoadingAttachment, setIsLoadingAttachment] = useState(false);
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    const fetchAttachments = async () => {
      const { id } = meetingPoint ? meetingPoint : {};

      if (id) {
        setIsLoadingAttachment(true);
        try {
          const result = await AttachmentService.fetchAttachments('meetingPoint', id);
          setAttachments(result);
        } catch (error) {
          // TODO: do we need to show error here
        }

        setIsLoadingAttachment(false);
      }
    }

    fetchAttachments();
  }, [meetingPoint]);

  // https://react-dropzone-uploader.js.org/docs/api#getuploadparams
  const getUploadParams = ({ file, meta }) => {
    const body = new FormData()
    body.append('file', file);

    return {
      url: `${apiUrl}/attachments/meetingPoint/${meetingPoint?.id || 0}`,
      headers: {
        'Authorization' : `Bearer ${jwtToken}`
      },
      body,
    }
  }

  /**
   * handle response from upload attachment request
   */
  const handleChangeStatus = (file, status) => {
    if (status === 'done'){
      const response = JSON.parse(file.xhr.response);
      if (response?.filename) {
        setAttachments([...attachments, response]);
      }

      setTimeout(() => {
        file.remove()
      }, 5000);
    }
  }

  const onRemoveAttachment = (id) => {
    showConfirmationAlert('Are you sure to delete attachment?')
      .then(async ({isConfirmed}) => {
        if (isConfirmed) {
          try {
            await AttachmentService.destroyAttachment('meetingPoint', meetingPoint?.id, id);

            const list = attachments.filter((attachment) => attachment.id !== id);
            setAttachments(list);
          } catch (error) {
            showErrorAlert(error, { defaultMessage: 'Delete attachment is failed' });
          }
        }
      });
  }

  return (
    <>
    { meetingPoint &&
      <Row>
        <Col sm="7">
        { meetingPoint &&
            <>
              <Row>
                <Col sm="8">
                <p><b>{t('Registration Number')}</b> { meetingPoint.meeting?.registrationNumber }</p>
                <StatusInfo status={meetingPoint.status} />
                </Col>
                <Col sm="4" className="text-right">
                <div className="border-bottom">{ displayDateOnly(meetingPoint.meeting?.meetingAt) }</div>
                <small>{t('Meeting Date')}</small>
                </Col>
              </Row>

              <Row>
                <Col sm="12" className="mt-3">
                  <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                  <b>{t('Meeting Point')}</b><br />
                  {meetingPoint.description}
                </Col>
              </Row>

              <Row>
                <Col sm="12" className="mt-3">
                  <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                  <b>{t('Person In Charge')}</b><br />
                  {meetingPoint.personInCharge?.fullName}
                </Col>
              </Row>

              { meetingPoint.status === 1 &&
              <Row>
                <Col sm="12" className="mt-3">
                  <div style={{ width: 40, fontSize: 20}} className="float-left"><Calendar /></div>
                  <b>{t('Finished Date')}</b><br />
                  {displayDateOnly(meetingPoint.finishedAt)}
                </Col>
              </Row>
              }

              { meetingPoint.responseText &&
              <Row>
                <Col sm="12" className="mt-3">
                  <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                  <div className="float-left">
                  <b>{t('Last Respond')}</b><br />
                  {nl2br(meetingPoint.responseText)}
                  </div>
                </Col>
              </Row>
              }
            </>
          }
        </Col>
        <Col sm="5">
          <AttachmentList
            attachments={attachments}
            module="meetingPoint"
            moduleId={meetingPoint && meetingPoint.id}
            canRemove={canRemoveAttachment}
            onRemove={onRemoveAttachment}
            loading={isLoadingAttachment}
          />

          { canUploadAttachment &&
            <div className="dz-message needsclick col-12">
              <Dropzone
                accept={attachmentAcceptMimeType}
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                inputContent={`${t('Upload Document')} (PDF, PNG, JPG)`}
              />
            </div>
          }
        </Col>
      </Row>
    }
    </>
  )
}

export default DetailMeetingPoint;
