import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import ServiceTypeConsultationService from './serviceTypeConsultation.service';
import { thunkHandler } from '../../app/thunk.handler';

export const fetchServiceTypeConsultations = createAsyncThunk('serviceTypeConsultations/fetchServiceTypeConsultations', async (payload, thunkAPI) => {
  return thunkHandler(ServiceTypeConsultationService.fetchServiceTypeConsultations(payload), thunkAPI);
});

export const destroyServiceTypeConsultation = createAsyncThunk('serviceTypeConsultations/destroy', async (id, thunkAPI) => {
  return thunkHandler(ServiceTypeConsultationService.destroyServiceTypeConsultation(id), thunkAPI);
});

export const addServiceTypeConsultation = createAsyncThunk('serviceTypeConsultations/addServiceTypeConsultation', async (payload, thunkAPI) => {
  return thunkHandler(ServiceTypeConsultationService.addServiceTypeConsultation(payload), thunkAPI);
});

export const updateServiceTypeConsultation = createAsyncThunk('serviceTypeConsultations/updateServiceTypeConsultation', async (payload, thunkAPI) => {
  const { id, ...data } = payload;

  return thunkHandler(ServiceTypeConsultationService.updateServiceTypeConsultation(id, data), thunkAPI);
});

const updateServiceTypeConsultationsState = (serviceTypeConsultations, serviceTypeConsultation) => {
  const updateServiceTypeConsultations = serviceTypeConsultations.reduce((result, item) => {
    if (serviceTypeConsultation.id && item.id === serviceTypeConsultation.id) {
      result.push(serviceTypeConsultation);
    } else {
      result.push(item);
    }

    return result;
  }, []);

  return updateServiceTypeConsultations;
}

const serviceTypeConsultationSlice = createSlice({
  name: 'serviceTypeConsultation',
  initialState: {
    serviceTypeConsultations: [],
    totalServiceTypeConsultation: 0,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchServiceTypeConsultations.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchServiceTypeConsultations.fulfilled]: (state, action) => {
      state.loading = false;
      state.serviceTypeConsultations = action.payload.result;
      state.totalServiceTypeConsultation = action.payload.total;
    },
    [fetchServiceTypeConsultations.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [destroyServiceTypeConsultation.pending]: (state, action) => {
      state.loading = true;
    },
    [destroyServiceTypeConsultation.fulfilled]: (state, action) => {
      state.loading = false;
      state.serviceTypeConsultations = state.serviceTypeConsultations.filter((item) => item.id !== action.payload.id);
    },
    [destroyServiceTypeConsultation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [addServiceTypeConsultation.pending]: (state, action) => {
      state.loading = true;
    },
    [addServiceTypeConsultation.fulfilled]: (state, action) => {
      state.loading = false;
      state.serviceTypeConsultations.unshift(action.payload);
    },
    [addServiceTypeConsultation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [updateServiceTypeConsultation.pending]: (state, action) => {
      state.loading = true;
    },
    [updateServiceTypeConsultation.fulfilled]: (state, action) => {
      state.loading = false;
      state.serviceTypeConsultations = updateServiceTypeConsultationsState(state.serviceTypeConsultations, action.payload);
    },
    [updateServiceTypeConsultation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  }
})

export default serviceTypeConsultationSlice.reducer;
