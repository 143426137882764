import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import DocumentService from './document.service';
import { thunkHandler } from '../../app/thunk.handler';

export const fetchDocuments = createAsyncThunk('documents/fetchDocuments', async (payload, thunkAPI) => {
  return thunkHandler(DocumentService.fetchDocuments(), thunkAPI);
});

export const destroyDocument = createAsyncThunk('documents/destroy', async (id, thunkAPI) => {
  return thunkHandler(DocumentService.destroyDocument(id), thunkAPI);
});

export const addDocument = createAsyncThunk('documents/addDocument', async (payload, thunkAPI) => {
  return thunkHandler(DocumentService.addDocument(payload), thunkAPI);
});

export const updateDocument = createAsyncThunk('documents/updateDocument', async (payload, thunkAPI) => {
  const { id, ...data } = payload;

  return thunkHandler(DocumentService.updateDocument(id, data), thunkAPI);
});

export const fetchTaskDocuments = createAsyncThunk('documents/fetchTaskDocuments', async (payload, thunkAPI) => {
  return thunkHandler(DocumentService.fetchTaskDocuments(payload), thunkAPI);
});

const updateDocumentsState = (documents, document) => {
  const updateDocuments = documents.reduce((result, item) => {
    if (document.id && item.id === document.id) {
      result.push(document);
    } else {
      result.push(item);
    }

    return result;
  }, []);

  return updateDocuments;
}

const documentSlice = createSlice({
  name: 'document',
  initialState: {
    documents: [],
    taskDocuments: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchDocuments.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchDocuments.fulfilled]: (state, action) => {
      state.loading = false;
      state.documents = action.payload;
    },
    [fetchDocuments.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [destroyDocument.pending]: (state, action) => {
      state.loading = true;
    },
    [destroyDocument.fulfilled]: (state, action) => {
      state.loading = false;
      state.documents = state.documents.filter((item) => item.id !== action.payload.id);
    },
    [destroyDocument.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [addDocument.pending]: (state, action) => {
      state.loading = true;
    },
    [addDocument.fulfilled]: (state, action) => {
      state.loading = false;
      state.documents.unshift(action.payload);
    },
    [addDocument.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [updateDocument.pending]: (state, action) => {
      state.loading = true;
    },
    [updateDocument.fulfilled]: (state, action) => {
      state.loading = false;
      state.documents = updateDocumentsState(state.documents, action.payload);
      state.taskDocuments = updateDocumentsState(state.taskDocuments, action.payload);
    },
    [updateDocument.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [fetchTaskDocuments.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchTaskDocuments.fulfilled]: (state, action) => {
      state.loading = false;
      state.taskDocuments = action.payload.result ? action.payload.result : []
    },
    [fetchTaskDocuments.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  }
})

export default documentSlice.reducer;
