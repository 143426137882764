import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Dropzone from 'react-dropzone-uploader';
import DatePicker from 'react-datepicker';

import { apiUrl, attachmentAcceptMimeType } from '../../../app/config';
import { updateDocument } from '../documentSlice';
import { addTypeaheadSenders } from '../../typeahead/typeaheadSlice';
import { SenderTypeaheadInput } from '../../../components/ui-kits';
import AttachmentList from '../../attachments/AttachmentList';
import AttachmentService from '../../attachments/attachment.service';
import { showErrorAlert, showSuccessAlert, showConfirmationAlert } from '../../../app/alertResponse.handler';
import UserInput from '../../user/UserInput';

const EditModal = ({ isOpen, document, onClose, t })  => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userError, setUserError] = useState(undefined);
  const [attachments, setAttachments] = useState([]);

  const jwtToken = useSelector(state => state.auth.jwtToken);

  const { handleSubmit, errors, control, getValues } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (document && document.receiverUser) {
      setSelectedUsers([document.receiverUser]);
    }
    setAttachments(document && Array.isArray(document.Attachments) ? document.Attachments : []);
  }, [document]);

  // https://react-dropzone-uploader.js.org/docs/api#getuploadparams
  const getUploadParams = ({ file, meta }) => {
    const body = new FormData()
    body.append('file', file);

    return {
      url: `${apiUrl}/attachments/document/${document?.id || 0}`,
      headers: {
        'Authorization' : `Bearer ${jwtToken}`
      },
      body,
    }
  }

  /**
   * handle response from upload attachment request
   */
  const handleChangeStatus = (file, status) => {
    if (status === 'done'){
      const response = JSON.parse(file.xhr.response);
      if (response?.filename) {
        setAttachments([...attachments, response]);
      }

      setTimeout(() => {
        file.remove()
      }, 5000);
    }
  };

  const onSubmitHandler = (data) => {
    if (!Array.isArray(selectedUsers) || selectedUsers.length === 0) {
      setUserError(t('This field must be filled'));
      return false;
    }

    const { id } = document;

    // typeahead is returning array and item object ([{id, name}])
    let senderName = '';
    let isNewSenderName = false;
    if (Array.isArray(data.senderName) && data.senderName.length > 0) {
      senderName = data.senderName[0].name;
      isNewSenderName = data.senderName[0].customOption === true;
    }

    const receiverUserId = selectedUsers[0].id;

    // TODO: we should show error here that senderName is empty
    if (!senderName || !receiverUserId) {
      return false;
    }

    dispatch(updateDocument({ id, ...data, senderName, receiverUserId })).then(({ error }) => {
      if (error) {
        showErrorAlert(error, { defaultMessage: 'Change data is failed' });
      } else {
        showSuccessAlert('Data has been changed successfully')
          .then(() => {
            // register new typeahead for sender name
            if (isNewSenderName) {
              dispatch(addTypeaheadSenders({ name: senderName }));
            }

            onClose()
          });
      }
    })
    .catch((error) => {
      // TODO: need to show error here
      console.log(error);
    });
  }

  const onChangeUserInput = (user) => {
    if (user && user.id) {
      setSelectedUsers([user]);
    }
  }

  const onRemoveAttachment = (id) => {
    showConfirmationAlert('Are you sure to delete attachment?')
      .then(async ({isConfirmed}) => {
        if (isConfirmed) {
          try {
            await AttachmentService.destroyAttachment('document', document?.id, id);

            const list = attachments.filter((attachment) => attachment.id !== id);
            setAttachments(list);
          } catch (error) {
            showErrorAlert(error, { defaultMessage: 'Delete attachment is failed' });
          }
        }
      });
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="lg" centered style={{maxWidth: '1600px', width: '80%'}}>
      <ModalHeader toggle={onClose}>
        {t('Edit Document Agenda')}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
          <Row>
            <Col sm="7">
              <FormGroup className="form-row">
                <Label htmlFor="name">{t('Registration Number')} <span className="text-danger">(*)</span></Label>
                <Input
                  className="form-control btn-square"
                  name="name"
                  type="text"
                  disabled={true}
                  defaultValue={document && document.registrationNumber}
                />
              </FormGroup>

              <FormGroup className="form-row">
                <Label htmlFor="subject">{t('Received Date')} <span className="text-danger">(*)</span></Label>
                <Controller
                  control={control}
                  render={({ onChange, onBlur, value, name, ref }) => (
                    <div className="col-12 px-0">
                      <DatePicker
                        selected={value}
                        onChange={e => onChange(e)}
                        name={name}
                        className="form-control digits"
                        dateFormat="dd/MM/yyyy"
                        />
                    </div>
                  )}
                  name="receivedAt"
                  defaultValue={document && new Date(document.receivedAt)}
                  rules={{
                    required: t('This field must be filled'),
                  }}
                />
                <span className="text-danger">{errors.receivedAt && errors.receivedAt.message}</span>
              </FormGroup>

              <FormGroup className="form-row">
                <Label htmlFor="senderName">{t('Sender Name')} <span className="text-danger">(*)</span></Label>
                <Controller
                  control={control}
                  render={({ onChange, onBlur, value, name, ref }) => (
                    <div className="col-12">
                      <SenderTypeaheadInput
                        defaultValue={document && document.senderName}
                        onBlur={onBlur}
                        onChange={e => onChange(e)}
                        name={name}
                        />
                    </div>
                  )}
                  name="senderName"
                  defaultValue=""
                  rules={{
                    required: t('This field must be filled'),
                    validate: (value) => {
                      const currentValue = getValues('senderName');
                      if (Array.isArray(currentValue) && currentValue.length === 0) {
                        return t('This field must be filled');
                      }

                      return true;
                    },
                  }}
                />
                <span color="danger">{errors.senderName && errors.senderName.message}</span>
              </FormGroup>

              <FormGroup className="form-row">
                <Label htmlFor="receiverUser">{t('Receiver')} <span className="text-danger">(*)</span></Label>
                <UserInput users={selectedUsers} onChange={onChangeUserInput} canEdit={true} />
                <span className="text-danger">{userError}</span>
              </FormGroup>
            </Col>
            <Col sm="5">
              <FormGroup className="form-row">
                <Label htmlFor="attachment">{t('Attachments')} <span className="text-danger">(*)</span></Label>
                <AttachmentList
                  attachments={attachments}
                  module="document"
                  moduleId={document && document.id}
                  canRemove={true}
                  onRemove={onRemoveAttachment}
                />

                <div className="dz-message needsclick col-12">
                  <Dropzone
                    accept={attachmentAcceptMimeType}
                    getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    inputContent={`${t('Upload Document')} (PDF, PNG, JPG)`}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>{t('buttons_Cancel')}</Button>
        <Button color="primary" onClick={handleSubmit(onSubmitHandler)}>{t('buttons_Save')}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default translate(EditModal);
