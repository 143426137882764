import React, { useEffect, useState, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useDispatch, useSelector } from 'react-redux';

import generateTableColumns from './settings/publicComplaint-table-column';
import tableStyles from '../../app/general-table-styles';
import { Breadcrumb } from '../../components/layout';
import { DataTableWithPagination, LoadingInfo } from '../../components/ui-kits';
import { fetchTaskPublicComplaints } from '../publicComplaint/publicComplaintSlice';
import { PublicComplaintEditModal as EditModal } from './modals';
import * as CONSTANTS from '../../app/constants';

const PublicComplaintIndex = (props) => {
  const publicComplaints = useSelector(state => state.publicComplaint.taskPublicComplaints);
  const totalPublicComplaints = useSelector(state => state.publicComplaint.totalTaskPublicComplaints);
  const isLoadingAPI = useSelector(state => state.publicComplaint.loading);

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTaskPublicComplaints({ page, pagesize: CONSTANTS.NUMBER_PER_PAGE }));
  }, [dispatch, page]);

  const editHandler = (row) => {
    setSelectedRow(row);
    setIsOpenEditModal(true);
  }

  const editModalOnCloseHandler = (success) => {
    setIsOpenEditModal(false);
  }

  const onRowClicked = (row) => {
    editHandler(row);
  }

  const tableColumns = useMemo(() => {
    return generateTableColumns();
  }, []);

  return (
    <React.Fragment>
      <Breadcrumb parent={props.t('Task')} headerTitle={props.t('Public Complaint')} title={props.t('Public Complaint')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTableWithPagination
                  data={publicComplaints}
                  columns={tableColumns}
                  totalRow={totalPublicComplaints}
                  onPageChange={(page) => setPage(page)}
                  onRowClicked={onRowClicked}
                  highlightOnHover={true}
                  pointerOnHover={true}
                  customStyles={tableStyles}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <EditModal isOpen={isOpenEditModal} publicComplaint={selectedRow} onClose={editModalOnCloseHandler} />
      </Container>
      <LoadingInfo loading={isLoadingAPI} />
    </React.Fragment>
  )
}

export default translate(PublicComplaintIndex);
