import React from 'react';
import { t } from 'react-switch-lang';
import LoadingOverlay from 'react-loading-overlay';
import styled, { css } from "styled-components";

// https://codesandbox.io/s/react-loading-overlay-3w274?from-embed=&file=/src/index.js:1235-1271
const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left:0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */

  ${props =>
    props.show &&
    css`
      display: block; /* show */
    `}
`;

const Spinner22 = (props) => {
  return (
    <div className="loader-box">
      <div className="loader-22"></div>
    </div>
  )
}

const LoadingInfo = ({ loading, children }) => {
  return (
    <DarkBackground show={loading}>
      <LoadingOverlay
        active={true}
        // spinner={<BounceLoader />}
        spinner={<Spinner22 />}
        // spinner={true}
        text={t('Please Wait')}
      >
        {children}
      </LoadingOverlay>
    </DarkBackground>
  );
}

export default LoadingInfo;
