import React, { useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { t } from 'react-switch-lang';

import TypeaheadService from '../../features/typeahead/typeahead.service';

const SenderTypeaheadInput = ({ defaultValue, name, onBlur, onChange, getRef }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = async (query) => {
    if (!query) {
      return;
    }
    setIsLoading(true);

    try {
      const result = await TypeaheadService.fetchTypeaheadSenders(query);
      setOptions(result);
    } catch (error) {
      setOptions([]);
    }

    setIsLoading(false);
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  const getRefFn = getRef ? getRef : () => {};

  return (
    <AsyncTypeahead
      id="sender-typeahead"
      onBlur={onBlur}
      onChange={e => onChange(e)}
      filterBy={filterBy}
      isLoading={isLoading}
      labelKey="name"
      name={name}
      allowNew
      clearButton
      multiple={false}
      onSearch={handleSearch}
      options={options}
      placeholder={t('Input Sender Name')}
      newSelectionPrefix={t('New selection:')}
      defaultSelected={defaultValue ? [defaultValue] : []}
      ref={(ref) => getRefFn(ref) }
    />
  )
}

export default SenderTypeaheadInput;
