import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import CourierService from './courier.service';
import { thunkHandler } from '../../app/thunk.handler';

export const fetchCouriers = createAsyncThunk('couriers/fetchCouriers', async (payload, thunkAPI) => {
  return thunkHandler(CourierService.fetchCouriers(), thunkAPI);
});

export const addCourier = createAsyncThunk('couriers/addCourier', async (payload, thunkAPI) => {
  return thunkHandler(CourierService.addCourier(payload), thunkAPI);
});

export const updateCourier = createAsyncThunk('couriers/updateCourier', async (payload, thunkAPI) => {
  const { id, ...data } = payload;

  return thunkHandler(CourierService.updateCourier(id, data), thunkAPI);
});

const updateCouriersState = (couriers, courier) => {
  const updateCouriers = couriers.reduce((result, item) => {
    if (courier.id && item.id === courier.id) {
      result.push(courier);
    } else {
      result.push(item);
    }

    return result;
  }, []);

  return updateCouriers;
}

const courierSlice = createSlice({
  name: 'courier',
  initialState: {
    couriers: undefined,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchCouriers.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchCouriers.fulfilled]: (state, action) => {
      state.loading = false;
      state.couriers = action.payload;
    },
    [fetchCouriers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [addCourier.pending]: (state, action) => {
      state.loading = true;
    },
    [addCourier.fulfilled]: (state, action) => {
      state.loading = false;
      state.couriers.unshift(action.payload);
    },
    [addCourier.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [updateCourier.pending]: (state, action) => {
      state.loading = true;
    },
    [updateCourier.fulfilled]: (state, action) => {
      state.loading = false;
      state.couriers = updateCouriersState(state.couriers, action.payload);
    },
    [updateCourier.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    }
  }
})

export default courierSlice.reducer;
