import React, { useEffect, useState, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useDispatch, useSelector } from 'react-redux';

import generateTableColumns from './settings/serviceApplication-table-column';
import tableStyles from '../../app/general-table-styles';
import { Breadcrumb } from '../../components/layout';
import { DataTableWithPagination, LoadingInfo } from '../../components/ui-kits';
import { fetchTaskServiceApplications } from '../serviceApplication/serviceApplicationSlice';
import { ServiceApplicationEditModal as EditModal } from './modals';
import * as CONSTANTS from '../../app/constants';

const ServiceApplicationIndex = (props) => {
  const serviceApplications = useSelector(state => state.serviceApplication.taskServiceApplications);
  const totalServiceApplications = useSelector(state => state.serviceApplication.totalTaskServiceApplications);
  const isLoadingAPI = useSelector(state => state.serviceApplication.loading);

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTaskServiceApplications({ page, pagesize: CONSTANTS.NUMBER_PER_PAGE }));
  }, [dispatch, page]);

  const editHandler = (row) => {
    setSelectedRow(row);
    setIsOpenEditModal(true);
  }

  const editModalOnCloseHandler = (success) => {
    setIsOpenEditModal(false);
  }

  const onRowClicked = (row) => {
    editHandler(row);
  }

  const tableColumns = useMemo(() => {
    return generateTableColumns();
  }, [])

  return (
    <React.Fragment>
      <Breadcrumb parent={props.t('Task')} headerTitle={props.t('Service Application')} title={props.t('Service Application')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTableWithPagination
                  data={serviceApplications}
                  columns={tableColumns}
                  totalRow={totalServiceApplications}
                  onPageChange={(page) => setPage(page)}
                  onRowClicked={onRowClicked}
                  highlightOnHover={true}
                  pointerOnHover={true}
                  customStyles={tableStyles}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <EditModal isOpen={isOpenEditModal} serviceApplication={selectedRow} onClose={editModalOnCloseHandler} />
      </Container>
      <LoadingInfo loading={isLoadingAPI} />
    </React.Fragment>
  )
}

export default translate(ServiceApplicationIndex);
