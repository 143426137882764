import { t } from 'react-switch-lang';

const generateTableColumns = () => [
  {
    name: 'Id',
    selector: 'id',
    sortable: false,
    center: false,
    width: '65px',
  },
  {
    name: t('Courier Name'),
    selector: 'name',
    sortable: false,
    center: false,
    grow: 1,
  },
];

export default generateTableColumns;
