import React from 'react';
import { Input } from 'reactstrap';
import { t } from 'react-switch-lang';

const GenderInput = (props) => {
  const genders = React.useMemo(() => {
    return [{
      id: 1,
      name: t('Man')
    }, {
      id: 2,
      name: t('Woman')
    }];
  }, []);

  return (
    <Input
      type="select"
      { ...props }
    >
      <option key={0} value="">{t('Please Select')}</option>
      {genders.map(item =>
        <option key={item.id} value={item.id}>{item.name}</option>
      )};
    </Input>
  )
}

export default GenderInput;
