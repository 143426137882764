import axios from 'axios';

import { apiUrl } from '../../app/config';

/**
 * fetch users data from api
 */
class SettingService {
  /**
   * fetch users list
   */
  static async fetchSettings() {
    const url = `${apiUrl}/settings`;

    const response = await axios.get(url);
    return response.data;
  }

  static async updateSetting(data) {
    const url = `${apiUrl}/settings`;

    const response = await axios.put(url, data);
    return response.data;
  }
}

export default SettingService;
