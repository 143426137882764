import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import courierReducer from '../features/courier/courierSlice';
import letterReducer from '../features/letter/letterSlice';
import positionReducer from '../features/position/positionSlice';
import typeaheadReducer from '../features/typeahead/typeaheadSlice';
import userReducer from '../features/user/userSlice';
import workUnitReducer from '../features/workUnit/workUnitSlice';
import meetingReducer from '../features/meeting/meetingSlice';
import documentReducer from '../features/document/documentSlice';
import letterNumberReducer from '../features/letterNumber/letterNumberSlice';
import templeReducer from '../features/temple/templeSlice';
import publicComplaintReducer from '../features/publicComplaint/publicComplaintSlice';
import religiousConsultationReducer from '../features/religiousConsultation/religiousConsultationSlice';
import serviceApplicationReducer from '../features/serviceApplication/serviceApplicationSlice';
import newsReducer from '../features/news/newsSlice';
import holidayReducer from '../features/holiday/holidaySlice';
import staffOffdayReducer from '../features/staffOffday/staffOffdaySlice';
import taskReducer from '../features/task/taskSlice';
import serviceTypeApplicationReducer from '../features/serviceTypeApplication/serviceTypeApplicationSlice';
import serviceTypeConsultationReducer from '../features/serviceTypeConsultation/serviceTypeConsultationSlice';

export default configureStore({
  reducer: {
    auth: authReducer,
    courier: courierReducer,
    letter: letterReducer,
    position: positionReducer,
    typeahead: typeaheadReducer,
    user: userReducer,
    workUnit: workUnitReducer,
    meeting: meetingReducer,
    document: documentReducer,
    letterNumber: letterNumberReducer,
    temple: templeReducer,
    publicComplaint: publicComplaintReducer,
    religiousConsultation: religiousConsultationReducer,
    serviceApplication: serviceApplicationReducer,
    news: newsReducer,
    holiday: holidayReducer,
    staffOffday: staffOffdayReducer,
    task: taskReducer,
    serviceTypeApplication: serviceTypeApplicationReducer,
    serviceTypeConsultation: serviceTypeConsultationReducer,
  },
});
