import React, { useEffect, useState, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { translate } from 'react-switch-lang';
import { useDispatch, useSelector } from 'react-redux';

import generateTableColumns from './settings/letter-table-column';
import tableStyles from './settings/letter-table-styles';
import { Breadcrumb } from '../../components/layout';
import { fetchTaskLetters } from '../letter/letterSlice';
import { LetterEditModal as EditModal } from './modals';

const LetterIndex = (props) => {
  const letters = useSelector(state => state.letter.taskLetters);

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const dispatch = useDispatch();

  const editHandler = (row) => {
    setSelectedRow(row);
    setIsOpenEditModal(true);
  }

  const editModalOnCloseHandler = (success) => {
    setIsOpenEditModal(false);
  }

  const tableColumns = useMemo(() => {
    return generateTableColumns();
  }, [])

  useEffect(() => {
    dispatch(fetchTaskLetters());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Breadcrumb parent={props.t('Task')} headerTitle={props.t('Letter Disposition Management')} title={props.t('Letter Disposition')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTable
                  data={letters}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  selectableRows={false}
                  selectableRowsHighlight
                  noHeader
                  pagination
                  paginationServer
                  onRowClicked={editHandler}
                  highlightOnHover={true}
                  pointerOnHover={true}
                  customStyles={tableStyles}
                  noDataComponent={props.t('There are no records to display')}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <EditModal isOpen={isOpenEditModal} letter={selectedRow} onClose={editModalOnCloseHandler} />
      </Container>
    </React.Fragment>
  )
}

export default translate(LetterIndex);
