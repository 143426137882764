import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Input, Form, FormGroup, Label } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';

import { updateLetter } from '../../letter/letterSlice';
import { showConfirmationAlert, showErrorAlert, showSuccessAlert} from '../../../app/alertResponse.handler';
import { WorkUnitInput, PositionInput, UserTypeaheadInput } from '../../../components/ui-kits';

const LetterForwardModal = ({ isOpen, id, onClose, t })  => {
  const [responseText, setResponseText] = useState('');
  const [selectedReceiver, setSelectedReceiver] = useState('workunit');
  const dispatch = useDispatch();
  const { register, handleSubmit, control, errors, getValues } = useForm();

  const onSubmitHandler = (data, e) => {
    const { dispoWorkUnitId, dispoPositionId, dispoUser, responseText } = data;
    showConfirmationAlert('Are you sure to forward this letter?')
    .then(({isConfirmed}) => {
      if (isConfirmed) {
        const dispoUserId = Array.isArray(dispoUser) && dispoUser.length > 0 ? dispoUser[0].id : 0;

        dispatch(updateLetter({ id, dispoWorkUnitId, dispoPositionId, dispoUserId, responseText, action: 'forward' })).then((payload) => {
          const { error } = payload;
          if (error) {
            showErrorAlert(error, { defaultMessage: 'Update data is failed' });
          } else {
            showSuccessAlert('This letter has been forwarded successfully')
              .then(() => {
                onClose(true);
              });
          }
        })
        .catch((error) => {
          // TODO: need to show error here
          console.log(error);
        });
      }
    });
  }

  const displayReceiverSelection = () => {
    if (selectedReceiver === 'workunit') {
      return (
        <React.Fragment>
          <WorkUnitInput
            className="form-control btn-square"
            name="dispoWorkUnitId"
            innerRef={
              register({
                required: t('This field must be filled')
              })
            }
          />
          <span color="danger">{errors.dispoWorkUnitId && errors.dispoWorkUnitId.message}</span>
        </React.Fragment>
      )
    }

    if (selectedReceiver === 'position') {
      return (
        <React.Fragment>
          <PositionInput
            className="form-control btn-square"
            name="dispoPositionId"
            innerRef={
              register({
                required: t('This field must be filled')
              })
            }
          />
          <span color="danger">{errors.dispoPositionId && errors.dispoPositionId.message}</span>
        </React.Fragment>
      )
    }

    if (selectedReceiver === 'user') {
      return (
        <React.Fragment>
          <Controller
            control={control}
            render={({ onChange, onBlur, value, name, ref }) => (
              <UserTypeaheadInput
                onBlur={onBlur}
                onChange={e => onChange(e)}
                name={name}
                />
            )}
            name="dispoUser"
            defaultValue=""
            rules={{
              required: t('This field must be filled'),
              validate: (value) => {
                const currentValue = getValues('dispoUser');
                if (Array.isArray(currentValue) && currentValue.length === 0) {
                  return t('This field must be filled');
                }

                return true;
              },
            }}
          />
          <span color="danger">{errors.dispoUser && errors.dispoUser.message}</span>
        </React.Fragment>
      );
    }
  }

  const receiverOnChangedHandler = (value) => {
    setSelectedReceiver(value);
  }

  return (
    <Modal isOpen={isOpen} centered>
      <ModalBody className="p-4">
        <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
          <FormGroup className="form-row mb-0">
            <Label htmlFor="code">{t('Forward To')} <span className="text-danger">(*)</span></Label>
          </FormGroup>
          <FormGroup className="form-row">
            <div className="m-checkbox-inline custom-radio-ml d-block">
              <div className="radio radio-primary">
                <Input id="receiverRadio1" type="radio" name="receiver" value="workunit" checked={selectedReceiver === 'workunit'} onChange={(e) => receiverOnChangedHandler(e.target.value)} />
                <Label className="mb-0" for="receiverRadio1"><span className="digits"> {t('Work Unit')}</span></Label>
              </div>
              <div className="radio radio-primary">
                <Input id="receiverRadio2" type="radio" name="receiver" value="position" checked={selectedReceiver === 'position'} onChange={(e) => receiverOnChangedHandler(e.target.value)} />
                <Label className="mb-0" for="receiverRadio2"><span className="digits"> {t('Position')}</span></Label>
              </div>
              <div className="radio radio-primary">
                <Input id="receiverRadio3" type="radio" name="receiver" value="user" checked={selectedReceiver === 'user'} onChange={(e) => receiverOnChangedHandler(e.target.value)} />
                <Label className="mb-0" for="receiverRadio3"><span className="digits"> {t('User')}</span></Label>
              </div>
            </div>
          </FormGroup>

          <FormGroup className="form-row">
            <div className="col-12 pl-0 pr-0">
            {displayReceiverSelection()}
            </div>
          </FormGroup>

          <FormGroup className="form-row">
            <Label htmlFor="responseText">{t('Instruction')} <span className="text-danger">(*)</span></Label><br />
            <Input
              type="textarea"
              rows="3"
              placeholder={t('Input instruction for receiver')}
              value={responseText}
              onChange={e => setResponseText(e.target.value)}
              name="responseText"
              innerRef={
                register({
                  required: t('This field must be filled')
                })
              }
              />
              <span color="danger">{errors.responseText && errors.responseText.message}</span>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => onClose(false)}>{t('buttons_Cancel')}</Button>
        <Button color="success" onClick={handleSubmit(onSubmitHandler)}>{t('buttons_Forward')}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default translate(LetterForwardModal);
