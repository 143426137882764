import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import ServiceTypeApplicationService from './serviceTypeApplication.service';
import { thunkHandler } from '../../app/thunk.handler';

export const fetchServiceTypeApplications = createAsyncThunk('serviceTypeApplications/fetchServiceTypeApplications', async (payload, thunkAPI) => {
  return thunkHandler(ServiceTypeApplicationService.fetchServiceTypeApplications(payload), thunkAPI);
});

export const destroyServiceTypeApplication = createAsyncThunk('serviceTypeApplications/destroy', async (id, thunkAPI) => {
  return thunkHandler(ServiceTypeApplicationService.destroyServiceTypeApplication(id), thunkAPI);
});

export const addServiceTypeApplication = createAsyncThunk('serviceTypeApplications/addServiceTypeApplication', async (payload, thunkAPI) => {
  return thunkHandler(ServiceTypeApplicationService.addServiceTypeApplication(payload), thunkAPI);
});

export const updateServiceTypeApplication = createAsyncThunk('serviceTypeApplications/updateServiceTypeApplication', async (payload, thunkAPI) => {
  const { id, ...data } = payload;

  return thunkHandler(ServiceTypeApplicationService.updateServiceTypeApplication(id, data), thunkAPI);
});

const updateServiceTypeApplicationsState = (serviceTypeApplications, serviceTypeApplication) => {
  const updateServiceTypeApplications = serviceTypeApplications.reduce((result, item) => {
    if (serviceTypeApplication.id && item.id === serviceTypeApplication.id) {
      result.push(serviceTypeApplication);
    } else {
      result.push(item);
    }

    return result;
  }, []);

  return updateServiceTypeApplications;
}

const serviceTypeApplicationSlice = createSlice({
  name: 'serviceTypeApplication',
  initialState: {
    serviceTypeApplications: [],
    totalServiceTypeApplication: 0,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchServiceTypeApplications.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchServiceTypeApplications.fulfilled]: (state, action) => {
      state.loading = false;
      state.serviceTypeApplications = action.payload.result;
      state.totalServiceTypeApplication = action.payload.total;
    },
    [fetchServiceTypeApplications.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [destroyServiceTypeApplication.pending]: (state, action) => {
      state.loading = true;
    },
    [destroyServiceTypeApplication.fulfilled]: (state, action) => {
      state.loading = false;
      state.serviceTypeApplications = state.serviceTypeApplications.filter((item) => item.id !== action.payload.id);
    },
    [destroyServiceTypeApplication.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [addServiceTypeApplication.pending]: (state, action) => {
      state.loading = true;
    },
    [addServiceTypeApplication.fulfilled]: (state, action) => {
      state.loading = false;
      state.serviceTypeApplications.unshift(action.payload);
    },
    [addServiceTypeApplication.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [updateServiceTypeApplication.pending]: (state, action) => {
      state.loading = true;
    },
    [updateServiceTypeApplication.fulfilled]: (state, action) => {
      state.loading = false;
      state.serviceTypeApplications = updateServiceTypeApplicationsState(state.serviceTypeApplications, action.payload);
    },
    [updateServiceTypeApplication.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  }
})

export default serviceTypeApplicationSlice.reducer;
