import React from 'react';
import DataTable from 'react-data-table-component';
import { t } from 'react-switch-lang';

const DataTableStandard = ({ columns, data, ...otherProps }) => {
  return (
    <DataTable
      data={data}
      columns={columns}
      striped={true}
      center={true}
      selectableRows={false}
      selectableRowsHighlight
      noHeader
      pagination={false}
      noDataComponent={t('There are no records to display')}
      {...otherProps}
    />
  );
}

export default DataTableStandard;
