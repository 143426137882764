import React from 'react';
import { Input } from 'reactstrap';
import { t } from 'react-switch-lang';

const MonthInput = ({ onChange, ...props }) => {
  const items = React.useMemo(() => {
    return [{
      id: 1,
      name: t('January')
    }, {
      id: 2,
      name: t('February')
    }, {
      id: 3,
      name: t('March')
    }, {
      id: 4,
      name: t('April')
    }, {
      id: 5,
      name: t('May')
    }, {
      id: 6,
      name: t('June')
    }, {
      id: 7,
      name: t('July')
    }, {
      id: 8,
      name: t('August')
    }, {
      id: 9,
      name: t('September')
    }, {
      id: 10,
      name: t('October')
    }, {
      id: 11,
      name: t('November')
    }, {
      id: 12,
      name: t('December')
    }];
  }, []);

  const onChangeHandler = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  }

  return (
    <Input
      type="select"
      onChange={onChangeHandler}
      { ...props }
    >
      {items.map(item =>
        <option key={item.id} value={item.id}>{item.name}</option>
      )};
    </Input>
  )
}

export default MonthInput;
