import React, { useState } from 'react';
import { Button, Card, CardBody, Row, Col, Input } from 'reactstrap';
import { t } from 'react-switch-lang';

import LetterStatusInput from './LetterStatusInput';

const LetterSearchFilter = ({ onSearch }) => {
  const [keyword, setKeyword] = useState('');
  const [status, setStatus] = useState('');

  const onSearchHandler = () => {
    if (onSearch) {
      onSearch({ keyword, status });
    }
  }

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      onSearchHandler();
    }
  }

  return (
    <Card className="rounded-0">
      <CardBody className="py-4">
        <Row>
          <Col>
            {t('Keyword')}
            <Input
              className="btn-square"
              name="keyword"
              type="text"
              placeholder={t('Input keyword here')}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </Col>
          <Col>
            {t('Status')}
            <LetterStatusInput
              className="btn-square"
              defaultLabel={t('All')}
              onChange={(e) => setStatus(e.target.value)}
            />
          </Col>
          <Col md="2">
            <Button color="info" style={{width: '100%', marginTop: 21}} onClick={onSearchHandler}>{t('buttons_Search')}</Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default LetterSearchFilter;
