import { t } from 'react-switch-lang';

const generateTableColumns = (editHandler, destroyHandler, viewMeetingPointHandler) => [
  {
    name: 'Id',
    selector: 'id',
    sortable: false,
    center: false,
    width: '65px',
  },
  {
    name: t('Name'),
    selector: 'name',
    sortable: false,
    center: false,
    grow: 1,
  },
  {
    name: t('Religion'),
    selector: 'religion',
    sortable: false,
    center: false,
    width: '95px',
  },
  {
    name: t('Handler Staff'),
    cell: row => row.User ? row.User.fullName : '',
    sortable: false,
    center: false,
    grow: 1,
  },

  // {
  //   name: t('Actions'),
  //   sortable: false,
  //   cell: row => (
  //     <React.Fragment>
  //       <Button onClick={() => destroyHandler(row)} className="btn-square mr-1" color="danger" size="xs"><i className={`fa fa-trash`}></i> {t('buttons_Delete')}</Button>
  //     </React.Fragment>
  //   ),
  //   wrap: true,
  //   width: '120px'
  // }
];

export default generateTableColumns;
