import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import jwt_decode from "jwt-decode";

import App from './App';
import appRoutes from '../app/app-routes';
import { getUserInfo } from '../features/auth/authSlice';
import { fetchCouriers } from '../features/courier/courierSlice';
import { fetchPositions } from '../features/position/positionSlice';
import { fetchWorkUnits } from '../features/workUnit/workUnitSlice';
import { canShowMenuByAccessRight } from '../util/AccessRightHelper';
import NotFound from '../components/pages/NotFound';

const getUsernameFromJwt = (jwtToken) => {
  try {
    const decoded = jwt_decode(jwtToken);
    return decoded.sub;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

const mapStateToProps = (state) => {
  return { authenticated: state.auth.jwtToken !== undefined && state.auth.jwtToken !== null };
};

const AppRoutes = ({ authenticated }) => {
  const jwtToken = useSelector(state => state.auth.jwtToken);
  const loginUser = useSelector(state => state.auth.userInfo);
  const couriers = useSelector(state => state.courier.couriers);
  const positions = useSelector(state => state.position.positions);
  const workUnits = useSelector(state => state.workUnit.workUnits);
  const userAccessRights = useSelector((state) => state.auth.userInfo?.Position?.accessRights || {});

  const dispatch = useDispatch();

  // load login user info and all other list
  useEffect(() => {
    if (!loginUser) {
      const username = getUsernameFromJwt(jwtToken);
      dispatch(getUserInfo(username));
    }

    if (!couriers) {
      dispatch(fetchCouriers());
    }

    if (!positions) {
      dispatch(fetchPositions());
    }

    if (!workUnits) {
      dispatch(fetchWorkUnits());
    }
  }, [dispatch, loginUser, jwtToken, couriers, positions, workUnits]);

  return (
    <React.Fragment>
      { authenticated ?
        <App>
          <Switch>
            {
              appRoutes.map(({ path, Component, accessRights }) => {
                if (canShowMenuByAccessRight(accessRights, userAccessRights)) {
                  return (
                    <Route key={path} exact path={`${process.env.PUBLIC_URL}${path}`} component={Component} />
                  )
                }
                return null;
              })
            }
            <Route exact={true} path={`${process.env.PUBLIC_URL}/`}>
              <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </App>
        :
        <Redirect to={`${process.env.PUBLIC_URL}/login`} />
      }
    </React.Fragment>
  );
}

export default connect(mapStateToProps, null)(AppRoutes);
