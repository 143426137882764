import React, { useState, useMemo } from 'react';
import { Container, Button, Row, Col, Card, CardBody, FormGroup, Label, Input } from 'reactstrap';
import { t } from 'react-switch-lang';
import DatePicker from 'react-datepicker';
import { toast, ToastContainer } from 'react-toastify';
import dayjs from 'dayjs';

import generateTableColumns from './settings/reportIndex-table-column';
import { Breadcrumb } from '../../components/layout';
import { DataTableWithPagination, ReportTypeSelectInput, StatusFilterInput, ServiceApplicationTypeFilterInput } from '../../components/ui-kits';
import ServiceApplicationService from './serviceApplication.service';
import { DetailModal } from './modals';
import { saveXlsFile } from '../../util/FileSaverHelper';
import * as CONSTANTS from '../../app/constants';

const ReportIndex = (props) => {
  const [timeScale, setTimeScale] = useState('daily');
  const [startDate, setStartDate] = useState(dayjs().startOf('month').toDate());
  const [endDate, setEndDate] = useState(new Date());
  const [reportRows, setReportRows] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [page, setPage] = useState(1);
  const [isRunningReport, setIsRunningReport] = useState(false);
  const [isShowTotalOnly, setIsShowTotalOnly] = useState(true); // for memo
  const [showTotalOnlyInput, setShowTotalOnlyInput] = useState(true);
  const [isOpenDetailModal, setIsOpenDetailModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const [statusFilter, setStatusFilter] = useState('2');
  const [statusFilterForColumn, setStatusFilterForColumn] = useState('2');
  const [serviceTypeFilter, setServiceTypeFilter] = useState('all');

  const tableColumns = useMemo(() => {
    const keyName = timeScale === 'monthly' ? 'Bulan' : 'Tanggal';
    return generateTableColumns(keyName, isShowTotalOnly, statusFilterForColumn);
  }, [timeScale, isShowTotalOnly, statusFilterForColumn]);

  const onRowClickedHandler = (row) => {
    setSelectedRow(row);
    setIsOpenDetailModal(true);
  }

  const detailModalOnCloseHandler = (success) => {
    setIsOpenDetailModal(false);
  }

  const onChangeReportType = (value) => {
    setTimeScale(value);
  }

  const onStatusChange = (value) => {
    setStatusFilter(value);
  }

  const onServiceTypeChange = (value) => {
    setServiceTypeFilter(value);
  }

  const onStartDateChange = (value) => {
    setStartDate(value);
  }

  const onEndDateChange = (value) => {
    setEndDate(value);
  }

  const onExportButtonClick = async () => {
    try {
      setIsShowTotalOnly(showTotalOnlyInput);
      setStatusFilterForColumn(statusFilter);

      const result = await ServiceApplicationService.reportIndex(timeScale, {
        start: dayjs(startDate).format('DD-MM-YYYY'),
        end: dayjs(endDate).format('DD-MM-YYYY'),
        status: statusFilter,
        servicetype: serviceTypeFilter,
        showtotalonly: showTotalOnlyInput ? 1 : 0,
        format: 'xls',
        page,
        pagesize: CONSTANTS.NUMBER_PER_PAGE
      });

      saveXlsFile(result, 'permohonan-layanan.xlsx');
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // dispatch(setError('Username or password is wrong!'));
      } else {
        toast.error('An error occured on server. Please try login or contact admin')
      }
    }
  }

  const onPageChange = async (page) => {
    try {
      setIsShowTotalOnly(showTotalOnlyInput);
      setStatusFilterForColumn(statusFilter);
      setPage(page);

      const { result, total } = await ServiceApplicationService.reportIndex(timeScale, {
        start: dayjs(startDate).format('DD-MM-YYYY'),
        end: dayjs(endDate).format('DD-MM-YYYY'),
        status: statusFilter,
        servicetype: serviceTypeFilter,
        showtotalonly: showTotalOnlyInput ? 1 : 0,
        format: 'json',
        page,
        pagesize: CONSTANTS.NUMBER_PER_PAGE
      });

      setIsRunningReport(true);
      setReportRows(result);
      setTotalRow(total);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // dispatch(setError('Username or password is wrong!'));
      } else {
        toast.error('An error occured on server. Please try login or contact admin')
      }
    }
  }

  return (
    <React.Fragment>
      <Breadcrumb parent={t('Service Application')} headerTitle={t('Service Application')} title={t('Report')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <FormGroup className="form-row mb-0">
                  <Label className="col-sm-3" htmlFor="timeScale">{t('Report Type')}</Label><br />
                  <Col sm="9">
                    <ReportTypeSelectInput
                      name="timeScale"
                      selectedValue={timeScale}
                      onChange={onChangeReportType}
                    />
                  </Col>
                </FormGroup>

                <FormGroup className="form-row mb-1">
                  <Label className="col-sm-3 col-form-label" htmlFor="startDate">{t('Start Date')}</Label>
                  <Col sm="3">
                    <DatePicker
                      name="startDate"
                      selected={startDate}
                      onChange={e => onStartDateChange(e)}
                      className="form-control digits"
                      dateFormat="dd/MM/yyyy"
                      />
                  </Col>
                </FormGroup>

                <FormGroup className="form-row mb-2">
                  <Label className="col-sm-3 col-form-label" htmlFor="endDate">{t('End Date')}</Label><br />
                  <Col sm="3">
                    <DatePicker
                      name="endDate"
                      selected={endDate}
                      onChange={e => onEndDateChange(e)}
                      className="form-control digits"
                      dateFormat="dd/MM/yyyy"
                      />
                  </Col>
                </FormGroup>
                <FormGroup className="form-row mb-2">
                  <Label className="col-sm-3 col-form-label" htmlFor="status">{t('Status')}</Label><br />
                  <Col sm="3">
                    <StatusFilterInput
                      name="status"
                      onChange={onStatusChange}
                      className="form-control digits"
                      />
                  </Col>
                </FormGroup>
                <FormGroup className="form-row mb-2">
                  <Label className="col-sm-3 col-form-label" htmlFor="status">{t('Service Type')}</Label><br />
                  <Col sm="3">
                    <ServiceApplicationTypeFilterInput
                      name="service_type"
                      onChange={onServiceTypeChange}
                      className="form-control digits"
                      />
                  </Col>
                </FormGroup>
                <FormGroup className="form-row mb-0">
                  <Col sm={{ size: 9, offset: 3}}>
                    <Label className="d-block" for="chk-manage_work_unit">
                      <Input className="checkbox_animated" id="chk-manage_work_unit" type="checkbox" checked={showTotalOnlyInput} onChange={(e) => setShowTotalOnlyInput(!showTotalOnlyInput)} /> {t('Show Total Only')}
                    </Label>
                    <Button className="mr-1" color="success" onClick={() => onPageChange(1)}>{t('buttons_Run_Report')}</Button>
                    <Button color="dark" onClick={onExportButtonClick}>{t('buttons_Xls_Export')}</Button>
                  </Col>
                </FormGroup>
              </CardBody>
            </Card>

            { isRunningReport &&
            <Card>
              <CardBody>
                { isShowTotalOnly ?
                  <DataTableWithPagination
                    data={reportRows}
                    columns={tableColumns}
                    totalRow={totalRow}
                    onPageChange={page => onPageChange(page)}
                  />
                  :
                  <DataTableWithPagination
                    data={reportRows}
                    columns={tableColumns}
                    totalRow={totalRow}
                    onPageChange={page => onPageChange(page)}
                    onRowClicked={onRowClickedHandler}
                    highlightOnHover={true}
                    pointerOnHover={true}
                  />
                }
              </CardBody>
            </Card>
            }
          </Col>
        </Row>
      </Container>

      <ToastContainer />
      <DetailModal isOpen={isOpenDetailModal} serviceApplication={selectedRow} onClose={detailModalOnCloseHandler} />
    </React.Fragment>
  )
}

export default ReportIndex;
