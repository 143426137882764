import React from 'react';
import { useSelector } from 'react-redux';

const BadgeTask = ({ varName, ...props }) => {
  const value = useSelector((state) => state.task.statInfo && state.task.statInfo[varName] > 0 ? state.task.statInfo[varName] : 0);

  return <label className={`badge ${value > 0 ? 'badge-danger' : 'badge-dark'}`} {...props}>{value}</label>;
}

export default BadgeTask;
