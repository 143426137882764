import axios from 'axios';

import { apiUrl } from '../../app/config';

/**
 * fetch positions data from api
 */
class PositionService {
  /**
   * fetch positions list
   */
  static async fetchPositions() {
    const url = `${apiUrl}/positions`;

    const response = await axios.get(url);
    return response.data;
  }

  /**
   * fetch position info
   * @param string|number id positions.id or positions.code
   */
  static async fetchPosition(id) {
    const url = `${apiUrl}/positions/${id}`;

    const response = await axios.get(url);
    return response.data;
  }

  static async addPosition(data) {
    const url = `${apiUrl}/positions`;

    const response = await axios.post(url, data);
    return response.data;
  }

  static async updatePosition(id, data) {
    const url = `${apiUrl}/positions/${id}`;

    const response = await axios.put(url, data);
    return response.data;
  }

  static async activatePosition(id) {
    const url = `${apiUrl}/positions/${id}`;

    const response = await axios.put(url, { active: true });
    return response.data;
  }

  static async deactivatePosition(id) {
    const url = `${apiUrl}/positions/${id}`;

    const response = await axios.delete(url);
    return response.data;
  }
}

export default PositionService;
