import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { t } from 'react-switch-lang';

import { apiUrl } from '../../../app/config';

const ImageViewerModal = ({ isOpen, module, moduleId, filename, onClose })  => {
  const url = module ? `${apiUrl}/images/${module}/${moduleId}/${filename}` : `${apiUrl}/images/undefined/undefined/${filename}`;

  if (!filename) {
    return '';
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="lg" centered style={{maxWidth: '1100px', width: '80%'}}>
      <ModalBody className="p-4">
        <img src={url} alt="attachment" width="1000" />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>{t('buttons_Close')}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default ImageViewerModal;
