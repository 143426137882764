import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { deleteJwt } from './authSlice';

const Logout = ({ deleteJwt }) => {
  const [hasBeenLogout, setHasBeenLogout] = useState(false);

  useEffect(() => {
    deleteJwt();

    setHasBeenLogout(true);
  }, [deleteJwt]);

  const showRedirect = () => {
    if (hasBeenLogout) {
      return <Redirect to={`${process.env.PUBLIC_URL}/login`} />
    }
  }

  return (
    <React.Fragment>
      { showRedirect() }
    </React.Fragment>
  )
}

export default connect(null, { deleteJwt })(Logout);
