import path from 'path';

import { saveAs } from 'file-saver';

/**
 * content type mapping based on file extension
 */
const extensionMapping = {
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.ms-excel',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  pdf: 'application/pdf',
  png: 'image/png',
}

/**
 * save blob data as file
 * this will triggering download action on browser
 * @param {*} data blob data (response data)
 * @param {*} filename save filename
 */
export const saveFileAs = (data, filename) => {
  const extension = path.extname(filename).toLowerCase();

  const contentType = extensionMapping[extension] ? extensionMapping[extension] : 'application/octet-stream';

  const blob = new Blob([data], { type: contentType });

  // For IE use this function to save blob file (See AFT-462)
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    // for other browsers
    const file = new File([blob], filename, { type: contentType });

    saveAs(file); // open dialog save as on browser
  }
}

/**
 * save blob data as excel file
 * @param {*} data blob data (response data)
 * @param {*} filename save filename
 */
export const saveXlsFile = (data, filename = 'export.xlsx') => {
  saveFileAs(data, filename);
}
