import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { t } from 'react-switch-lang';

import DetailPublicComplaint from '../DetailPublicComplaint';

const DetailModal = ({ isOpen, publicComplaint, threads, onClose })  => {
  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={onClose} size="lg" centered>
        <ModalBody className="p-4">
          <DetailPublicComplaint publicComplaint={publicComplaint} threads={threads} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClose}>{t('buttons_Close')}</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default DetailModal;
