import React from "react";
import { Button, Alert } from "reactstrap";

const AlertWithIcon = (props) => {
  const { id, color, text, icon, onClose, isOpen } = props;

  const showIcon = () => {
    if (icon) {
      return icon;
    }
  }
  return (
    <Alert
      className="alert-dismissible"
      color={color}
      isOpen={isOpen}
      target={`Alert-${id}`}
    >{ showIcon() }<p>{ text }</p>
      <Button className="close" color="default" id={`Alert-${id}`} onClick={onClose}><span>×</span></Button>
    </Alert>
  );
};

export default AlertWithIcon;
