import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardFooter, Button, Form, FormGroup, Input, Label, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { t } from 'react-switch-lang';

import { Breadcrumb } from '../../components/layout';
import SettingService from './setting.service';
import { showErrorAlert, showSuccessAlert, showConfirmationAlert } from '../../app/alertResponse.handler';
import UserInput from '../user/UserInput';

const SettingIndex = (props) => {
  const [selectedPlhUsers, setSelectedPlhUsers] = useState([]);
  const [selectedPltUsers, setSelectedPltUsers] = useState([]);
  const [selectedPublicComplaintUsers, setSelectedPublicComplaintUsers] = useState([]);

  const [numberOfLeavePerYear, setNumberOfLeavePerYear] = useState(0);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadSetting = async () => {
      try {
        setLoading(true);
        const result = await SettingService.fetchSettings();

        if (result.plh) {
          setSelectedPlhUsers([result.plh])
        }

        if (result.plt) {
          setSelectedPltUsers([result.plt])
        }

        if (result.public_complaint_handler) {
          setSelectedPublicComplaintUsers([result.public_complaint_handler])
        }

        setNumberOfLeavePerYear(result.number_of_leave_per_year);
        setLoading(false);
      } catch (error) {

      }
    }

    loadSetting();
  }, []);

  const onChangePlhUserInput = (user) => {
    if (user && user.id) {
      setSelectedPlhUsers([user]);
    }
  }

  const onChangePltUserInput = (user) => {
    if (user && user.id) {
      setSelectedPltUsers([user]);
    }
  }

  const onChangePublicComplaintUserInput = (user) => {
    if (user && user.id) {
      setSelectedPublicComplaintUsers([user]);
    }
  }

  const onSubmitHandler = (data, e) => {
    showConfirmationAlert('Are you sure to update setting?')
    .then(async ({isConfirmed}) => {
      if (isConfirmed) {
        try {
          const settingsForAPI = {};
          if (Array.isArray(selectedPlhUsers) && selectedPlhUsers.length > 0) {
            settingsForAPI.plh = selectedPlhUsers[0].id;
          }
          if (Array.isArray(selectedPltUsers) && selectedPltUsers.length > 0) {
            settingsForAPI.plt = selectedPltUsers[0].id;
          }
          if (Array.isArray(selectedPublicComplaintUsers) && selectedPublicComplaintUsers.length > 0) {
            settingsForAPI.public_complaint_handler = selectedPublicComplaintUsers[0].id;
          }

          settingsForAPI.number_of_leave_per_year = numberOfLeavePerYear;

          await SettingService.updateSetting(settingsForAPI);

          showSuccessAlert('Setting has been updated')
        } catch (error) {
          showErrorAlert(error, { defaultMessage: 'Add data is failed' });
        }
      }
    });
  };

  return (
    <React.Fragment>
      <Breadcrumb parent={t('Setting')} headerTitle={t('Setting')} title={t('Index')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                {loading ? <h4>Loading...</h4> :
                <Form className="needs-validation" noValidate="">
                  <h5 className="border-bottom pb-2 mt-4 mb-3">Kepala Dinas</h5>
                  <Row>
                    <Col sm="12">
                      <FormGroup className="form-row">
                        <Label className="col-sm-3 col-form-label" htmlFor="subject">Pelaksana Harian (PLH)</Label>
                        <Col sm="9">
                          <UserInput users={selectedPlhUsers} onChange={onChangePlhUserInput} canEdit={true} />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <FormGroup className="form-row">
                        <Label className="col-sm-3 col-form-label" htmlFor="subject">Pelaksana Tugas (PLT)</Label>
                        <Col sm="9">
                          <UserInput users={selectedPltUsers} onChange={onChangePltUserInput} canEdit={true} />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>

                  <h5 className="border-bottom pb-2 mt-4 mb-3">{t('Public Complaint')} (SiDu)</h5>
                  <Row>
                    <Col sm="12">
                      <FormGroup className="form-row">
                        <Label className="col-sm-3 col-form-label" htmlFor="subject">Pegawai yang menangani</Label>
                        <Col sm="9">
                          <UserInput users={selectedPublicComplaintUsers} onChange={onChangePublicComplaintUserInput} canEdit={true} />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>

                  <h5 className="border-bottom pb-2 mt-4 mb-3">{t('Attendance')}</h5>
                  <Row>
                    <Col sm="12">
                      <FormGroup className="form-row">
                        <Label className="col-sm-3 col-form-label" htmlFor="subject">{t('Number of leave per year')}</Label>
                        <Col sm="9">
                          <InputGroup style={{ width: '150px'}}>
                            <Input type="number" value={numberOfLeavePerYear} onChange={(e) => setNumberOfLeavePerYear(e.target.value)} />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>{t('Days')}</InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                }
              </CardBody>
              <CardFooter>
                <Button color="primary" onClick={onSubmitHandler}>{t('buttons_Save')}</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default SettingIndex;
