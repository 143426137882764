import { t } from 'react-switch-lang';

import { StatusInfo } from '../../../components/ui-kits';
import { displayDateTime } from '../../../util/FormaterHelper';

const generateTableColumns = (keyName, showTotalOnly = true, status = 2) => {
  if (showTotalOnly) {
    if (status === '0') {
      return [
        {
          name: keyName,
          selector: 'keyName',
          sortable: false,
          center: false,
          grow: 1,
        },
        {
          name: 'Total Masih Dalam Proses',
          selector: 'totalStatus0',
          sortable: false,
          center: true,
          width: '120px',
        }
      ];
    }

    if (status === '1') {
      return [
        {
          name: keyName,
          selector: 'keyName',
          sortable: false,
          center: false,
          grow: 1,
        },
        {
          name: 'Total Sudah di Proses',
          selector: 'totalStatus1',
          sortable: false,
          center: true,
          width: '120px',
        }
      ];
    }

    return [
      {
        name: keyName,
        selector: 'keyName',
        sortable: false,
        center: false,
        grow: 1,
      },
      {
        name: 'Total Masih Dalam Proses',
        selector: 'totalStatus0',
        sortable: false,
        center: true,
        width: '120px',
      },
      {
        name: 'Total Sudah di Proses',
        selector: 'totalStatus1',
        sortable: false,
        center: true,
        width: '120px',
      },
      {
        name: 'Total',
        selector: (row) => `${row.totalStatus0 + row.totalStatus1}`,
        sortable: false,
        center: true,
        width: '120px',
      }
    ];
  }

  return [
    {
      name: 'Id',
      selector: 'id',
      sortable: false,
      center: false,
      width: '65px',
    },
    {
      name: t('Registration Number'),
      selector: 'registrationNumber',
      sortable: false,
      center: false,
      grow: 1,
    },
    {
      name: t('Full Name'),
      selector: 'fullName',
      sortable: false,
      center: false,
      grow: 1,
    },
    {
      name: t('Phone'),
      selector: 'phone',
      sortable: false,
      center: false,
      grow: 1,
    },
    {
      name: t('NIK'),
      selector: 'nik',
      sortable: false,
      center: false,
      grow: 1,
    },
    {
      name: 'Status',
      selector: 'status',
      cell: (row) => <StatusInfo status={row.status} />,
      sortable: false,
      center: false,
      width: '200px',
    },
    {
      name: t('Date'),
      selector: (row) => displayDateTime(row.createdAt),
      sortable: false,
      center: false,
      grow: 1,
    },
  ];
};

export default generateTableColumns;
