import axios from 'axios';

const init = () => {
  axios.interceptors.request.use(
    config => {
      // TODO: donot store in local storage
      const token = localStorage.getItem('jwtToken');
      config.headers.authorization = `Bearer ${token}`;
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
}

export default init;
