import React, { useEffect, useState, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { translate } from 'react-switch-lang';
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'sweetalert2';

import generateTableColumns from './settings/table-column';
import { Breadcrumb } from '../../components/layout';
import { fetchLetterNumbers, destroyLetterNumber } from './letterNumberSlice';
import { EditModal } from './modals';

const LetterNumberIndex = (props) => {
  const letterNumbers = useSelector(state => state.letterNumber.letterNumbers);

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const dispatch = useDispatch();

  const editHandler = (row) => {
    setSelectedRow(row);
    setIsOpenEditModal(true);
  }

  const editModalOnCloseHandler = () => {
    setIsOpenEditModal(false);
  }

  const onRowClicked = (row) => {
    editHandler(row);
  }

  const tableColumns = useMemo(() => {
    const destroyHandler = (row) => {
      SweetAlert.fire({
        title: props.t('Are you sure to delete this letter number?'),
        cancelButtonText: props.t('buttons_Cancel'),
        confirmButtonText: props.t('buttons_Delete'),
        reverseButtons: true,
        showCancelButton: true,
      }).then(({isConfirmed}) => {
        if (isConfirmed) {
          dispatch(destroyLetterNumber(row.id)).then(({ error }) => {
            if (error) {
              // get proper error message from server
              const errorMessage = error && error.message ? props.t(error.message) : props.t('Delete data is failed');

              // trigger error alert
              SweetAlert.fire({
                title: props.t('Error Occured'), text: errorMessage, icon: 'error'
              });
            } else {
              SweetAlert.fire({
                title: props.t('Success'), text: props.t('Data has been deleted successfully'), icon: 'success'
              });
            }
          });
        }
      });
    }

    return generateTableColumns(editHandler, destroyHandler);
  }, [dispatch, props])

  useEffect(() => {
    dispatch(fetchLetterNumbers());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Breadcrumb parent={props.t('Letter Number Registration')} headerTitle={props.t('Letter Number Management')} title={props.t('Index')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTable
                  data={letterNumbers}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  selectableRows={false}
                  selectableRowsHighlight
                  noHeader
                  pagination
                  paginationServer
                  onRowClicked={onRowClicked}
                  highlightOnHover={true}
                  pointerOnHover={true}
                  noDataComponent={props.t('There are no records to display')}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <EditModal isOpen={isOpenEditModal} letterNumber={selectedRow} onClose={editModalOnCloseHandler} />
      </Container>
    </React.Fragment>
  )
}

export default translate(LetterNumberIndex);
