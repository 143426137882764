import React, { useState } from 'react';
import { Button, Card, CardBody, CardFooter, Container, Col, Form, FormGroup, Label, Row, Input } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';

import { addStaffOffday } from './staffOffdaySlice';
import { Breadcrumb } from '../../components/layout';
import { showErrorAlert, showSuccessAlert } from '../../app/alertResponse.handler';
import UserInput from '../user/UserInput';
import { OffdayTypeInput } from '../../components/ui-kits';

const AddStaffOffday = ({ t }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userError, setUserError] = useState(undefined);
  const [selectedOffdayType, setSelectedOffdayType] = useState('Cuti');
  const { register, reset, handleSubmit, control, errors } = useForm();
  const dispatch = useDispatch();

  const onSubmitHandler = (data, e) => {
    if (!Array.isArray(selectedUsers) || selectedUsers.length === 0) {
      setUserError(t('This field must be filled'));
      return false;
    }

    dispatch(addStaffOffday({ userId: selectedUsers[0].id, offdayType: selectedOffdayType, approved: true, ...data })).then((payload) => {
      const { error } = payload;
      if (error) {
        showErrorAlert(error, { defaultMessage: 'Add data is failed' });
      } else {
        showSuccessAlert('Data has been created successfully')
          .then(() => {
            clearForm();
          });
      }
    })
    .catch((error) => {
      // TODO: need to show error here
      console.log(error);
    });
  }

  const onChangeUserInput = (user) => {
    if (user && user.id) {
      setSelectedUsers([user]);
    }
  }

  const onChangeOffdayTypeInput = (value) => {
    setSelectedOffdayType(value);
  }

  /**
   * clear form after submit or just reset form
   */
  const clearForm = () => {
    reset();
  }

  return (
    <React.Fragment>
      <Breadcrumb parent={t('Staff Offday')} headerTitle={t('Add Staff Offday')} title={t('Add Staff Offday')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="subject">{t('User')} <span className="text-danger">(*)</span></Label>
                    <Col sm="9">
                      <UserInput users={selectedUsers} onChange={onChangeUserInput} canEdit={true} />
                      <span className="text-danger">{userError}</span>
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="subject">{t('Date')} <span className="text-danger">(*)</span></Label>
                    <Col sm="9">
                      <Controller
                        control={control}
                        render={({ onChange, onBlur, value, name, ref }) => (
                          <DatePicker
                            selected={value}
                            onChange={e => onChange(e)}
                            name={name}
                            className="form-control digits"
                            dateFormat="dd/MM/yyyy"
                            />
                        )}
                        name="dateAt"
                        defaultValue=""
                        rules={{
                          required: t('This field must be filled'),
                        }}
                      />
                      <span className="text-danger">{errors.dateAt && errors.dateAt.message}</span>
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="title">{t('Offday Type')} <span className="text-danger">(*)</span></Label>
                    <Col sm="9">
                      <OffdayTypeInput selectedValue={selectedOffdayType} onChange={onChangeOffdayTypeInput} />
                    </Col>
                  </FormGroup>

                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="description">{t('Description')}</Label><br />
                    <Col sm="9">
                      <Input
                        type="textarea"
                        rows="3"
                        placeholder={t('Input point description')}
                        name="description"
                        innerRef={register}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Link to={`${process.env.PUBLIC_URL}/staff-offdays`}>
                  <Button color="secondary" className="mr-2">{t('buttons_Back')}</Button>
                </Link>
                <Button color="primary" onClick={handleSubmit(onSubmitHandler)}>{t('buttons_Save')}</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default translate(AddStaffOffday);
