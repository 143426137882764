export const AUTH_JWT_TOKEN = 'jwtToken';

export const STATUS_CREATED = 0;
export const STATUS_DONE = 1;

export const STATUS_INACTIVE = 0;
export const STATUS_ACTIVE = 1;

export const LETTER_CREATED = 0;
export const LETTER_DISPOSITED = 1;
export const LETTER_DONE = 2;

// letter history actionType
export const LETTER_HISTORY_CREATED = 1;
export const LETTER_HISTORY_ADD_ATTACHMENT = 2;
export const LETTER_HISTORY_DESTROY_ATTACHMENT = 3;
export const LETTER_HISTORY_DISPOSITED = 4; // forwarded
export const LETTER_HISTORY_MARK_DONE = 5;
export const LETTER_HISTORY_UPDATE = 6;

// document history actionType
export const DOCUMENT_HISTORY_CREATED = 1;
export const DOCUMENT_HISTORY_ADD_ATTACHMENT = 2;
export const DOCUMENT_HISTORY_DESTROY_ATTACHMENT = 3;
export const DOCUMENT_HISTORY_MARK_DONE = 4;
export const DOCUMENT_HISTORY_UPDATE = 5;

export const STAFF_OFFDAY_CREATED = 0;
export const STAFF_OFFDAY_APPROVED = 1;
export const STAFF_OFFDAY_DECLINED = 2;

// service queue status
export const SERVICE_QUEUE_CREATED = 0;
export const SERVICE_QUEUE_IN_PROGRESS = 1;
export const SERVICE_QUEUE_DONE = 2;

export const NUMBER_PER_PAGE = 15; // total item per page

export const RELIGIONS = [
  'Buddha',
  'Hindu',
  'Islam',
  'Katolik',
  'Konghucu',
  'Kristen',
];

export const TEMPLE_TYPES = {
  Buddha: ['Wihara', 'Cetya', 'Titd'],
  Hindu: ['Sanggah', 'Panti', 'Khayangan Desa', 'Swagina', 'Khayangan Tiga', 'Dhang Khayangan', 'Sad Khayangan'],
  Islam: ['Masjid', 'Musholla', 'Langgar'],
  Katolik: ['Katedral', 'Gereja', 'Kapel'],
  Konghucu: ['Klenteng'],
  Kristen: ['Gereja'],
}

export const DISTRICTS = ['Sukawati', 'Blahbatuh', 'Gianyar', 'Tampaksiring', 'Ubud', 'Tegalalang', 'Payangan'];
