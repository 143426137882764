import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { updateNews } from '../newsSlice';
import { showErrorAlert, showSuccessAlert } from '../../../app/alertResponse.handler';

const EditModal = ({ isOpen, news, onClose, t })  => {
  const [isActive, setIsActive] = useState(true);
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (news) {
      const { isActive } = news;

      setIsActive(isActive === 1);
    }
  }, [news]);

  const onSubmitHandler = (data) => {
    const { id } = news;

    dispatch(updateNews({ id, ...data, isActive })).then(({ error }) => {
      if (error) {
        showErrorAlert(error, { defaultMessage: 'Change data is failed' });
      } else {
        showSuccessAlert('Data has been changed successfully')
          .then(() => {
            onClose()
          });
      }
    })
    .catch((error) => {
      // TODO: need to show error here
      console.log(error);
    });
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="lg" centered style={{maxWidth: '1600px', width: '80%'}}>
      <ModalHeader toggle={onClose}>
        {t('Edit News')}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
            <FormGroup className="form-row">
              <Label htmlFor="title">{t('Title')} <span className="text-danger">(*)</span></Label>
              <Input
                className="form-control btn-square"
                name="title"
                type="text"
                defaultValue={news && news.title}
                innerRef={
                  register({
                    required: t('This field must be filled')
                  })
                }
              />
            </FormGroup>

            <FormGroup className="form-row">
              <Label htmlFor="subject">{t('News Content')} <span className="text-danger">(*)</span></Label>
              <Input
                type="textarea"
                rows="3"
                name="content"
                defaultValue={news && news.content}
                innerRef={
                  register({
                    required: t('This field must be filled')
                  })
                }
              />
              <span color="danger">{errors.content && errors.content.message}</span>
            </FormGroup>

            <Label className="d-block" for="chk-isActive">
              <Input className="checkbox_animated" id="chk-isActive" type="checkbox" checked={isActive} onChange={(e) => setIsActive(!isActive)} /> {t('Active')}
            </Label>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>{t('buttons_Cancel')}</Button>
        <Button color="primary" onClick={handleSubmit(onSubmitHandler)}>{t('buttons_Save')}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default translate(EditModal);
