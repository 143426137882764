import axios from 'axios';

import { apiUrl } from '../../app/config';

/**
 * fetch tasks data from api
 */
class TaskService {
  /**
   * get status info for badge
   */
  static async getStatusInfo() {
    const url = `${apiUrl}/tasks/stat-info`;

    const response = await axios.get(url);
    return response.data;
  }
}

export default TaskService;
