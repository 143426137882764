import { t } from 'react-switch-lang';

import SweetAlert from 'sweetalert2';

/**
 * show error alert from error response
 * @param {*} error error object or error message
 * @param {*} options optional parameter
 *   - defaultMessage default message for alert
 */
export const showErrorAlert = (error, options = {}) => {
  if (typeof error === 'string') {
    // trigger error alert
    return SweetAlert.fire({
      title: t('Error Occured'), text: error, icon: 'error'
    });
  } else if (typeof error === 'object') {
    // if default message set on the options, then use it otherwise use 'Error'
    const defaultMessage = options?.defaultMessage ? options.defaultMessage : 'Error';

    // get proper error message from server
    const errorMessage = error && error.message ? t(error.message) : t(defaultMessage);

    // trigger error alert
    return SweetAlert.fire({
      title: t('Error Occured'), text: errorMessage, icon: 'error'
    });
  }
}

/**
 * show alert on success action
 * @param {*} message
 */
export const showSuccessAlert = (message) => {
  return SweetAlert.fire({
    title: t('Success'), text: t(message), icon: 'success'
  });
}

/**
 * show alert on success action
 * @param {*} htmlMessage message in html format (this message will not be escaped)
 */
export const showHtmlSuccessAlert = (htmlMessage) => {
  return SweetAlert.fire({
    title: t('Success'), html: htmlMessage, icon: 'success'
  });
}

/**
 * show confirmation alert
 * @param {*} message message to show
 * @param {*} options optional parameters
 *  - buttonYesCaption caption for yes button
 *  - buttonCancelCaption caption for cancel button
 */
export const showConfirmationAlert = (message, options = {}) => {
  const buttonYesCaption = options?.buttonYesCaption ? options.buttonYesCaption : 'buttons_Yes';
  const buttonCancelCaption = options?.buttonCancelCaption ? options.buttonCancelCaption : 'buttons_Cancel';

  return SweetAlert.fire({
    title: t(message),
    cancelButtonText: t(buttonCancelCaption),
    confirmButtonText: t(buttonYesCaption),
    reverseButtons: true,
    showCancelButton: true,
  });
}
