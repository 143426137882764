import React from 'react';
import { Button, Form, FormGroup, Label, Col, Modal, ModalBody, ModalFooter, Input } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';

import { updateTaskServiceApplication } from '../../serviceApplication/serviceApplicationSlice';
import { showConfirmationAlert, showErrorAlert, showSuccessAlert} from '../../../app/alertResponse.handler';

const ServiceApplicationMarkDoneModal = ({ isOpen, id, onClose, t })  => {
  const { register, handleSubmit, control, errors } = useForm();
  const dispatch = useDispatch();

  const onSubmitHandler = (data, e) => {
    showConfirmationAlert('Are you sure to mark this Service Application as done?')
      .then(({isConfirmed}) => {
        if (isConfirmed) {
          dispatch(updateTaskServiceApplication({ id, ...data, action: 'markdone' })).then((payload) => {
            const { error } = payload;
            if (error) {
              showErrorAlert(error, { defaultMessage: 'Update data is failed' });
            } else {
              showSuccessAlert('This Service Application has been mark done successfully')
                .then(() => {
                  onClose(true);
                });
            }
          })
          .catch((error) => {
            // TODO: need to show error here
            console.log(error);
          });
        }
      });
  }

  return (
    <Modal isOpen={isOpen} centered>
      <ModalBody className="p-4">
        <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
          <FormGroup className="form-row">
            <Label htmlFor="subject">{t('Finished Date')} <span className="text-danger">(*)</span></Label>
              <Controller
                control={control}
                render={({ onChange, onBlur, value, name, ref }) => (
                  <Col sm="12">
                    <DatePicker
                      selected={value}
                      onChange={e => onChange(e)}
                      name={name}
                      className="form-control digits"
                      dateFormat="dd/MM/yyyy"
                      />
                  </Col>
                )}
                name="finishedAt"
                defaultValue=""
                rules={{
                  required: t('This field must be filled'),
                }}
              />
              <span className="text-danger">{errors.finishedAt && errors.finishedAt.message}</span>
          </FormGroup>
          <FormGroup className="form-row">
            <Label htmlFor="subject">{t('Your Response')}</Label>
            <Input
              type="textarea"
              placeholder={t('Can be left blank')}
              name="responseText"
              innerRef={register}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => onClose(false)}>{t('buttons_Cancel')}</Button>
        <Button color="success" onClick={handleSubmit(onSubmitHandler)}>{t('buttons_Mark_Done')}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default translate(ServiceApplicationMarkDoneModal);
