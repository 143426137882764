import React from 'react';
import { Button } from 'reactstrap';
import { t } from 'react-switch-lang';

const generateTableColumns = (selectHandler) => [
  {
    name: 'Id',
    selector: 'id',
    sortable: false,
    center: false,
    width: '65px',
  },
  {
    name: t('Fullname'),
    selector: (row, index) => `${row.firstname} ${row.lastname}`.trim(),
    sortable: false,
    center: false,
    grow: 1,
  },
  {
    name: t('Position'),
    selector: (row, index) => `${row.Position ? row.Position.name : ''}`,
    sortable: false,
    center: false,
    grow: 1,
  },
  {
    name: t('Actions'),
    sortable: false,
    // login user cannot deactivate himself
    cell: row => (
      <React.Fragment>
        <Button onClick={() => selectHandler(row)} className="btn-square mr-1" color="primary" size="xs"><i className={`fa fa-pencil`}></i> {t('buttons_Select')}</Button>
      </React.Fragment>
    ),
    wrap: true,
    width: '120px'
  }
];

export default generateTableColumns;
