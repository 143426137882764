import React, { useEffect, useState, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'sweetalert2';

import generateTableColumns from './settings/table-column';
import { Breadcrumb } from '../../components/layout';
import { DataTableWithPagination, LoadingInfo } from '../../components/ui-kits';
import { fetchMeetings, destroyMeeting } from './meetingSlice';
import { EditModal, MeetingPointModal } from './modals';
import * as CONSTANTS from '../../app/constants';

const MeetingIndex = (props) => {
  const meetings = useSelector(state => state.meeting.meetings);
  const totalMeeting = useSelector(state => state.meeting.totalMeeting);
  const isLoadingAPI = useSelector(state => state.meeting.loading);

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isMeetingPointEditModal, setIsMeetingPointEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMeetings({ page, pagesize: CONSTANTS.NUMBER_PER_PAGE }));
  }, [dispatch, page]);

  const editHandler = (row) => {
    setSelectedRow(row);
    setIsOpenEditModal(true);
  }

  const editModalOnCloseHandler = () => {
    setIsOpenEditModal(false);
  }

  const meetingPointHandler = (row) => {
    setSelectedRow(row);
    setIsMeetingPointEditModal(true);
  }

  const meetingPointModalOnCloseHandler = () => {
    setIsMeetingPointEditModal(false);
  }

  const onRowClicked = (row) => {
    editHandler(row);
  }

  const tableColumns = useMemo(() => {
    const destroyHandler = (row) => {
      SweetAlert.fire({
        title: props.t('Are you sure to delete this meeting?'),
        cancelButtonText: props.t('buttons_Cancel'),
        confirmButtonText: props.t('buttons_Delete'),
        reverseButtons: true,
        showCancelButton: true,
      }).then(({isConfirmed}) => {
        if (isConfirmed) {
          dispatch(destroyMeeting(row.id)).then(({ error }) => {
            if (error) {
              // get proper error message from server
              const errorMessage = error && error.message ? props.t(error.message) : props.t('Delete data is failed');

              // trigger error alert
              SweetAlert.fire({
                title: props.t('Error Occured'), text: errorMessage, icon: 'error'
              });
            } else {
              SweetAlert.fire({
                title: props.t('Success'), text: props.t('Data has been deleted successfully'), icon: 'success'
              });
            }
          });
        }
      });
    }

    return generateTableColumns(editHandler, destroyHandler, meetingPointHandler);
  }, [dispatch, props])

  return (
    <React.Fragment>
      <Breadcrumb parent={props.t('Meeting')} headerTitle={props.t('Meeting Management')} title={props.t('Index')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
              <DataTableWithPagination
                  data={meetings}
                  columns={tableColumns}
                  totalRow={totalMeeting}
                  onPageChange={(page) => setPage(page)}
                  onRowClicked={onRowClicked}
                  highlightOnHover={true}
                  pointerOnHover={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <EditModal isOpen={isOpenEditModal} meeting={selectedRow} onClose={editModalOnCloseHandler} />
        <MeetingPointModal isOpen={isMeetingPointEditModal} meeting={selectedRow} onClose={meetingPointModalOnCloseHandler} />
      </Container>
      <LoadingInfo loading={isLoadingAPI} />
    </React.Fragment>
  )
}

export default translate(MeetingIndex);
