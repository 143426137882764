import axios from 'axios';

import { apiUrl } from '../../app/config';

/**
 * satisfaction index service
 */
class AttendanceService {
  /**
   * get report monthly
   * @param string month (1..12)
   * @param string year (2021..YEAR_NOW)
   * @param int userId
   */
  static async reportIndex(month, year, userId, format = 'json') {
    const url = `${apiUrl}/attendances/report?month=${month}&year=${year}&userId=${userId}&format=${format}`;

    const axiosOptions = format === 'xls' ? { responseType: 'blob' } : {};
    const response = await axios.get(url, axiosOptions);
    return response.data;
  }
}

export default AttendanceService;
