import React from 'react';
import { Input, Label } from 'reactstrap';
import { t } from 'react-switch-lang';

const OffdayTypeInput = ({ selectedValue, onChange }) => {
  return (
    <div className="m-checkbox-inline custom-radio-ml d-block">
      <div className="radio radio-primary">
        <Input id="receiverRadio1" type="radio" name="receiver" value="Cuti" checked={selectedValue === 'Cuti'} onChange={(e) => onChange(e.target.value)} />
        <Label className="mb-0" for="receiverRadio1"><span className="digits"> {t('offdayType_Cuti')}</span></Label>
      </div>
      <div className="radio radio-primary">
        <Input id="receiverRadio2" type="radio" name="receiver" value="DL" checked={selectedValue === 'DL'} onChange={(e) => onChange(e.target.value)} />
        <Label className="mb-0" for="receiverRadio2"><span className="digits"> {t('offdayType_DL')}</span></Label>
      </div>
    </div>
  )
}

export default OffdayTypeInput;
