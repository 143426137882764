import axios from 'axios';

import { apiUrl } from '../../app/config';

/**
 * fetch typeaheads data from api
 */
class TypeaheadService {
  /**
   * fetch typeaheads list
   */
  static async fetchTypeaheadSenders(keyword) {
    const url = `${apiUrl}/typeahead/sender/${keyword}`;

    const response = await axios.get(url);
    return response.data;
  }

  static async addTypeaheadSenders(data) {
    const url = `${apiUrl}/typeahead/sender`;

    const response = await axios.post(url, data);
    return response.data;
  }

  /**
   * fetch typeaheads list
   */
  static async fetchTypeaheadUsers(keyword) {
    const url = `${apiUrl}/typeahead/user/${keyword}`;

    const response = await axios.get(url);
    return response.data;
  }
}

export default TypeaheadService;
