import axios from 'axios';
import { t } from 'react-switch-lang';

import { apiUrl } from '../../app/config';
import * as CONSTANTS from '../../app/constants';

/**
 * fetch staffOffdays data from api
 */
class StaffOffdayService {
  /**
   * fetch staffOffdays list
   */
  static async fetchStaffOffdays() {
    const url = `${apiUrl}/staff-offdays`;

    const response = await axios.get(url);
    return response.data;
  }

  /**
   * fetch staffOffday info
   * @param string|number id staffOffdays.id or staffOffdays.code
   */
  static async fetchStaffOffday(id) {
    const url = `${apiUrl}/staff-offdays/${id}`;

    const response = await axios.get(url);
    return response.data;
  }

  static async addStaffOffday(data) {
    const url = `${apiUrl}/staff-offdays`;

    const response = await axios.post(url, data);
    return response.data;
  }

  static async updateStaffOffday(id, data) {
    const url = `${apiUrl}/staff-offdays/${id}`;

    const response = await axios.put(url, data);
    return response.data;
  }

  static async destroyStaffOffday(id) {
    const url = `${apiUrl}/staff-offdays/${id}`;

    const response = await axios.delete(url);
    return response.data;
  }

  /**
   * fetch staff offday list
   */
  static async fetchTaskStaffOffdays() {
    const url = `${apiUrl}/tasks/staffOffday`;

    const response = await axios.get(url);
    return response.data;
  }

  /**
   * get staff offday stat
   */
  static async getStaffOffdayStat(userId) {
    const url = `${apiUrl}/staff-offdays/staff-offday-stat/${userId}`;

    const response = await axios.get(url);
    return response.data;
  }

  /**
   * get status text
   * @param {*} status
   */
  static getStatusText = (status) => {
    if (status === CONSTANTS.STAFF_OFFDAY_CREATED) {
      return <span className="text-warning">{ t('offdayStatus_Created') }</span>;
    }
    if (status === CONSTANTS.STAFF_OFFDAY_APPROVED) {
      return <span className="text-success">{ t('offdayStatus_Approved') }</span>;
    }
    if (status === CONSTANTS.STAFF_OFFDAY_DECLINED) {
      return <span className="text-danger">{ t('offdayStatus_Declined') }</span>;
    }
  }
}

export default StaffOffdayService;
