import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useDispatch, useSelector } from 'react-redux';

import { Breadcrumb } from '../../components/layout';
import { getStatusInfo } from '../../features/task/taskSlice';
import { canShowMenuByAccessRight } from '../../util/AccessRightHelper';
import ServiceQueueSearchForm from '../../features/serviceQueues/SearchForm';

const Dashboard = (props) => {
  const userAccessRights = useSelector((state) => state.auth.userInfo?.Position?.accessRights || {});

  const [isShowDefaultDashboard, setIsShowDefaultDashboard] = useState(true);
  const [canAccessPTSP, setCanAccessPTSP] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStatusInfo());
  }, [dispatch]);

  useEffect(() => {
    setIsShowDefaultDashboard(!(canAccessPTSP));
  }, [canAccessPTSP]);

  useEffect(() => {
    const canAccessPTSP = canShowMenuByAccessRight(['canAccessPTSP'], userAccessRights);

    setCanAccessPTSP(canAccessPTSP);
  }, [userAccessRights]);

  const showDefaultDashboard = () => {
    return (
      <Card>
        <CardBody>
          Dashboard
        </CardBody>
      </Card>
    );
  }

  const showServiceQueueForm = () => {
    return (
      <Card>
        <CardBody>
          <ServiceQueueSearchForm />
        </CardBody>
      </Card>
    )
  }

  const showDashboardComponents = () => {
    if (isShowDefaultDashboard) {
      return showDefaultDashboard();
    }

    return (
      <>
        { showServiceQueueForm() }
      </>
    );
  }

  return (
    <React.Fragment>
      <Breadcrumb parent="Home" headerTitle={props.t('Dashboard')} title={props.t('Index')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
          { showDashboardComponents() }
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default translate(Dashboard);
