import axios from 'axios';

import { apiUrl } from '../../app/config';
import * as RequestHelper from '../../util/RequestHelper';

/**
 * fetch letters data from api
 */
class LetterService {
  /**
   * fetch letters list
   */
   static async fetchLetters(queryParams = {}, cancelToken = undefined) {
    const options = {};
    if (cancelToken) {
      options.cancelToken = cancelToken;
    }

    const response = await axios.post(`${apiUrl}/letters/search`, queryParams, options);
    return response.data;
  }

  /**
   * fetch letter info
   * @param string|number id letters.id or letters.code
   */
  static async fetchLetter(id) {
    const url = `${apiUrl}/letters/${id}`;

    const response = await axios.get(url);
    return response.data;
  }

  static async addLetter(data) {
    const url = `${apiUrl}/letters`;

    const response = await axios.post(url, data);
    return response.data;
  }

  static async updateLetter(id, data) {
    const url = `${apiUrl}/letters/${id}`;

    const response = await axios.put(url, data);
    return response.data;
  }

  static async destroyLetter(id) {
    const url = `${apiUrl}/letters/${id}`;

    const response = await axios.delete(url);
    return response.data;
  }

  /**
   * fetch letter histories list
   */
  static async fetchLetterHistories(id) {
    const url = `${apiUrl}/letter-histories/${id}`;

    const response = await axios.get(url);
    return response.data;
  }

  /**
   * fetch letters list
   */
  static async fetchTaskLetters() {
    const url = `${apiUrl}/tasks/letter`;

    const response = await axios.get(url);
    return response.data;
  }

  /**
   * get report by daily or month
   * @param string timeScale (daily or monthly)
   * @param object queryParams
   *  - startDate (format DD/MM/YYYY)
   *  - endDate (format DD/MM/YYYY)
   *  - format = 'json' | 'xls',
   *  - status
   *  - showTotalOnly return total only
   *  - page
   *  - pagesize
   */
  static async reportIndex(timeScale, queryParams = {}, cancelToken = undefined) {
    const { format } = queryParams;

    const queryString = RequestHelper.getQueryStringFromParams(queryParams);

    const url = `${apiUrl}/letters/report/${timeScale}?${queryString}`;

    const options = {};
    if (cancelToken) {
      options.cancelToken = cancelToken;
    }

    if (format === 'xls') {
      options.responseType = 'blob';
    }

    const response = await axios.get(url, options);
    return response.data;
  }
}

export default LetterService;
