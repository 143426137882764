import axios from 'axios';

import { apiUrl } from '../../app/config';
import * as RequestHelper from '../../util/RequestHelper';

/**
 * service application service
 */
class ServiceApplicationService {
  static async updateServiceApplication(id, data) {
    const url = `${apiUrl}/service-applications/${id}`;

    const response = await axios.put(url, data);
    return response.data;
  }

  /**
   * fetch service application list
   */
  static async fetchTaskServiceApplications(queryParams = {}, cancelToken = undefined) {
    const queryString = RequestHelper.getQueryStringFromParams(queryParams);

    const url = queryString ? `${apiUrl}/tasks/serviceApplication?${queryString}` : `${apiUrl}/tasks/serviceApplication`;

    const options = {};
    if (cancelToken) {
      options.cancelToken = cancelToken;
    }

    const response = await axios.get(url, options);
    return response.data;
  }

  /**
   * get report by daily or month
   * @param string timeScale (daily or monthly)
   * @param object queryParams
   *  - startDate (format DD/MM/YYYY)
   *  - endDate (format DD/MM/YYYY)
   *  - format = 'json' | 'xls',
   *  - status
   *  - showTotalOnly return total only
   *  - page
   *  - pagesize
   */
  static async reportIndex(timeScale, queryParams = {}, cancelToken = undefined) {
    const { format } = queryParams;

    const queryString = RequestHelper.getQueryStringFromParams(queryParams);

    const url = `${apiUrl}/service-applications/report/${timeScale}?${queryString}`;

    const options = {};
    if (cancelToken) {
      options.cancelToken = cancelToken;
    }

    if (format === 'xls') {
      options.responseType = 'blob';
    }

    const response = await axios.get(url, options);
    return response.data;
  }
}

export default ServiceApplicationService;
