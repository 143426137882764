import React from 'react';
import { t } from 'react-switch-lang';

import { STATUS_DONE } from '../../../app/constants';
import { displayDateOnly, displayTimeOnly } from '../../../util/FormaterHelper';

const renderStatus = (row) => {
  const { status } = row;

  if (status === STATUS_DONE) {
    return <i className="fa fa-check fa-lg text-success"></i>
  }

  return <span className="fa fa-times fa-lg text-danger"></span>
}

const generateTableColumns = () => [
  {
    name: 'Id',
    selector: 'id',
    sortable: false,
    center: true,
    width: '65px',
  },
  {
    name: t('Description'),
    selector: (row, index) => (
      <React.Fragment>
      <span><b>{t('Full Name')}</b> {row.fullName}</span><br />
      <span><b>{t('Email')}</b> {row.email}</span><br />
      <small>{t('Registration Number')}: {row.registrationNumber}</small>
      </React.Fragment>
    ),
    sortable: false,
    center: false,
    grow: 1,
  },
  {
    name: t('Phone'),
    selector: 'phone',
    sortable: false,
    center: false,
    grow: 1,
  },
  {
    name: 'Status',
    selector: 'status',
    cell: (row) => renderStatus(row),
    sortable: false,
    center: true,
    width: '100px',
  },
  {
    name: t('Input Date'),
    selector: 'createdAt',
    cell: (row) => (
      <span>
      {displayDateOnly(row.createdAt) }
      <br /><small>{ displayTimeOnly(row.createdAt) }</small>
      </span>
    ),
    sortable: false,
    center: false,
    width: '120px',
  }
];

export default generateTableColumns;
