import React from 'react';
import { t } from 'react-switch-lang';

const StatusInfo = ({ status, doneText }) => {
  const doneTextToShow = doneText ? doneText : t('Done');
  const renderStatus = () => {
    if (status === 1) {
      return <i className="fa fa-check fa-lg text-success"> {doneTextToShow}</i>
    }

    return <i className="fa fa-close fa-lg text-danger"> {t('Inprogress')}</i>
  }

  return (
    <>
    {renderStatus()}
    </>
  )
}

export default StatusInfo;
