import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import LetterNumberService from './letterNumber.service';
import { thunkHandler } from '../../app/thunk.handler';

export const fetchLetterNumbers = createAsyncThunk('letterNumbers/fetchLetterNumbers', async (payload, thunkAPI) => {
  return thunkHandler(LetterNumberService.fetchLetterNumbers(), thunkAPI);
});

export const destroyLetterNumber = createAsyncThunk('letterNumbers/destroy', async (id, thunkAPI) => {
  return thunkHandler(LetterNumberService.destroyLetterNumber(id), thunkAPI);
});

export const addLetterNumber = createAsyncThunk('letterNumbers/addLetterNumber', async (payload, thunkAPI) => {
  return thunkHandler(LetterNumberService.addLetterNumber(payload), thunkAPI);
});

export const updateLetterNumber = createAsyncThunk('letterNumbers/updateLetterNumber', async (payload, thunkAPI) => {
  const { id, ...data } = payload;

  return thunkHandler(LetterNumberService.updateLetterNumber(id, data), thunkAPI);
});

export const fetchTaskLetterNumbers = createAsyncThunk('letterNumbers/fetchTaskLetterNumbers', async (payload, thunkAPI) => {
  return thunkHandler(LetterNumberService.fetchTaskLetterNumbers(payload), thunkAPI);
});

const updateLetterNumbersState = (letterNumbers, letterNumber) => {
  const updateLetterNumbers = letterNumbers.reduce((result, item) => {
    if (letterNumber.id && item.id === letterNumber.id) {
      result.push(letterNumber);
    } else {
      result.push(item);
    }

    return result;
  }, []);

  return updateLetterNumbers;
}

const letterNumberSlice = createSlice({
  name: 'letterNumber',
  initialState: {
    letterNumbers: [],
    taskLetterNumbers: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchLetterNumbers.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchLetterNumbers.fulfilled]: (state, action) => {
      state.loading = false;
      state.letterNumbers = action.payload;
    },
    [fetchLetterNumbers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [destroyLetterNumber.pending]: (state, action) => {
      state.loading = true;
    },
    [destroyLetterNumber.fulfilled]: (state, action) => {
      state.loading = false;
      state.letterNumbers = state.letterNumbers.filter((item) => item.id !== action.payload.id);
    },
    [destroyLetterNumber.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [addLetterNumber.pending]: (state, action) => {
      state.loading = true;
    },
    [addLetterNumber.fulfilled]: (state, action) => {
      state.loading = false;
      state.letterNumbers.unshift(action.payload);
    },
    [addLetterNumber.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [updateLetterNumber.pending]: (state, action) => {
      state.loading = true;
    },
    [updateLetterNumber.fulfilled]: (state, action) => {
      state.loading = false;
      state.letterNumbers = updateLetterNumbersState(state.letterNumbers, action.payload);
      state.taskLetterNumbers = updateLetterNumbersState(state.taskLetterNumbers, action.payload);
    },
    [updateLetterNumber.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [fetchTaskLetterNumbers.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchTaskLetterNumbers.fulfilled]: (state, action) => {
      state.loading = false;
      state.taskLetterNumbers = action.payload;
    },
    [fetchTaskLetterNumbers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  }
})

export default letterNumberSlice.reducer;
