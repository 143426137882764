import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import StaffOffdayService from './staffOffday.service';
import { thunkHandler } from '../../app/thunk.handler';

export const fetchStaffOffdays = createAsyncThunk('staffOffdays/fetchStaffOffdays', async (payload, thunkAPI) => {
  return thunkHandler(StaffOffdayService.fetchStaffOffdays(), thunkAPI);
});

export const destroyStaffOffday = createAsyncThunk('staffOffdays/destroy', async (id, thunkAPI) => {
  return thunkHandler(StaffOffdayService.destroyStaffOffday(id), thunkAPI);
});

export const addStaffOffday = createAsyncThunk('staffOffdays/addStaffOffday', async (payload, thunkAPI) => {
  return thunkHandler(StaffOffdayService.addStaffOffday(payload), thunkAPI);
});

export const updateStaffOffday = createAsyncThunk('staffOffdays/updateStaffOffday', async (payload, thunkAPI) => {
  const { id, ...data } = payload;

  return thunkHandler(StaffOffdayService.updateStaffOffday(id, data), thunkAPI);
});

export const fetchTaskStaffOffdays = createAsyncThunk('staffOffdays/fetchTaskStaffOffdays', async (payload, thunkAPI) => {
  return thunkHandler(StaffOffdayService.fetchTaskStaffOffdays(payload), thunkAPI);
});

const updateStaffOffdaysState = (staffOffdays, staffOffday) => {
  const updateStaffOffdays = staffOffdays.reduce((result, item) => {
    if (staffOffday.id && item.id === staffOffday.id) {
      result.push(staffOffday);
    } else {
      result.push(item);
    }

    return result;
  }, []);

  return updateStaffOffdays;
}

const updateTaskStaffOffdaysState = (taskStaffOffdays, staffOffday) => {
  const updateStaffOffdays = taskStaffOffdays.reduce((result, item) => {
    if (staffOffday.id && item.id === staffOffday.id) {
      result.push(staffOffday);
    } else {
      result.push(item);
    }

    return result;
  }, []);

  return updateStaffOffdays;
}

const staffOffdaySlice = createSlice({
  name: 'staffOffday',
  initialState: {
    staffOffdays: [],
    taskStaffOffdays: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchStaffOffdays.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchStaffOffdays.fulfilled]: (state, action) => {
      state.loading = false;
      state.staffOffdays = action.payload;
    },
    [fetchStaffOffdays.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [destroyStaffOffday.pending]: (state, action) => {
      state.loading = true;
    },
    [destroyStaffOffday.fulfilled]: (state, action) => {
      state.loading = false;
      state.staffOffdays = state.staffOffdays.filter((item) => item.id !== action.payload.id);
    },
    [destroyStaffOffday.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [addStaffOffday.pending]: (state, action) => {
      state.loading = true;
    },
    [addStaffOffday.fulfilled]: (state, action) => {
      state.loading = false;
      state.staffOffdays.unshift(action.payload);
    },
    [addStaffOffday.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [updateStaffOffday.pending]: (state, action) => {
      state.loading = true;
    },
    [updateStaffOffday.fulfilled]: (state, action) => {
      state.loading = false;
      state.staffOffdays = updateStaffOffdaysState(state.staffOffdays, action.payload);
      state.taskStaffOffdays = updateTaskStaffOffdaysState(state.taskStaffOffdays, action.payload);
    },
    [updateStaffOffday.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [fetchTaskStaffOffdays.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchTaskStaffOffdays.fulfilled]: (state, action) => {
      state.loading = false;
      state.taskStaffOffdays = action.payload.result ? action.payload.result : []
    },
    [fetchTaskStaffOffdays.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  }
})

export default staffOffdaySlice.reducer;
