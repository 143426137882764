import React, { useEffect, useState, useMemo } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { translate } from 'react-switch-lang';
import SweetAlert from 'sweetalert2';
import DataTable from 'react-data-table-component';

import MeetingService from '../../meeting/meeting.service';
import AddMeetingPointModal from './AddMeetingPointModal';
import generateTableColumns from '../settings/meeting-point-table-column';
import { showErrorAlert, showSuccessAlert } from '../../../app/alertResponse.handler';

const MeetingPointModal = ({ isOpen, meeting, onClose, t })  => {
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [meetingPoints, setMeetingPoints] = useState([]);

  const editHandler = (row) => {
    setSelectedRow(row);
    setIsOpenAddModal(true);
  }

  const onRowClicked = (row) => {
    editHandler(row);
  }

  const addModalOnCloseHandler = (payload) => {
    if (payload && payload.id) {
      updateMeetingPointState(payload);
    }

    setIsOpenAddModal(false);
  }

  const updateMeetingPointState = (meetingPoint) => {
    let found = false;
    const updateMeetingPoints = meetingPoints.reduce((result, item) => {
      if (meetingPoint.id && item.id === meetingPoint.id) {
        result.push(meetingPoint);
        found = true;
      } else {
        result.push(item);
      }

      return result;
    }, []);

    if (!found) {
      updateMeetingPoints.push(meetingPoint);
    }

    setMeetingPoints(updateMeetingPoints);
  }

  const tableColumns = useMemo(() => {
    const destroyHandler = (row) => {
      SweetAlert.fire({
        title: t('Are you sure to delete this meeting point?'),
        cancelButtonText: t('buttons_Cancel'),
        confirmButtonText: t('buttons_Delete'),
        reverseButtons: true,
        showCancelButton: true,
      }).then(({isConfirmed}) => {
        if (isConfirmed) {
          MeetingService.destroyMeetingPoint(meeting?.id, row.id).then((error) => {
            if (error) {
              showErrorAlert(error, { defaultMessage: 'Delete data is failed' })
            } else {
              showSuccessAlert('Data has been deleted successfully')
                .then(() => {
                  const updateMeetingPoints = meetingPoints.reduce((result, item) => {
                    if (item.id !== row.id) {
                      result.push(item);
                    }

                    return result;
                  }, []);

                  setMeetingPoints(updateMeetingPoints);
                });
            }
          });
        }
      });
    }

    return generateTableColumns(editHandler, destroyHandler);
  }, [meeting, meetingPoints, t])

  useEffect(() => {
    const fetchHistories = async () => {
      if (meeting?.id) {
        setIsLoading(true);
        try {
          const result = await MeetingService.fetchMeetingPoints(meeting?.id);
          setMeetingPoints(result);
        } catch (error) {
          // TODO: do we need to show error here
        }

        setIsLoading(false);
      }
    }

    fetchHistories();
  }, [meeting]);

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={onClose} size="lg" centered style={{maxWidth: '1000px', width: '80%'}}>
        <ModalHeader toggle={onClose}>
          {t('Meeting Point')}
        </ModalHeader>
        <ModalBody className="p-4">
          <DataTable
            data={meetingPoints}
            columns={tableColumns}
            striped={true}
            center={true}
            selectableRows={false}
            selectableRowsHighlight
            noHeader
            pagination
            paginationServer
            onRowClicked={onRowClicked}
            highlightOnHover={true}
            pointerOnHover={true}
            progressPending={isLoading}
            noDataComponent={t('There are no records to display')}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClose}>{t('buttons_Close')}</Button>
          <Button color="success" onClick={editHandler}>{t('buttons_Add_Meeting_Point')}</Button>
        </ModalFooter>
      </Modal>

      <AddMeetingPointModal isOpen={isOpenAddModal} meetingId={meeting?.id} meetingPoint={selectedRow} onClose={addModalOnCloseHandler} />
    </React.Fragment>
  );
}

export default translate(MeetingPointModal);
