import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardFooter, Container, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { setUserInfo } from '../auth/authSlice';
import { updateUser } from './userSlice';
import { Breadcrumb } from '../../components/layout';
import { GenderInput } from '../../components/ui-kits';
import { showErrorAlert, showSuccessAlert } from '../../app/alertResponse.handler';
import { getPositionName } from '../../util/ModelReferenceHelper';
import { EditPasswordModal } from './modals';

const MyProfile = ({ t }) => {
  const userInfo = useSelector((state) => state.auth.userInfo);
  const positions = useSelector(state => state.position.positions);

  const [positionName, setPositionName] = useState('');
  const [isOpenEditPasswordModal, setIsOpenEditPasswordModal] = useState(false);

  const { register, reset, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    // TODO: sometime gender not selected properly
    setPositionName(getPositionName(userInfo?.positionId, positions));
  }, [userInfo, positions]);

  const editPasswordHandler = () => {
    setIsOpenEditPasswordModal(true);
  }

  const editPasswordModalOnCloseHandler = () => {
    setIsOpenEditPasswordModal(false);
  }

  const onSubmitHandler = (data, e) => {
    dispatch(updateUser({ id: userInfo?.id, ...data, action: 'updateprofile' })).then((response) => {
      const { error, payload } = response;
      if (error) {
        showErrorAlert(error, { defaultMessage: 'Update account is failed' });
      } else {
        dispatch(setUserInfo(payload));
        showSuccessAlert('Your account has been updated successfully');
      }
    })
    .catch((error) => {
      // TODO: need to show error here
      console.log(error);
    });
  }

  return (
    <React.Fragment>
      <Breadcrumb parent={t('User')} headerTitle={t('My Account')} title={t('My Account')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
                <FormGroup className="form-row">
                    <Label className="col-sm-2 col-form-label" htmlFor="code">{t('Position')}</Label>
                    <Col sm="10">
                      <Input type="text" className="form-control btn-square" disabled={true} value={positionName} />
                    </Col>
                  </FormGroup>

                  <FormGroup className="form-row">
                    <Label className="col-sm-2 col-form-label" htmlFor="username">{t('Username')}</Label>
                    <Col sm="10">
                      <Input type="text" className="form-control btn-square" disabled={true} defaultValue={userInfo?.username} />
                    </Col>
                  </FormGroup>

                  <FormGroup className="form-row border-bottom pb-3 mb-3">
                    <Label className="col-sm-2 col-form-label" htmlFor="password">{t('Password')}</Label>
                    <Col sm="10">
                      <Button color="warning txt-dark" type="button" className="mr-2" onClick={editPasswordHandler}>{t('buttons_Change_Password')}</Button>
                    </Col>
                  </FormGroup>

                  <FormGroup className="form-row">
                    <Label className="col-sm-2 col-form-label" htmlFor="username">{t('Gender')} <span className="text-danger">(*)</span></Label>
                    <Col sm="10">
                      <GenderInput
                        className="form-control btn-square"
                        name="gender"
                        defaultValue={userInfo && userInfo.gender}
                        innerRef={
                          register({
                            required: t('This field must be filled')
                          })
                        }
                      />
                      <span color="danger">{errors.gender && errors.gender.message}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup className="form-row">
                    <Label className="col-sm-2 col-form-label" htmlFor="firstname">{t('Firstname')} <span className="text-danger">(*)</span></Label>
                    <Col sm="10">
                      <Input
                        className="form-control btn-square"
                        name="firstname"
                        type="text"
                        defaultValue={userInfo?.firstname}
                        maxLength={50}
                        innerRef={
                          register({
                            required: t('This field must be filled')
                          })
                        }
                      />
                      <span color="danger">{errors.firstname && errors.firstname.message}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup className="form-row">
                    <Label className="col-sm-2 col-form-label" htmlFor="lastname">{t('Lastname')}</Label>
                    <Col sm="10">
                      <Input
                        className="form-control btn-square"
                        name="lastname"
                        type="text"
                        defaultValue={userInfo?.lastname}
                        maxLength={50}
                        innerRef={register}
                        />
                    </Col>
                  </FormGroup>

                  <FormGroup className="form-row">
                    <Label className="col-sm-2 col-form-label" htmlFor="lastname">{t('Email')} <span className="text-danger">(*)</span></Label>
                    <Col sm="10">
                      <Input
                        className="form-control btn-square"
                        name="email"
                        type="text"
                        defaultValue={userInfo?.email}
                        maxLength={150}
                        innerRef={
                          register({
                            required: t('This field must be filled'),
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: t('Invalid email address')
                            }
                          })
                        }
                        />
                      <span color="danger">{errors.email && errors.email.message}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup className="form-row mb-0">
                    <Label className="col-sm-2 col-form-label" htmlFor="phone">{t('Phone')}</Label>
                    <Col sm="10">
                      <Input
                        className="form-control"
                        name="phone"
                        type="text"
                        defaultValue={userInfo?.phone}
                        maxLength={15}
                        innerRef={register}
                        />
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter className="text-right">
                <Button color="secondary" className="mr-2" onClick={() => reset()}>{t('buttons_Cancel')}</Button>
                <Button color="primary" onClick={handleSubmit(onSubmitHandler)}>{t('buttons_Save')}</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
      <EditPasswordModal isOpen={isOpenEditPasswordModal} user={userInfo} onClose={editPasswordModalOnCloseHandler} />
    </React.Fragment>
  )
}

export default translate(MyProfile);
