import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import TaskService from './task.service';
import { thunkHandler } from '../../app/thunk.handler';

export const getStatusInfo = createAsyncThunk('tasks/getStatusInfo', async (payload, thunkAPI) => {
  return thunkHandler(TaskService.getStatusInfo(), thunkAPI);
});

const taskSlice = createSlice({
  name: 'task',
  initialState: {
    statInfo: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [getStatusInfo.pending]: (state, action) => {
      state.loading = true;
    },
    [getStatusInfo.fulfilled]: (state, action) => {
      state.loading = false;
      state.statInfo = action.payload;
    },
    [getStatusInfo.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  }
})

export default taskSlice.reducer;
