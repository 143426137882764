import React, { useState } from 'react';
import { Button, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { translate } from 'react-switch-lang';

import { showErrorAlert } from '../../app/alertResponse.handler';
import ServiceQueueService from '../serviceQueues/serviceQueue.service';
import DetailServiceQueue from './DetailServiceQueue';

const SearchForm = ({ t }) => {
  const [serviceQueue, setServiceQueue] = useState(undefined);
  const [isFormEnable, setIsFormEnable] = useState(true);

  const { register, reset, handleSubmit } = useForm();

  const onSubmitHandler = (data, e) => {
    setIsFormEnable(false);
    setServiceQueue(null);
    ServiceQueueService.fetchServiceByRegistrationNumber(data.registrationNumber)
      .then((data) => {
        setServiceQueue(data);
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          showErrorAlert(new Error(t('Data is not found')), { defaultMessage: t('Data is not found') });
        } else {
          showErrorAlert(error, { defaultMessage: t('Unknown Error') });
        }

        console.log(error);
      })
      .finally(() => {
        clearForm();
        setIsFormEnable(true);
      })
  }

  const onNextClick = () => {
    if (serviceQueue) {
      setIsFormEnable(false);

      ServiceQueueService.updateServiceQueue(serviceQueue.id, { action: 'marknextstep' })
        .then((data) => {
          setServiceQueue(data);
        })
        .catch((error) => {
          if (error.response?.status === 404) {
            showErrorAlert(new Error(t('Data is not found')), { defaultMessage: t('Data is not found') });
          } else {
            showErrorAlert(error, { defaultMessage: t('Unknown Error') });
          }

          console.log(error);
        })
        .finally(() => {
          setIsFormEnable(true);
        })
    }
  }

  /**
   * clear form after submit or just reset form
   */
  const clearForm = () => {
    reset();
    // TODO: set focus to registration number input
  }

  const showDetailServiceQueue = () => {
    if (serviceQueue) {
      return (
        <>
          <hr />
          <DetailServiceQueue serviceQueue={serviceQueue} onNextClick={onNextClick} isFormEnable={isFormEnable} />
        </>
      );
    }
  }

  return (
    <>
      <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
        <FormGroup className="form-row">
          <Label className="col-sm-3 col-form-label" htmlFor="registrationNumber">{t('Service Queue Number')} <span className="text-danger">(*)</span></Label>
          <Col sm="9">
            <InputGroup>
              <Input
                name="registrationNumber"
                type="text"
                maxLength={150}
                disabled={!isFormEnable}
                innerRef={
                  register({
                    required: t('This field must be filled')
                  })
                }
              />
              <InputGroupAddon addonType="append">
                <Button type="submit" disabled={!isFormEnable}>{t('buttons_Search')}</Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </FormGroup>
      </Form>

      {showDetailServiceQueue()}
    </>
  );
}

export default translate(SearchForm);
