import React from 'react';
import { Button } from 'reactstrap';
import { t } from 'react-switch-lang';

import { displayDateOnly } from '../../../util/FormaterHelper';

const generateTableColumns = (editHandler, destroyHandler, viewMeetingPointHandler) => [
  {
    name: 'Id',
    selector: 'id',
    sortable: false,
    center: false,
    width: '65px',
  },
  {
    name: t('Registration Number'),
    selector: 'registrationNumber',
    sortable: false,
    center: false,
    grow: 1,
  },
  {
    name: t('Subject'),
    selector: 'subject',
    sortable: false,
    center: false,
    wrap: true,
    grow: 1,
  },
  {
    name: t('Description'),
    selector: 'description',
    sortable: false,
    center: false,
    wrap: true,
    grow: 1,
  },
  {
    name: t('Meeting Date'),
    selector: 'meetingAt',
    cell: row => displayDateOnly(row.meetingAt),
    sortable: false,
    center: false,
    grow: 1,
  },
  {
    name: t('Actions'),
    sortable: false,
    cell: row => (
      <React.Fragment>
        <Button onClick={() => viewMeetingPointHandler(row)} className="btn-square mr-1" color="primary" size="xs"><i className={`fa fa-pencil`}></i> {t('buttons_View_Meeting_Point')}</Button>
        <Button onClick={() => destroyHandler(row)} className="btn-square mr-1" color="danger" size="xs"><i className={`fa fa-trash`}></i> {t('buttons_Delete')}</Button>
      </React.Fragment>
    ),
    wrap: true,
    width: '200px'
  }
];

export default generateTableColumns;
