import axios from 'axios';

import { apiUrl } from '../../app/config';
import * as RequestHelper from '../../util/RequestHelper';

/**
 * fetch documents data from api
 */
class DocumentService {
  /**
   * fetch documents list
   */
  static async fetchDocuments() {
    const url = `${apiUrl}/documents`;

    const response = await axios.get(url);
    return response.data;
  }

  /**
   * fetch document info
   * @param string|number id documents.id or documents.code
   */
  static async fetchDocument(id) {
    const url = `${apiUrl}/documents/${id}`;

    const response = await axios.get(url);
    return response.data;
  }

  static async addDocument(data) {
    const url = `${apiUrl}/documents`;

    const response = await axios.post(url, data);
    return response.data;
  }

  static async updateDocument(id, data) {
    const url = `${apiUrl}/documents/${id}`;

    const response = await axios.put(url, data);
    return response.data;
  }

  static async destroyDocument(id) {
    const url = `${apiUrl}/documents/${id}`;

    const response = await axios.delete(url);
    return response.data;
  }

  /**
   * fetch document histories list
   */
  static async fetchDocumentHistories(id) {
    const url = `${apiUrl}/document-histories/${id}`;

    const response = await axios.get(url);
    return response.data;
  }

  /**
   * fetch documents list
   */
  static async fetchTaskDocuments() {
    const url = `${apiUrl}/tasks/document`;

    const response = await axios.get(url);
    return response.data;
  }

  /**
   * get report by daily or month
   * @param string timeScale (daily or monthly)
   * @param object queryParams
   *  - startDate (format DD/MM/YYYY)
   *  - endDate (format DD/MM/YYYY)
   *  - format = 'json' | 'xls',
   *  - status
   *  - showTotalOnly return total only
   *  - page
   *  - pagesize
   */
  static async reportIndex(timeScale, queryParams = {}, cancelToken = undefined) {
    const { format } = queryParams;

    const queryString = RequestHelper.getQueryStringFromParams(queryParams);

    const url = `${apiUrl}/documents/report/${timeScale}?${queryString}`;

    const options = {};
    if (cancelToken) {
      options.cancelToken = cancelToken;
    }

    if (format === 'xls') {
      options.responseType = 'blob';
    }

    const response = await axios.get(url, options);
    return response.data;
  }
}

export default DocumentService;
