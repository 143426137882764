import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import ReligiousConsultationService from './religiousConsultation.service';
import { thunkHandler } from '../../app/thunk.handler';

export const fetchTaskReligiousConsultations = createAsyncThunk('religiousConsultations/fetchTaskReligiousConsultations', async (payload, thunkAPI) => {
  return thunkHandler(ReligiousConsultationService.fetchTaskReligiousConsultations(payload), thunkAPI);
});

const religiousConsultationSlice = createSlice({
  name: 'religiousConsultation',
  initialState: {
    taskReligiousConsultations: [],
    totalTaskReligiousConsultations: 0,
    loading: false,
    error: null,
  },
  reducers: {
    updateStatusById(state, action) {
      const id = action.payload.id;
      const status = action.payload.status;

      const result = state.taskReligiousConsultations.map((item) => {
        if (item.id === id) {
          item.status = status;
        }

        return item;
      });

      state.taskReligiousConsultations = result;
    }
  },
  extraReducers: {
    [fetchTaskReligiousConsultations.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchTaskReligiousConsultations.fulfilled]: (state, action) => {
      state.loading = false;
      state.taskReligiousConsultations = action.payload.result ? action.payload.result : []
      state.totalTaskReligiousConsultations = action.payload.total ? action.payload.total : 0;
    },
    [fetchTaskReligiousConsultations.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  }
});

export const { updateStatusById } = religiousConsultationSlice.actions;

export default religiousConsultationSlice.reducer;
