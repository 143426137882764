import axios from 'axios';

import { apiUrl } from '../../app/config';
import * as RequestHelper from '../../util/RequestHelper';

/**
 * fetch serviceTypeConsultations data from api
 */
class ServiceTypeConsultationService {
  /**
   * fetch serviceTypeConsultations list
   */
   static async fetchServiceTypeConsultations(queryParams = {}, cancelToken = undefined) {
    const queryString = RequestHelper.getQueryStringFromParams(queryParams);

    const url = queryString ? `${apiUrl}/service-type-consultations?${queryString}` : `${apiUrl}/service-type-consultations`;

    const options = {};
    if (cancelToken) {
      options.cancelToken = cancelToken;
    }

    const response = await axios.get(url, options);
    return response.data;
  }

  /**
   * fetch serviceTypeConsultation info
   * @param string|number id serviceTypeConsultations.id or serviceTypeConsultations.code
   */
  static async fetchServiceTypeConsultation(id) {
    const url = `${apiUrl}/service-type-consultations/${id}`;

    const response = await axios.get(url);
    return response.data;
  }

  static async addServiceTypeConsultation(data) {
    const url = `${apiUrl}/service-type-consultations`;

    const response = await axios.post(url, data);
    return response.data;
  }

  static async updateServiceTypeConsultation(id, data) {
    const url = `${apiUrl}/service-type-consultations/${id}`;

    const response = await axios.put(url, data);
    return response.data;
  }

  static async destroyServiceTypeConsultation(id) {
    const url = `${apiUrl}/service-type-consultations/${id}`;

    const response = await axios.delete(url);
    return response.data;
  }

  /**
   * fetch staff offday list
   */
  static async fetchTaskServiceTypeConsultations() {
    const url = `${apiUrl}/tasks/serviceTypeConsultation`;

    const response = await axios.get(url);
    return response.data;
  }
}

export default ServiceTypeConsultationService;
