import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import { t } from 'react-switch-lang';

import { TEMPLE_TYPES } from '../../app/constants';

const TempleTypeInput = (props) => {
  const { religionName, ...otherProps } = props;
  const [templeTypes, setTempleTypes] = useState([]);

  useEffect(() => {
    setTempleTypes(TEMPLE_TYPES[props.religionName] ? TEMPLE_TYPES[props.religionName] : []);
  }, [props.religionName]);

  return (
    <Input
      type="select"
      { ...otherProps }
    >
      <option key={0} value="">{t('Please Select')}</option>
      {templeTypes.map(item =>
        <option key={item} value={item}>{item}</option>
      )};
    </Input>
  )
}

export default TempleTypeInput;
