import axios from 'axios';

import { apiUrl } from '../../app/config';
import * as RequestHelper from '../../util/RequestHelper';

/**
 * satisfaction index service
 */
class GuestBookService {
  /**
   * get report by daily or month
   * @param string timeScale (daily or monthly)
   * @param object queryParams
   *  - startDate (format DD/MM/YYYY)
   *  - endDate (format DD/MM/YYYY)
   *  - format = 'json' | 'xls',
   *  - showTotalOnly return total only
   *  - page
   *  - pagesize
   */
  static async reportIndex(timeScale, queryParams = {}, cancelToken = undefined) {
    const { format } = queryParams;

    const queryString = RequestHelper.getQueryStringFromParams(queryParams);

    const url = `${apiUrl}/guest-books/report/${timeScale}?${queryString}`;

    const options = {};
    if (cancelToken) {
      options.cancelToken = cancelToken;
    }

    if (format === 'xls') {
      options.responseType = 'blob';
    }

    const response = await axios.get(url, options);
    return response.data;
  }
}

export default GuestBookService;
