import React from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { updateTemple } from '../templeSlice';
import { DistrictInput, ReligionInput, TempleTypeInput } from '../../../components/ui-kits';
import { showErrorAlert, showSuccessAlert } from '../../../app/alertResponse.handler';

const EditModal = ({ isOpen, temple, onClose, t })  => {
  const { register, handleSubmit, watch, errors } = useForm();
  const watchReligionName = watch("religionName", null);
  const dispatch = useDispatch();

  const onSubmitHandler = (data) => {
    const { id } = temple;

    dispatch(updateTemple({ id, ...data })).then(({ error }) => {
      if (error) {
        showErrorAlert(error, { defaultMessage: 'Change data is failed' })
      } else {
        showSuccessAlert('Data has been changed successfully')
          .then(() => {
            onClose();
          });
      }
    })
    .catch((error) => {
      // TODO: need to show error here
      console.log(error);
    });
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalHeader toggle={onClose}>
        {t('Edit Temple')}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
          <FormGroup className="form-row">
            <Label htmlFor="name">{t('Religion')} <span className="text-danger">(*)</span></Label>
            <ReligionInput
                className="form-control btn-square"
                name="religionName"
                defaultValue={temple && temple.religionName}
                innerRef={
                  register({
                    required: t('This field must be filled')
                  })
                }
              />
            <span color="danger">{errors.religionName && errors.religionName.message}</span>
          </FormGroup>
          <FormGroup className="form-row">
            <Label htmlFor="code">{t('Temple Type')} <span className="text-danger">(*)</span></Label>
            <TempleTypeInput
              className="form-control btn-square"
              name="templeType"
              religionName={watchReligionName || (temple && temple.religionName)}
              defaultValue={temple && temple.templeType}
              innerRef={
                register({
                  required: t('This field must be filled')
                })
              }
            />
            <span color="danger">{errors.templeType && errors.templeType.message}</span>
          </FormGroup>
          <FormGroup className="form-row">
            <Label htmlFor="name">{t('Temple Name')} <span className="text-danger">(*)</span></Label>
            <Input
              className="form-control btn-square"
              name="name"
              type="text"
              defaultValue={temple && temple.name}
              innerRef={
                register({
                  required: t('This field must be filled')
                })
              }
            />
            <span color="danger">{errors.name && errors.name.message}</span>
          </FormGroup>
          <FormGroup className="form-row">
            <Label htmlFor="code">{t('District')} <span className="text-danger">(*)</span></Label>
            <DistrictInput
              className="form-control btn-square"
              name="district"
              defaultValue={temple && temple.district}
              innerRef={
                register({
                  required: t('This field must be filled')
                })
              }
            />
            <span color="danger">{errors.district && errors.district.message}</span>
          </FormGroup>
          <FormGroup className="form-row">
            <Label htmlFor="code">{t('Sub-district')} <span className="text-danger">(*)</span></Label>
            <Input
              className="form-control btn-square"
              name="subDistrict"
              type="text"
              defaultValue={temple && temple.subDistrict}
              innerRef={
                register({
                  required: t('This field must be filled')
                })
              }
            />
            <span color="danger">{errors.subDistrict && errors.subDistrict.message}</span>
          </FormGroup>

          <FormGroup className="form-row">
            <Label htmlFor="code">{t('Address')}</Label>
            <Input
              className="form-control btn-square"
              name="address"
              type="text"
              defaultValue={temple && temple.address}
              innerRef={register}
            />
          </FormGroup>
          <FormGroup className="form-row">
            <Label htmlFor="code">Nama Pengelola</Label>
            <Input
              className="form-control btn-square"
              name="managerName"
              type="text"
              defaultValue={temple && temple.managerName}
              innerRef={register}
            />
          </FormGroup>
          <FormGroup className="form-row">
            <Label htmlFor="code">{t('Phone')}</Label>
            <Input
              className="form-control btn-square"
              name="phone"
              type="text"
              defaultValue={temple && temple.phone}
              innerRef={register}
            />
          </FormGroup>
          <FormGroup className="form-row">
            <Label htmlFor="code">{t('Holidays')}</Label>
            <Input
              className="form-control btn-square"
              name="holidays"
              type="textarea"
              defaultValue={temple && temple.holidays}
              innerRef={register}
            />
          </FormGroup>
          <FormGroup className="form-row">
            <Label htmlFor="code">{t('History')}</Label>
            <Input
              className="form-control btn-square"
              name="historyText"
              type="textarea"
              defaultValue={temple && temple.historyText}
              innerRef={register}
            />
          </FormGroup>
          <FormGroup className="form-row">
            <Label htmlFor="code">{t('Google Map Link')}</Label>
            <Input
              className="form-control btn-square"
              name="gmapLink"
              type="text"
              defaultValue={temple && temple.gmapLink}
              innerRef={register}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>{t('buttons_Cancel')}</Button>
        <Button color="primary" onClick={handleSubmit(onSubmitHandler)}>{t('buttons_Save')}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default translate(EditModal);
