import axios from 'axios';

import { apiUrl } from '../../app/config';
import * as RequestHelper from '../../util/RequestHelper';

/**
 * fetch Letter-numbers data from api
 */
class LetterNumberService {
  /**
   * fetch Letter-numbers list
   */
  static async fetchLetterNumbers() {
    const url = `${apiUrl}/letter-numbers`;

    const response = await axios.get(url);
    return response.data;
  }

  /**
   * fetch document info
   * @param string|number id letter_numbers.id or letter_numbers.code
   */
  static async fetchLetterNumber(id) {
    const url = `${apiUrl}/letter-numbers/${id}`;

    const response = await axios.get(url);
    return response.data;
  }

  static async addLetterNumber(data) {
    const url = `${apiUrl}/letter-numbers`;

    const response = await axios.post(url, data);
    return response.data;
  }

  static async updateLetterNumber(id, data) {
    const url = `${apiUrl}/letter-numbers/${id}`;

    const response = await axios.put(url, data);
    return response.data;
  }

  static async destroyLetterNumber(id) {
    const url = `${apiUrl}/letter-numbers/${id}`;

    const response = await axios.delete(url);
    return response.data;
  }

  /**
   * get report by daily or month
   * @param string timeScale (daily or monthly)
   * @param object queryParams
   *  - startDate (format DD/MM/YYYY)
   *  - endDate (format DD/MM/YYYY)
   *  - format = 'json' | 'xls',
   *  - status
   *  - showTotalOnly return total only
   *  - page
   *  - pagesize
   */
  static async reportIndex(timeScale, queryParams = {}, cancelToken = undefined) {
    const { format } = queryParams;

    const queryString = RequestHelper.getQueryStringFromParams(queryParams);

    const url = `${apiUrl}/letter-numbers/report/${timeScale}?${queryString}`;

    const options = {};
    if (cancelToken) {
      options.cancelToken = cancelToken;
    }

    if (format === 'xls') {
      options.responseType = 'blob';
    }

    const response = await axios.get(url, options);
    return response.data;
  }
}

export default LetterNumberService;
