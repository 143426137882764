import React from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';

import { GenderInput, PositionInput } from '../../../components/ui-kits';
import { updateUser } from '../userSlice';
import { showErrorAlert, showSuccessAlert } from '../../../app/alertResponse.handler';

const EditUserModal = ({ isOpen, user, onClose, t })  => {
  const { register, handleSubmit, control, errors } = useForm();
  const dispatch = useDispatch();

  const onSubmitHandler = (data) => {
    const { id } = user;

    dispatch(updateUser({ id, ...data })).then(({ error }) => {
      if (error) {
        showErrorAlert(error, { defaultMessage: 'Change data is failed' });
      } else {
        showSuccessAlert('Data has been changed successfully')
          .then(() => {
            onClose();
          });
      }
    })
    .catch((error) => {
      // TODO: need to show error here
      console.log(error);
    });
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered style={{maxWidth: '1000px', width: '80%'}}>
      <ModalHeader toggle={onClose}>
        {t('Edit User')}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
          <Row>
            <Col sm="6">
              <FormGroup className="form-row">
                <Label htmlFor="username">{t('Username')} <span className="text-danger">(*)</span></Label>
                <Input
                  className="form-control btn-square"
                  name="username"
                  type="text"
                  disabled={true}
                  defaultValue={user && user.username}
                />
              </FormGroup>

              <FormGroup className="form-row">
                <Label htmlFor="code">{t('Position')} <span className="text-danger">(*)</span></Label>
                <PositionInput
                  className="form-control btn-square"
                  name="positionId"
                  defaultValue={user && user.positionId}
                  innerRef={
                    register({
                      required: t('This field must be filled')
                    })
                  }
                />
                <span color="danger">{errors.positionId && errors.positionId.message}</span>
              </FormGroup>

              <FormGroup className="form-row">
                <Label htmlFor="gender">{t('Gender')} <span className="text-danger">(*)</span></Label>
                <GenderInput
                  className="form-control btn-square"
                  name="gender"
                  defaultValue={user && user.gender}
                  innerRef={
                    register({
                      required: t('This field must be filled')
                    })
                  }
                />
                <span color="danger">{errors.gender && errors.gender.message}</span>
              </FormGroup>

              <FormGroup className="form-row">
                <Label htmlFor="firstname">{t('Firstname')} <span className="text-danger">(*)</span></Label>
                <Input
                  className="form-control btn-square"
                  name="firstname"
                  type="text"
                  defaultValue={user && user.firstname}
                  maxLength={50}
                  innerRef={
                    register({
                      required: t('This field must be filled')
                    })
                  }
                />
                <span color="danger">{errors.firstname && errors.firstname.message}</span>
              </FormGroup>
              <FormGroup className="form-row mb-0">
                <Label htmlFor="lastname">{t('Lastname')}</Label>
                <Input
                  className="form-control btn-square"
                  name="lastname"
                  type="text"
                  defaultValue={user && user.lastname}
                  maxLength={50}
                  innerRef={register}
                  />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup className="form-row">
                <Label htmlFor="lastname">{t('Email')} <span className="text-danger">(*)</span></Label>
                <Input
                  className="form-control btn-square"
                  name="email"
                  type="text"
                  defaultValue={user && user.email}
                  maxLength={150}
                  innerRef={
                    register({
                      required: t('This field must be filled'),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: t('Invalid email address')
                      }
                    })
                  }
                  />
              </FormGroup>
              <FormGroup className="form-row">
                <Label htmlFor="phone">{t('Phone')}</Label>
                <Input
                  className="form-control btn-square"
                  name="phone"
                  type="text"
                  defaultValue={user && user.phone}
                  maxLength={15}
                  innerRef={register}
                  />
              </FormGroup>
              <FormGroup className="form-row">
                <Label htmlFor="subject">{t('Periodic Salary Increase Date')}</Label>
                <Controller
                  control={control}
                  render={({ onChange, onBlur, value, name, ref }) => (
                    <div className="col-12 px-0">
                      <DatePicker
                        selected={value}
                        onChange={e => onChange(e)}
                        name={name}
                        className="form-control digits"
                        dateFormat="dd/MM/yyyy"
                        />
                    </div>
                  )}
                  name="periodicSalaryIncreaseAt"
                  defaultValue={user && new Date(user.periodicSalaryIncreaseAt)}
                  innerRef={register}
                />
                <span className="text-danger">{errors.periodicSalaryIncreaseAt && errors.periodicSalaryIncreaseAt.message}</span>
              </FormGroup>
              <FormGroup className="form-row">
                <Label htmlFor="totalSalary">{t('Salary')}</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Rp</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="number"
                    name="totalSalary"
                    defaultValue={user && user.totalSalary}
                    innerRef={register}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className="form-row">
                <Label htmlFor="totalAllowance">{t('Allowance')}</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Rp</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="number"
                    name="totalAllowance"
                    defaultValue={user && user.totalAllowance}
                    innerRef={register}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>{t('buttons_Cancel')}</Button>
        <Button color="primary" onClick={handleSubmit(onSubmitHandler)}>{t('buttons_Save')}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default translate(EditUserModal);
