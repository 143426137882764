import React from 'react';
import { Input } from 'reactstrap';
import { useSelector } from 'react-redux';
import { t } from 'react-switch-lang';

const CourierInput = (props) => {
  const couriers = useSelector((state) => {
    if (Array.isArray(state.courier.couriers)) {
      // https://stackoverflow.com/questions/53420055/error-while-sorting-array-of-objects-cannot-assign-to-read-only-property-2-of/53420326
      return state.courier.couriers.slice().sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (b.name > a.name) {
          return -1;
        }
        return 0;
      });
    }

    return [];
  });

  return (
    <Input
      type="select"
      { ...props }
    >
      <option key={0} value="">{t('Please Select')}</option>
      {couriers.map(item =>
        <option key={item.id} value={item.id}>{item.name}</option>
      )};
    </Input>
  )
}

export default CourierInput;
