import axios from 'axios';

import { apiUrl } from '../../app/config';
import * as RequestHelper from '../../util/RequestHelper';

/**
 * service queue service
 */
class ServiceQueueService {
  static async updateServiceQueue(id, data) {
    const url = `${apiUrl}/service-queues/${id}`;

    const response = await axios.put(url, data);
    return response.data;
  }

  /**
   * fetch service queue list
   */
  static async fetchServiceQueues(queryParams = {}, cancelToken = undefined) {
    const queryString = RequestHelper.getQueryStringFromParams(queryParams);

    const url = queryString ? `${apiUrl}/service-queues?${queryString}` : `${apiUrl}/service-queues`;

    const options = {};
    if (cancelToken) {
      options.cancelToken = cancelToken;
    }

    const response = await axios.get(url, options);
    return response.data;
  }

  /**
   * fetch service queue by registration number
   */
   static async fetchServiceByRegistrationNumber(registrationNumber, cancelToken = undefined) {
    const url = `${apiUrl}/service-queues/${registrationNumber}`;

    const options = {};
    if (cancelToken) {
      options.cancelToken = cancelToken;
    }

    const response = await axios.get(url, options);
    return response.data;
  }

  /**
   * get report by daily or month
   * @param string timeScale (daily or monthly)
   * @param object queryParams
   *  - startDate (format DD/MM/YYYY)
   *  - endDate (format DD/MM/YYYY)
   *  - format = 'json' | 'xls',
   *  - status
   *  - showTotalOnly return total only
   *  - page
   *  - pagesize
   */
  static async reportIndex(timeScale, queryParams = {}, cancelToken = undefined) {
    const { format } = queryParams;

    const queryString = RequestHelper.getQueryStringFromParams(queryParams);

    const url = `${apiUrl}/service-queues/report/${timeScale}?${queryString}`;

    const options = {};
    if (cancelToken) {
      options.cancelToken = cancelToken;
    }

    if (format === 'xls') {
      options.responseType = 'blob';
    }

    const response = await axios.get(url, options);
    return response.data;
  }
}

export default ServiceQueueService;
