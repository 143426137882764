import React, { useState, useMemo } from 'react';
import { Container, Button, Row, Col, Card, CardBody, FormGroup, Label } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { t } from 'react-switch-lang';
import { toast, ToastContainer } from 'react-toastify';

import generateTableColumns from './settings/reportIndex-table-column';
import { Breadcrumb } from '../../components/layout';
import { MonthInput, YearLimitedInput } from '../../components/ui-kits';
import AttendanceService from './attendance.service';
import { saveXlsFile } from '../../util/FileSaverHelper';
import UserInput from '../user/UserInput';

const ReportIndex = (props) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userError, setUserError] = useState(undefined);
  const [monthFilter, setMonthFilter] = useState(new Date().getMonth() + 1);
  const [yearFilter, setYearFilter] = useState(new Date().getFullYear());
  const [reportRows, setReportRows] = useState([]);
  const [isRunningReport, setIsRunningReport] = useState(false);

  const tableColumns = useMemo(() => {
    return generateTableColumns();
  }, []);

  const onChangeMonthFilter = (value) => {
    setMonthFilter(value);
  }

  const onChangeYearFilter = (value) => {
    setYearFilter(value);
  }

  const onChangeUserInput = (user) => {
    if (user && user.id) {
      setSelectedUsers([user]);
      setUserError(undefined);
    }
  }

  const onRunReportClick = async (format = 'json') => {
    try {
      if (!Array.isArray(selectedUsers) || selectedUsers.length === 0) {
        setUserError(t('This field must be filled'));
        return false;
      }

      const result = await AttendanceService.reportIndex(monthFilter, yearFilter, selectedUsers[0].id, format);

      if (format === 'xls') {
        saveXlsFile(result, 'absensi.xlsx');
      } else {
        setIsRunningReport(true);
        setReportRows(result.result);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // dispatch(setError('Username or password is wrong!'));
      } else {
        toast.error('An error occured on server. Please try login or contact admin')
      }
    }
  }

  return (
    <React.Fragment>
      <Breadcrumb parent={t('Attendance')} headerTitle={t('Attendance')} title={t('Report')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <FormGroup className="form-row">
                  <Label className="col-sm-3 col-form-label" htmlFor="subject">{t('User')} <span className="text-danger">(*)</span></Label>
                  <Col sm="9">
                    <UserInput users={selectedUsers} onChange={onChangeUserInput} canEdit={true} />
                    <span className="text-danger">{userError}</span>
                  </Col>
                </FormGroup>
                <FormGroup className="form-row">
                  <Label className="col-sm-3" htmlFor="timeScale">{t('Month')}</Label><br />
                  <Col sm="9">
                    <MonthInput
                      name="month"
                      value={monthFilter}
                      onChange={onChangeMonthFilter}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="form-row">
                  <Label className="col-sm-3" htmlFor="timeScale">{t('Year')}</Label><br />
                  <Col sm="9">
                    <YearLimitedInput
                      name="year"
                      value={yearFilter}
                      onChange={onChangeYearFilter}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="form-row">
                  <Col sm={{ size: 9, offset: 3}}>
                    <Button className="mr-1" color="success" onClick={onRunReportClick}>{t('buttons_Run_Report')}</Button>
                    <Button color="dark" onClick={() => onRunReportClick('xls')}>{t('buttons_Xls_Export')}</Button>
                  </Col>
                </FormGroup>
              </CardBody>
            </Card>

            { isRunningReport &&
            <Card>
              <CardBody>
                <DataTable
                  data={reportRows}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  noHeader
                  noDataComponent={t('There are no records to display')}
                />
              </CardBody>
            </Card>
            }
          </Col>
        </Row>
      </Container>

      <ToastContainer />
    </React.Fragment>
  )
}

export default ReportIndex;
