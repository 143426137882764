import React from 'react';
import { t } from 'react-switch-lang';

import { STATUS_DONE } from '../../../app/constants';

const renderStatus = (row) => {
  const { status } = row;

  if (status === STATUS_DONE) {
    return <i className="fa fa-check fa-lg text-success"></i>
  }

  return <span className="fa fa-times fa-lg text-danger"></span>
}

const generateTableColumns = () => [
  {
    name: 'Id',
    selector: 'id',
    sortable: false,
    center: true,
    width: '65px',
  },
  {
    name: t('Description'),
    selector: (row, index) => (
      <React.Fragment>
      <span><b>{t('Subject')}</b> {row.meeting.subject}</span><br />
      <span><b>{t('Description')}</b> {row.description}</span><br />
      </React.Fragment>
    ),
    sortable: false,
    center: false,
    grow: 1,
    wrap: true,
  },
  {
    name: 'Status',
    selector: 'status',
    cell: (row) => renderStatus(row),
    sortable: false,
    center: true,
    width: '100px',
  }
];

export default generateTableColumns;
