import axios from 'axios';

import { apiUrl } from '../../app/config';
import * as RequestHelper from '../../util/RequestHelper';

/**
 * fetch serviceTypeApplications data from api
 */
class ServiceTypeApplicationService {
  /**
   * fetch serviceTypeConsultations list
   */
  static async fetchServiceTypeApplications(queryParams = {}, cancelToken = undefined) {
    const queryString = RequestHelper.getQueryStringFromParams(queryParams);

    const url = queryString ? `${apiUrl}/service-type-applications?${queryString}` : `${apiUrl}/service-type-applications`;

    const options = {};
    if (cancelToken) {
      options.cancelToken = cancelToken;
    }

    const response = await axios.get(url, options);
    return response.data;
  }

  /**
   * fetch serviceTypeApplication info
   * @param string|number id serviceTypeApplications.id or serviceTypeApplications.code
   */
  static async fetchServiceTypeApplication(id) {
    const url = `${apiUrl}/service-type-applications/${id}`;

    const response = await axios.get(url);
    return response.data;
  }

  static async addServiceTypeApplication(data) {
    const url = `${apiUrl}/service-type-applications`;

    const response = await axios.post(url, data);
    return response.data;
  }

  static async updateServiceTypeApplication(id, data) {
    const url = `${apiUrl}/service-type-applications/${id}`;

    const response = await axios.put(url, data);
    return response.data;
  }

  static async destroyServiceTypeApplication(id) {
    const url = `${apiUrl}/service-type-applications/${id}`;

    const response = await axios.delete(url);
    return response.data;
  }

  /**
   * fetch staff offday list
   */
  static async fetchTaskServiceTypeApplications() {
    const url = `${apiUrl}/tasks/serviceTypeApplication`;

    const response = await axios.get(url);
    return response.data;
  }
}

export default ServiceTypeApplicationService;
