import React from 'react';
import { Button } from 'reactstrap';
import { t } from 'react-switch-lang';

const generateTableColumns = (activateHandler, deactivateHandler) => [
  {
    name: 'Id',
    selector: 'id',
    sortable: false,
    center: false,
    width: '65px',
  },
  {
    name: t('Position Name'),
    selector: 'name',
    sortable: false,
    center: false,
    grow: 1,
  },
  {
    name: t('Position Code'),
    selector: 'code',
    sortable: false,
    center: false,
    width: '120px',
  },
  {
    name: t('Parent'),
    selector: (row, index) => `${row.parent ? row.parent.name : ''}`,
    sortable: false,
    center: false,
    grow: 1,
  },
  {
    name: 'Status',
    selector: 'isActive',
    cell: (row) => row.deletedAt ? <i className="fa fa-times fa-lg text-danger" title={t('InActive')}></i> : <i className="fa fa-check fa-lg text-success" title={t('Active')}></i>,
    sortable: false,
    center: true,
    width: '80px',
  },
  {
    name: t('Actions'),
    sortable: false,
    cell: row => (
      <React.Fragment>
        { row.deletedAt &&
        <Button onClick={() => activateHandler(row)} className="btn-square mr-1" color="success" size="xs"><i className={`fa fa-check`}></i> {t('buttons_Activate')}</Button>
        }
        { !row.deletedAt &&
        <Button onClick={() => deactivateHandler(row)} className="btn-square mr-1" color="danger" size="xs"><i className={`fa fa-trash`}></i> {t('buttons_Deactivate')}</Button>
        }
      </React.Fragment>
    ),
    wrap: true,
    width: '130px'
  }
];

export default generateTableColumns;
