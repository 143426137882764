import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import HolidayService from './holiday.service';
import { thunkHandler } from '../../app/thunk.handler';

export const fetchHolidays = createAsyncThunk('holidays/fetchHolidays', async (payload, thunkAPI) => {
  return thunkHandler(HolidayService.fetchHolidays(), thunkAPI);
});

export const destroyHoliday = createAsyncThunk('holidays/destroy', async (id, thunkAPI) => {
  return thunkHandler(HolidayService.destroyHoliday(id), thunkAPI);
});

export const addHoliday = createAsyncThunk('holidays/addHoliday', async (payload, thunkAPI) => {
  return thunkHandler(HolidayService.addHoliday(payload), thunkAPI);
});

export const updateHoliday = createAsyncThunk('holidays/updateHoliday', async (payload, thunkAPI) => {
  const { id, ...data } = payload;

  return thunkHandler(HolidayService.updateHoliday(id, data), thunkAPI);
});

const updateHolidaysState = (holidays, holiday) => {
  const updateHolidays = holidays.reduce((result, item) => {
    if (holiday.id && item.id === holiday.id) {
      result.push(holiday);
    } else {
      result.push(item);
    }

    return result;
  }, []);

  return updateHolidays;
}

const holidaySlice = createSlice({
  name: 'holiday',
  initialState: {
    holidays: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchHolidays.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchHolidays.fulfilled]: (state, action) => {
      state.loading = false;
      state.holidays = action.payload;
    },
    [fetchHolidays.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [destroyHoliday.pending]: (state, action) => {
      state.loading = true;
    },
    [destroyHoliday.fulfilled]: (state, action) => {
      state.loading = false;
      state.holidays = state.holidays.filter((item) => item.id !== action.payload.id);
    },
    [destroyHoliday.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [addHoliday.pending]: (state, action) => {
      state.loading = true;
    },
    [addHoliday.fulfilled]: (state, action) => {
      state.loading = false;
      state.holidays.unshift(action.payload);
    },
    [addHoliday.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [updateHoliday.pending]: (state, action) => {
      state.loading = true;
    },
    [updateHoliday.fulfilled]: (state, action) => {
      state.loading = false;
      state.holidays = updateHolidaysState(state.holidays, action.payload);
    },
    [updateHoliday.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  }
})

export default holidaySlice.reducer;
