import React, { useState } from 'react';
import { Button, Card, CardBody, CardFooter, Container, Col, Form, FormGroup, Label, Row, Input } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { addServiceTypeConsultation } from './serviceTypeConsultationSlice';
import { Breadcrumb } from '../../components/layout';
import { showErrorAlert, showSuccessAlert } from '../../app/alertResponse.handler';
import UserInput from '../user/UserInput';
import { ReligionInput } from '../../components/ui-kits';

const AddServiceTypeConsultation = ({ t }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userError, setUserError] = useState(undefined);
  const [isHajjService, setIsHajjService] = useState(false);
  const { register, reset, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();

  const onSubmitHandler = (data, e) => {
    if (!Array.isArray(selectedUsers) || selectedUsers.length === 0) {
      setUserError(t('This field must be filled'));
      return false;
    }

    dispatch(addServiceTypeConsultation({ handlerUserId: selectedUsers[0].id, isHajjService, ...data })).then((payload) => {
      const { error } = payload;
      if (error) {
        showErrorAlert(error, { defaultMessage: 'Add data is failed' });
      } else {
        showSuccessAlert('Data has been created successfully')
          .then(() => {
            clearForm();
          });
      }
    })
    .catch((error) => {
      // TODO: need to show error here
      console.log(error);
    });
  }

  const onChangeUserInput = (user) => {
    if (user && user.id) {
      setSelectedUsers([user]);
    }
  }

  /**
   * clear form after submit or just reset form
   */
  const clearForm = () => {
    reset();
  }

  return (
    <React.Fragment>
      <Breadcrumb parent={t('Service Type')} headerTitle={t('Add Service Type')} title={t('Add Service Type')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="name">{t('Name')} <span className="text-danger">(*)</span></Label>
                    <Col sm="9">
                      <Input
                        className="form-control btn-square"
                        name="name"
                        type="text"
                        innerRef={
                          register({
                            required: t('This field must be filled')
                          })
                        }
                      />
                      <span color="danger">{errors.name && errors.name.message}</span>
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="religion">{t('Religion')} <span className="text-danger">(*)</span></Label>
                    <Col sm="9">
                      <ReligionInput
                          className="form-control btn-square"
                          name="religion"
                          innerRef={
                            register({
                              required: t('This field must be filled')
                            })
                          }
                        />
                      <span color="danger">{errors.religion && errors.religion.message}</span>
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="handlerUser">{t('Handler Staff')} <span className="text-danger">(*)</span></Label>
                    <Col sm="9">
                      <UserInput users={selectedUsers} onChange={onChangeUserInput} canEdit={true} />
                      <span className="text-danger">{userError}</span>
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row mb-0">
                    <Col sm={{ size: 9, offset: 3}}>
                      <Label className="d-block" for="chk-is-hajj-service">
                        <Input className="checkbox_animated" id="chk-is-hajj-service" type="checkbox" checked={isHajjService} onChange={(e) => setIsHajjService(!isHajjService)} /> {t('Hajj Service')}
                      </Label>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Link to={`${process.env.PUBLIC_URL}/service-type-consultations`}>
                  <Button color="secondary" className="mr-2">{t('buttons_Back')}</Button>
                </Link>
                <Button color="primary" onClick={handleSubmit(onSubmitHandler)}>{t('buttons_Save')}</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default translate(AddServiceTypeConsultation);
