import axios from 'axios';

import { apiUrl } from '../../app/config';

/**
 * attachments service
 */
class AttachmentService {
  /**
   * fetch attachment
   */
  static async fetchAttachments(module, moduleId) {
    const url = `${apiUrl}/attachments/${module}/${moduleId}`;

    const response = await axios.get(url);
    return response.data;
  }

  static async addAttachment(module, moduleId, data) {
    const url = `${apiUrl}/attachments/${module}/${moduleId}`;

    const response = await axios.post(url, data);
    return response.data;
  }

  static async destroyAttachment(module, moduleId, filenameOrId) {
    const url = `${apiUrl}/attachments/${module}/${moduleId}/${filenameOrId}`;

    const response = await axios.delete(url);
    return response.data;
  }

  static async downloadAttachment(module, moduleId, filenameOrId) {
    const url = `${apiUrl}/images/${module}/${moduleId}/${filenameOrId}/1`;

    const response = await axios.get(url, { responseType: 'blob' });
    return response.data;
  }
}

export default AttachmentService;
