import React from 'react';
import { t } from 'react-switch-lang';

import { LETTER_DISPOSITED, LETTER_DONE } from '../../app/constants';

const LetterStatusInfo = ({ status }) => {
  const renderStatus = () => {
    if (status === LETTER_DISPOSITED) {
      return <i className="fa fa-paper-plane fa-lg text-info"> {t('letterStatus_DISPOSITED')}</i>
    }

    if (status === LETTER_DONE) {
      return <i className="fa fa-check fa-lg text-success"> {t('letterStatus_DONE')}</i>
    }

    return <span className="text-danger">{t('letterStatus_CREATED')}</span>
  }

  return (
    <>
    {renderStatus()}
    </>
  )
}

export default LetterStatusInfo;
