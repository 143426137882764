import React from 'react';
import { t } from 'react-switch-lang';
import { ListGroup, ListGroupItem } from 'reactstrap';

const ThreadList = ({ threads, loading }) => {

  const showList = () => {
    if (!Array.isArray(threads) || threads.length === 0) {
      return (
        <ListGroup className="mb-3 col-12">
          <ListGroupItem className="list-group-item-action">
          <b>{loading ? t('Loading') : t('No thread')}</b>
          </ListGroupItem>
        </ListGroup>
      )
    }

    return (
      <ListGroup className="mb-3 col-12">
        {threads.map(({ id, isQuestioner, userId, user, responseText }) =>
          <ListGroupItem className="list-group-item-action" key={id}>
            <i>{user?.fullName} menulis: </i><br />{responseText}
          </ListGroupItem>
        )}
      </ListGroup>
    )
  }

  return (
    <>
      {showList()}
    </>
  )
}

export default ThreadList;
