import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import PublicComplaintService from './publicComplaint.service';
import { thunkHandler } from '../../app/thunk.handler';

export const fetchTaskPublicComplaints = createAsyncThunk('publicComplaints/fetchTaskPublicComplaints', async (payload, thunkAPI) => {
  return thunkHandler(PublicComplaintService.fetchTaskPublicComplaints(payload), thunkAPI);
});

const publicComplaintSlice = createSlice({
  name: 'publicComplaint',
  initialState: {
    taskPublicComplaints: [],
    totalTaskPublicComplaints: 0,
    loading: false,
    error: null,
  },
  reducers: {
    updateStatusById(state, action) {
      const id = action.payload.id;
      const status = action.payload.status;

      const result = state.taskPublicComplaints.map((item) => {
        if (item.id === id) {
          item.status = status;
        }

        return item;
      });

      state.taskPublicComplaints = result;
    }
  },
  extraReducers: {
    [fetchTaskPublicComplaints.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchTaskPublicComplaints.fulfilled]: (state, action) => {
      state.loading = false;
      state.taskPublicComplaints = action.payload.result ? action.payload.result : []
      state.totalTaskPublicComplaints = action.payload.total ? action.payload.total : 0;
    },
    [fetchTaskPublicComplaints.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  }
})

export const { updateStatusById } = publicComplaintSlice.actions;

export default publicComplaintSlice.reducer;
