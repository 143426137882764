import React from 'react';
import { t } from 'react-switch-lang';

import { SERVICE_QUEUE_IN_PROGRESS, SERVICE_QUEUE_DONE } from '../../app/constants';

const ServiceQueueStatusInfo = ({ status }) => {
  const renderStatus = () => {
    if (status === SERVICE_QUEUE_IN_PROGRESS) {
      return <i className="fa fa-paper-plane fa-lg text-info"> {t('serviceQueue_IN_PROGRESS')}</i>
    }

    if (status === SERVICE_QUEUE_DONE) {
      return <i className="fa fa-check fa-lg text-success"> {t('serviceQueue_DONE')}</i>
    }

    return <span className="text-danger">{t('serviceQueue_CREATED')}</span>
  }

  return (
    <>
    {renderStatus()}
    </>
  )
}

export default ServiceQueueStatusInfo;
