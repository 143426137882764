import React, { useMemo, useState, useEffect } from 'react';
import { Button, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { translate } from 'react-switch-lang';
import DataTable from 'react-data-table-component';
import axios from 'axios';

import generateTableColumns from '../settings/searchUser-table-column';
import UserService from '../user.service';

const SearchUserModal = ({ isOpen, onClose, t })  => {
  const [users, setUsers] = useState([]);
  const [axiosSource, setAxiosSource] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const tableColumns = useMemo(() => {
    const selectHandler = (row) => {
      onClose(row);
    }

    return generateTableColumns(selectHandler);
  }, [onClose]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    setAxiosSource(source);
  }, [])

  const onKeywordChange = async (value) => {
    try {
      if (loading && axiosSource) {
        axiosSource.cancel('cancelled on keyword changed');
      }
      setLoading(true);
      const cancelToken = axiosSource ? axiosSource.cancelToken : undefined;
      const result = await UserService.fetchUsers({ keyword: value }, cancelToken);
      setUsers(result);
    } catch (error) {
      setUsers([]);
    }
    setLoading(false);
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="lg" centered style={{maxWidth: '1000px', width: '80%'}} autoFocus={false}>
      <ModalHeader toggle={onClose}>
        {t('Search User')}
      </ModalHeader>
      <ModalBody className="p-4">
        <FormGroup className="form-row">
          <Input
            className="form-control btn-square"
            name="keyword"
            type="text"
            placeholder={t('Input User Search')}
            onChange={(e) => onKeywordChange(e.target.value)}
            autoFocus={true}
          />
        </FormGroup>

        <hr />
        <DataTable
          data={users}
          columns={tableColumns}
          striped={true}
          center={true}
          selectableRows={false}
          selectableRowsHighlight
          noHeader
          pagination
          paginationServer
          noDataComponent={t('There are no records to display')}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>{t('buttons_Cancel')}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default translate(SearchUserModal);
