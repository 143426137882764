import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, ListGroup, ListGroupItem, Row, Col } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Dropzone from 'react-dropzone-uploader';

import { apiUrl, attachmentAcceptMimeType } from '../../../app/config';
import { updateLetterNumber } from '../letterNumberSlice';
import { addTypeaheadSenders } from '../../typeahead/typeaheadSlice';
import { SenderTypeaheadInput } from '../../../components/ui-kits';
import AttachmentService from '../../attachments/attachment.service';
import { showErrorAlert, showSuccessAlert, showConfirmationAlert } from '../../../app/alertResponse.handler';

const EditModal = ({ isOpen, letterNumber, onClose, t })  => {
  const [attachments, setAttachments] = useState([]);

  const jwtToken = useSelector(state => state.auth.jwtToken);

  const { register, handleSubmit, errors, control, getValues } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    setAttachments(letterNumber && Array.isArray(letterNumber.Attachments) ? letterNumber.Attachments : []);
  }, [letterNumber]);

  // https://react-dropzone-uploader.js.org/docs/api#getuploadparams
  const getUploadParams = ({ file, meta }) => {
    const body = new FormData()
    body.append('file', file);

    return {
      url: `${apiUrl}/attachments/letterNumber/${letterNumber?.id || 0}`,
      headers: {
        'Authorization' : `Bearer ${jwtToken}`
      },
      body,
    }
  }

  /**
   * handle response from upload attachment request
   */
  const handleChangeStatus = (file, status) => {
    if (status === 'done'){
      const response = JSON.parse(file.xhr.response);
      if (response?.filename) {
        setAttachments([...attachments, response]);
      }

      setTimeout(() => {
        file.remove()
      }, 5000);
    }
  };

  const onSubmitHandler = (data) => {
    const { id } = letterNumber;

    // typeahead is returning array and item object ([{id, name}])
    let receiverName = '';
    let isNewReceiverName = false;
    if (Array.isArray(data.receiverName) && data.receiverName.length > 0) {
      receiverName = data.receiverName[0].name;
      isNewReceiverName = data.receiverName[0].customOption === true;
    } else if (data.receiverName) {
      receiverName = data.receiverName;
    }

    // TODO: we should show error here that senderName is empty
    if (!receiverName) {
      return false;
    }

    dispatch(updateLetterNumber({ id, ...data, receiverName })).then(({ error }) => {
      if (error) {
        showErrorAlert(error, { defaultMessage: 'Change data is failed' });
      } else {
        showSuccessAlert('Data has been changed successfully')
          .then(() => {
            // register new typeahead for sender name
            if (isNewReceiverName) {
              dispatch(addTypeaheadSenders({ name: receiverName }));
            }

            onClose()
          });
      }
    })
    .catch((error) => {
      // TODO: need to show error here
      console.log(error);
    });
  }

  const removeAttachment = (id) => {
    showConfirmationAlert('Are you sure to delete attachment?')
      .then(async ({isConfirmed}) => {
        if (isConfirmed) {
          try {
            await AttachmentService.destroyAttachment('letterNumber', letterNumber?.id, id);

            const list = attachments.filter((attachment) => attachment.id !== id);
            setAttachments(list);
          } catch (error) {
            showErrorAlert(error, { defaultMessage: 'Delete attachment is failed' });
          }
        }
      });
  }

  const showAttachmentList = () => {
    if (!Array.isArray(attachments) || attachments.length === 0) {
      return (
        <ListGroup className="mb-3 col-12">
          <ListGroupItem className="list-group-item-action">
          <b>{t('Attachment not yet uploaded')}</b>
          </ListGroupItem>
        </ListGroup>
      )
    }

    return (
      <ListGroup className="mb-3 col-12">
        {attachments.map(({ originalname, id }) =>
          <ListGroupItem key={id} className="list-group-item-action">
            <i className="fa fa-file"></i>{originalname}
            <button type="button" className="float-right" onClick={() => removeAttachment(id)}>{t('buttons_Delete')}</button>
          </ListGroupItem>
        )}
      </ListGroup>
    )
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="lg" centered style={{maxWidth: '1600px', width: '80%'}}>
      <ModalHeader toggle={onClose}>
        {t('Edit Letter Number')}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
          <Row>
            <Col sm="7">
              <FormGroup className="form-row">
                <Label htmlFor="name">{t('Registration Number')} <span className="text-danger">(*)</span></Label>
                <Input
                  className="form-control btn-square"
                  name="name"
                  type="text"
                  disabled={true}
                  defaultValue={letterNumber && letterNumber.registrationNumber}
                />
              </FormGroup>

              <FormGroup className="form-row">
                <Label htmlFor="subject">{t('Subject')} <span className="text-danger">(*)</span></Label>
                <Input
                  className="form-control btn-square"
                  name="subject"
                  type="text"
                  defaultValue={letterNumber && letterNumber.subject}
                  innerRef={
                    register({
                      required: t('This field must be filled')
                    })
                  }
                />
                <span color="danger">{errors.subject && errors.subject.message}</span>
              </FormGroup>

              <FormGroup className="form-row">
                <Label htmlFor="receiverName">{t('Receiver Name')} <span className="text-danger">(*)</span></Label>
                <Controller
                  control={control}
                  render={({ onChange, onBlur, value, name, ref }) => (
                    <div className="col-12">
                      <SenderTypeaheadInput
                        defaultValue={letterNumber && letterNumber.receiverName}
                        onBlur={onBlur}
                        onChange={e => onChange(e)}
                        name={name}
                        />
                    </div>
                  )}
                  name="receiverName"
                  defaultValue={letterNumber && letterNumber.receiverName}
                  rules={{
                    required: t('This field must be filled'),
                    validate: (value) => {
                      const currentValue = getValues('receiverName');
                      if (Array.isArray(currentValue) && currentValue.length === 0) {
                        return t('This field must be filled');
                      }

                      return true;
                    },
                  }}
                />
                <span color="danger">{errors.receiverName && errors.receiverName.message}</span>
              </FormGroup>
            </Col>
            <Col sm="5">
              <FormGroup className="form-row">
                <Label htmlFor="attachment">{t('Attachments')} <span className="text-danger">(*)</span></Label>
                { showAttachmentList() }

                <div className="dz-message needsclick col-12">
                  <Dropzone
                    accept={attachmentAcceptMimeType}
                    getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    inputContent={`${t('Upload Document')} (PDF, PNG, JPG)`}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>{t('buttons_Cancel')}</Button>
        <Button color="primary" onClick={handleSubmit(onSubmitHandler)}>{t('buttons_Save')}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default translate(EditModal);
