import React from 'react';
import { ToastContainer } from 'react-toastify';
import Header from './layout/header';
import { Footer, Sidebar, TapTop } from './layout';

function App({ children }) {
  return (
    <React.Fragment>
      <TapTop/>
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header/>
        <div className="page-body-wrapper sidebar-icon">
          <Sidebar />
          <div className="page-body">
            { children }
          </div>
          <Footer/>
        </div>
      </div>
      <ToastContainer/>
    </React.Fragment>
  );
}

export default App;
