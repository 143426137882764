import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import UserService from './user.service';
import { thunkHandler } from '../../app/thunk.handler';

export const fetchUsers = createAsyncThunk('users/fetchUsers', async (payload, thunkAPI) => {
  return thunkHandler(UserService.fetchUsers(payload), thunkAPI);
});

export const activateUser = createAsyncThunk('users/activate', async (id, thunkAPI) => {
  return thunkHandler(UserService.activateUser(id), thunkAPI);
});

export const deactivateUser = createAsyncThunk('users/deactivate', async (id, thunkAPI) => {
  return thunkHandler(UserService.deactivateUser(id), thunkAPI);
});

export const changePassword = createAsyncThunk('users/changePassword', async (payload, thunkAPI) => {
  const { id, newPassword } = payload;

  return thunkHandler(UserService.changePassword(id, newPassword), thunkAPI);
});

export const addUser = createAsyncThunk('users/addUser', async (payload, thunkAPI) => {
  return thunkHandler(UserService.addUser(payload), thunkAPI);
});

export const updateUser = createAsyncThunk('users/updateUser', async (payload, thunkAPI) => {
  const { id, ...data } = payload;

  return thunkHandler(UserService.updateUser(id, data), thunkAPI);
});

const updateUsersState = (users, user) => {
  const updateUsers = users.reduce((result, item) => {
    if (user.id && item.id === user.id) {
      result.push(user);
    } else {
      result.push(item);
    }

    return result;
  }, []);

  return updateUsers;
}

const userSlice = createSlice({
  name: 'user',
  initialState: {
    users: [],
    totalUser: 0,
    userRoles: undefined,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchUsers.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.users = action.payload.result;
      state.totalUser = action.payload.total;
    },
    [fetchUsers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [activateUser.pending]: (state, action) => {
      state.loading = true;
    },
    [activateUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.users = updateUsersState(state.users, action.payload);
    },
    [activateUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [deactivateUser.pending]: (state, action) => {
      state.loading = true;
    },
    [deactivateUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.users = updateUsersState(state.users, action.payload);
    },
    [deactivateUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [changePassword.pending]: (state, action) => {
      state.loading = true;
    },
    [changePassword.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [changePassword.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [addUser.pending]: (state, action) => {
      state.loading = true;
    },
    [addUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.users.unshift(action.payload);
    },
    [addUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [updateUser.pending]: (state, action) => {
      state.loading = true;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.users = updateUsersState(state.users, action.payload);
    },
    [updateUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    }
  }
})

export default userSlice.reducer;
