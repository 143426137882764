import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { t } from 'react-switch-lang';
import { Info } from 'react-feather';
import nl2br from 'react-nl2br';

import { displayDateTime } from '../../../util/FormaterHelper';

const DetailModal = ({ isOpen, guestBook, onClose })  => {
  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={onClose} size="lg" centered>
        <ModalBody className="p-4">
          { guestBook &&
            <>
              <Row>
                <Col sm="8">
                <p><b>{t('Guest Book')}</b></p>
                </Col>
                <Col sm="4" className="text-right">
                <div className="border-bottom">{ displayDateTime(guestBook.createdAt) }</div>
                <small>{t('Input Date')}</small>
                </Col>
              </Row>

              <Row>
                <Col sm="12" className="mt-3">
                  <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                  <b>{t('Full Name')}</b><br />
                  {guestBook.fullName}
                </Col>
              </Row>

              <Row>
                <Col sm="12" className="mt-3">
                  <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                  <b>{t('Address')}</b><br />
                  {guestBook.address}
                </Col>
              </Row>

              <Row>
                <Col sm="12" className="mt-3">
                  <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                  <b>{t('Phone')}</b><br />
                  {guestBook.phone}
                </Col>
              </Row>

              <Row>
                <Col sm="12" className="mt-3">
                  <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                  <b>{t('Subject')}</b><br />
                  {guestBook.subject}
                </Col>
              </Row>

              <Row>
                <Col sm="12" className="mt-3">
                  <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                  <div className="float-left">
                    <b>{t('Comment')}</b><br />
                    {nl2br(guestBook.comment)}
                  </div>
                </Col>
              </Row>
            </>
          }
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClose}>{t('buttons_Close')}</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default DetailModal;
