import axios from 'axios';

import { apiUrl } from '../../app/config';

/**
 * fetch couriers data from api
 */
class CourierService {
  /**
   * fetch couriers list
   */
  static async fetchCouriers() {
    const url = `${apiUrl}/couriers`;

    const response = await axios.get(url);
    return response.data;
  }

  /**
   * fetch courier info
   * @param string|number id couriers.id or couriers.code
   */
  static async fetchCourier(id) {
    const url = `${apiUrl}/couriers/${id}`;

    const response = await axios.get(url);
    return response.data;
  }

  static async addCourier(data) {
    const url = `${apiUrl}/couriers`;

    const response = await axios.post(url, data);
    return response.data;
  }

  static async updateCourier(id, data) {
    const url = `${apiUrl}/couriers/${id}`;

    const response = await axios.put(url, data);
    return response.data;
  }

  static async activateCourier(id) {
    const url = `${apiUrl}/couriers/${id}`;

    const response = await axios.put(url, { active: true });
    return response.data;
  }

  static async deactivateCourier(id) {
    const url = `${apiUrl}/couriers/${id}`;

    const response = await axios.delete(url);
    return response.data;
  }
}

export default CourierService;
