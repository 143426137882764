import React from 'react';
import { Input } from 'reactstrap';
import { t } from 'react-switch-lang';

import { DISTRICTS } from '../../app/constants';

const DistrictInput = (props) => {
  const { excludeId, ...otherProps } = props;

  return (
    <Input
      type="select"
      { ...otherProps }
    >
      <option key={0} value="">{t('Please Select')}</option>
      {DISTRICTS.map(item =>
        <option key={item} value={item}>{item}</option>
      )};
    </Input>
  )
}

export default DistrictInput;
