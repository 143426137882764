import React, { useEffect, useState, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'sweetalert2';

import generateTableColumns from './settings/table-column';
import { Breadcrumb } from '../../components/layout';
import { DataTable } from '../../components/ui-kits';
import { fetchWorkUnits, activateWorkUnit, deactivateWorkUnit } from './workUnitSlice';
import { EditModal } from './modals';

const WorkUnitIndex = (props) => {
  const workUnits = useSelector(state => state.workUnit.workUnits);

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWorkUnits());
  }, [dispatch]);

  const tableColumns = useMemo(() => {
    const activateHandler = (row) => {
      SweetAlert.fire({
        title: props.t('Are you sure to activate this work unit?'),
        cancelButtonText: props.t('buttons_Cancel'),
        confirmButtonText: props.t('buttons_Activate'),
        reverseButtons: true,
        showCancelButton: true,
      }).then(({isConfirmed}) => {
        if (isConfirmed) {
          dispatch(activateWorkUnit(row.id));
        }
      });
    }

    const deactivateHandler = (row) => {
      SweetAlert.fire({
        title: props.t('Are you sure to deactivate this work unit?'),
        cancelButtonText: props.t('buttons_Cancel'),
        confirmButtonText: props.t('buttons_Deactivate'),
        reverseButtons: true,
        showCancelButton: true,
      }).then(({isConfirmed}) => {
        if (isConfirmed) {
          dispatch(deactivateWorkUnit(row.id));
        }
      });
    }

    return generateTableColumns(activateHandler, deactivateHandler);
  }, [dispatch, props])

  const editHandler = (row) => {
    setSelectedRow(row);
    setIsOpenEditModal(true);
  }

  const editModalOnCloseHandler = () => {
    setIsOpenEditModal(false);
  }

  const onRowClicked = (row) => {
    editHandler(row);
  }

  return (
    <React.Fragment>
      <Breadcrumb parent={props.t('Work Unit')} headerTitle={props.t('Work Unit Management')} title={props.t('Index')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTable
                  data={workUnits}
                  columns={tableColumns}
                  onRowClicked={onRowClicked}
                  highlightOnHover={true}
                  pointerOnHover={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <EditModal isOpen={isOpenEditModal} workUnit={selectedRow} onClose={editModalOnCloseHandler} />
      </Container>
    </React.Fragment>
  )
}

export default translate(WorkUnitIndex);
