import React from 'react';
import { Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, Input } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useForm } from 'react-hook-form';

import { showErrorAlert, showSuccessAlert} from '../../../app/alertResponse.handler';
import ReligiousConsultationService from '../../religiousConsultation/religiousConsultation.service';

const ReligiousConsultationReplyModal = ({ isOpen, id, onClose, t })  => {
  const { register, handleSubmit, errors } = useForm();

  const onSubmitHandler = async (data, e) => {
    try {
      const thread = await ReligiousConsultationService.createThread(id, data);

      showSuccessAlert('Your reply has been send successfully')
      .then(() => {
        onClose(thread);
      });
    } catch (error) {
      showErrorAlert(error, { defaultMessage: 'Update data is failed' })
    }
  }

  return (
    <Modal isOpen={isOpen} centered>
      <ModalBody className="p-4">
        <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
          <FormGroup className="form-row">
            <Label htmlFor="subject">{t('Your Reply')} <span className="text-danger">(*)</span></Label>
            <Input
              type="textarea"
              name="responseText"
              row="10"
              innerRef={
                register({
                  required: t('This field must be filled')
                })
              }
            />
            <span color="danger">{errors.responseText && errors.responseText.message}</span>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => onClose(false)}>{t('buttons_Cancel')}</Button>
        <Button color="success" onClick={handleSubmit(onSubmitHandler)}>{t('buttons_Send')}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default translate(ReligiousConsultationReplyModal);
