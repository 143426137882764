import React, { useEffect, useState, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { translate } from 'react-switch-lang';
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'sweetalert2';
import { Link } from 'react-router-dom';

import generateTableColumns from './settings/table-column';
import { Breadcrumb } from '../../components/layout';
import { fetchStaffOffdays, destroyStaffOffday } from './staffOffdaySlice';
import { EditModal } from './modals';

const StaffOffdayIndex = (props) => {
  const staffOffdays = useSelector(state => state.staffOffday.staffOffdays);

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const dispatch = useDispatch();

  const editHandler = (row) => {
    setSelectedRow(row);
    setIsOpenEditModal(true);
  }

  const editModalOnCloseHandler = () => {
    setIsOpenEditModal(false);
  }

  const staffOffdayPointHandler = (row) => {
    setSelectedRow(row);
  }

  const onRowClicked = (row) => {
    editHandler(row);
  }

  const tableColumns = useMemo(() => {
    const destroyHandler = (row) => {
      SweetAlert.fire({
        title: props.t('Are you sure to delete this staff offday?'),
        cancelButtonText: props.t('buttons_Cancel'),
        confirmButtonText: props.t('buttons_Delete'),
        reverseButtons: true,
        showCancelButton: true,
      }).then(({isConfirmed}) => {
        if (isConfirmed) {
          dispatch(destroyStaffOffday(row.id)).then(({ error }) => {
            if (error) {
              // get proper error message from server
              const errorMessage = error && error.message ? props.t(error.message) : props.t('Delete data is failed');

              // trigger error alert
              SweetAlert.fire({
                title: props.t('Error Occured'), text: errorMessage, icon: 'error'
              });
            } else {
              SweetAlert.fire({
                title: props.t('Success'), text: props.t('Data has been deleted successfully'), icon: 'success'
              });
            }
          });
        }
      });
    }

    return generateTableColumns(editHandler, destroyHandler, staffOffdayPointHandler);
  }, [dispatch, props])

  useEffect(() => {
    dispatch(fetchStaffOffdays());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Breadcrumb parent={props.t('Staff Offday')} headerTitle={props.t('Staff Offday Management')} title={props.t('Index')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTable
                  data={staffOffdays}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  selectableRows={false}
                  selectableRowsHighlight
                  noHeader
                  pagination
                  paginationServer
                  onRowClicked={onRowClicked}
                  highlightOnHover={true}
                  pointerOnHover={true}
                  noDataComponent={props.t('There are no records to display')}
                />

                <Row>
                  <Col sm="12" className="text-center mt-3">
                    <Link to={`${process.env.PUBLIC_URL}/staff-offdays/add`}>
                      <Button color="secondary" className="mx-auto">{props.t('buttons_Add_StaffOffday')}</Button>
                    </Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <EditModal isOpen={isOpenEditModal} staffOffday={selectedRow} onClose={editModalOnCloseHandler} />
      </Container>
    </React.Fragment>
  )
}

export default translate(StaffOffdayIndex);
