import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import WorkUnitService from './workUnit.service';
import { thunkHandler } from '../../app/thunk.handler';

export const fetchWorkUnits = createAsyncThunk('workUnits/fetchWorkUnits', async (payload, thunkAPI) => {
  return thunkHandler(WorkUnitService.fetchWorkUnits(), thunkAPI);
});

export const activateWorkUnit = createAsyncThunk('workUnits/activate', async (id, thunkAPI) => {
  return thunkHandler(WorkUnitService.activateWorkUnit(id), thunkAPI);
});

export const deactivateWorkUnit = createAsyncThunk('workUnits/deactivate', async (id, thunkAPI) => {
  return thunkHandler(WorkUnitService.deactivateWorkUnit(id), thunkAPI);
});

export const addWorkUnit = createAsyncThunk('workUnits/addWorkUnit', async (payload, thunkAPI) => {
  return thunkHandler(WorkUnitService.addWorkUnit(payload), thunkAPI);
});

export const updateWorkUnit = createAsyncThunk('workUnits/updateWorkUnit', async (payload, thunkAPI) => {
  const { id, ...data } = payload;

  return thunkHandler(WorkUnitService.updateWorkUnit(id, data), thunkAPI);
});

const updateWorkUnitsState = (workUnits, workUnit) => {
  const updateWorkUnits = workUnits.reduce((result, item) => {
    if (workUnit.id && item.id === workUnit.id) {
      result.push(workUnit);
    } else {
      result.push(item);
    }

    return result;
  }, []);

  return updateWorkUnits;
}

const workUnitSlice = createSlice({
  name: 'workUnit',
  initialState: {
    workUnits: undefined,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchWorkUnits.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchWorkUnits.fulfilled]: (state, action) => {
      state.loading = false;
      state.workUnits = action.payload;
    },
    [fetchWorkUnits.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [activateWorkUnit.pending]: (state, action) => {
      state.loading = true;
    },
    [activateWorkUnit.fulfilled]: (state, action) => {
      state.loading = false;
      state.workUnits = updateWorkUnitsState(state.workUnits, action.payload);
    },
    [activateWorkUnit.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [deactivateWorkUnit.pending]: (state, action) => {
      state.loading = true;
    },
    [deactivateWorkUnit.fulfilled]: (state, action) => {
      state.loading = false;
      state.workUnits = updateWorkUnitsState(state.workUnits, action.payload);
    },
    [deactivateWorkUnit.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [addWorkUnit.pending]: (state, action) => {
      state.loading = true;
    },
    [addWorkUnit.fulfilled]: (state, action) => {
      state.loading = false;
      state.workUnits.unshift(action.payload);
    },
    [addWorkUnit.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [updateWorkUnit.pending]: (state, action) => {
      state.loading = true;
    },
    [updateWorkUnit.fulfilled]: (state, action) => {
      state.loading = false;
      state.workUnits = updateWorkUnitsState(state.workUnits, action.payload);
    },
    [updateWorkUnit.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    }
  }
})

export default workUnitSlice.reducer;
