import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { updatePosition } from '../positionSlice';
import { PositionInput, WorkUnitInput } from '../../../components/ui-kits';
import { showErrorAlert, showSuccessAlert } from '../../../app/alertResponse.handler';

const EditModal = ({ isOpen, position, onClose, t })  => {
  const [canManageWorkUnit, setCanManageWorkUnit] = useState(false);
  const [canManageCourier, setCanManageCourier] = useState(false);
  const [canManagePosition, setCanManagePosition] = useState(false);
  const [canManageUser, setCanManageUser] = useState(false);
  const [canManageServiceType, setCanManageServiceType] = useState(false);
  const [canLetterAdministration, setCanLetterAdministration] = useState(false);
  const [canMeetingAdministration, setCanMeetingAdministration] = useState(false);
  const [canDocumentAdministration, setCanDocumentAdministration] = useState(false);
  const [canLetterNumberAdministration, setCanLetterNumberAdministration] = useState(false);
  const [canTempleAdministration, setCanTempleAdministration] = useState(false);
  const [canNewsAdministration, setCanNewsAdministration] = useState(false);
  const [canAttendanceAdministration, setCanAttendanceAdministration] = useState(false);
  const [canIKMReportAdministration, setCanIKMReportAdministration] = useState(false);
  const [canGuestBookReportAdministration, setCanGuestBookReportAdministration] = useState(false);
  const [canServiceApplicationReportAdministration, setCanServiceApplicationReportAdministration] = useState(false);
  const [canPublicComplaintReportAdministration, setCanPublicComplaintReportAdministration] = useState(false);
  const [canReligiousConsultationReportAdministration, setCanReligiousConsultationReportAdministration] = useState(false);
  const [canServiceQueueReportAdministration, setCanServiceQueueReportAdministration] = useState(false);
  const [canManageGeneralSetting, setCanManageGeneralSetting] = useState(false);
  const [canAccessUserLocationInfo, setCanAccessUserLocationInfo] = useState(false);
  const [canAccessPTSP, setCanAccessPTSP] = useState(false);

  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (position) {
      const { accessRights } = position;

      setCanManageWorkUnit(accessRights && accessRights.canManageWorkUnit === true);
      setCanManageCourier(accessRights && accessRights.canManageCourier === true);
      setCanManagePosition(accessRights && accessRights.canManagePosition === true);
      setCanManageUser(accessRights && accessRights.canManageUser === true);
      setCanManageServiceType(accessRights && accessRights.canManageServiceType === true);
      setCanLetterAdministration(accessRights && accessRights.canLetterAdministration === true);
      setCanMeetingAdministration(accessRights && accessRights.canMeetingAdministration === true);
      setCanDocumentAdministration(accessRights && accessRights.canDocumentAdministration === true);
      setCanLetterNumberAdministration(accessRights && accessRights.canLetterNumberAdministration === true);
      setCanTempleAdministration(accessRights && accessRights.canTempleAdministration === true);
      setCanNewsAdministration(accessRights && accessRights.canNewsAdministration === true);
      setCanAttendanceAdministration(accessRights && accessRights.canAttendanceAdministration === true);
      setCanIKMReportAdministration(accessRights && accessRights.canIKMReportAdministration === true);
      setCanGuestBookReportAdministration(accessRights && accessRights.canGuestBookReportAdministration === true);
      setCanServiceApplicationReportAdministration(accessRights && accessRights.canServiceApplicationReportAdministration === true);
      setCanPublicComplaintReportAdministration(accessRights && accessRights.canPublicComplaintReportAdministration === true);
      setCanReligiousConsultationReportAdministration(accessRights && accessRights.canReligiousConsultationReportAdministration === true);
      setCanManageGeneralSetting(accessRights && accessRights.canManageGeneralSetting === true);
      setCanAccessUserLocationInfo(accessRights && accessRights.canAccessUserLocationInfo === true);
      setCanServiceQueueReportAdministration(accessRights && accessRights.canServiceQueueReportAdministration === true);
    }
  }, [position]);

  const onSubmitHandler = (data) => {
    const { id } = position;
    const accessRights = {
      canManageWorkUnit,
      canManageCourier,
      canManagePosition,
      canManageUser,
      canManageServiceType,
      canLetterAdministration,
      canMeetingAdministration,
      canDocumentAdministration,
      canLetterNumberAdministration,
      canTempleAdministration,
      canNewsAdministration,
      canAttendanceAdministration,
      canIKMReportAdministration,
      canGuestBookReportAdministration,
      canServiceApplicationReportAdministration,
      canPublicComplaintReportAdministration,
      canReligiousConsultationReportAdministration,
      canServiceQueueReportAdministration,
      canManageGeneralSetting,
      canAccessUserLocationInfo,
      canAccessPTSP,
    };

    dispatch(updatePosition({ id, ...data, accessRights })).then(({ error }) => {
      if (error) {
        showErrorAlert(error, { defaultMessage: 'Change data is failed' });
      } else {
        showSuccessAlert('Data has been changed successfully')
          .then(() => {
            onClose();
          });
      }
    })
    .catch((error) => {
      // TODO: need to show error here
      console.log(error);
    });
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="lg" centered style={{maxWidth: '1000px', width: '80%'}}>
      <ModalHeader toggle={onClose}>
        {t('Edit Position')}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
          <FormGroup className="form-row">
            <Label htmlFor="name">{t('Position Name')} <span className="text-danger">(*)</span></Label>
            <Input
              className="form-control btn-square"
              name="name"
              type="text"
              defaultValue={position && position.name}
              innerRef={
                register({
                  required: t('This field must be filled')
                })
              }
            />
            <span color="danger">{errors.name && errors.name.message}</span>
          </FormGroup>

          <FormGroup className="form-row">
            <Label htmlFor="code">{t('Position Code')} <span className="text-danger">(*)</span></Label>
            <Input
              className="form-control btn-square"
              name="code"
              type="text"
              defaultValue={position && position.code}
              innerRef={
                register({
                  required: t('This field must be filled')
                })
              }
            />
            <span color="danger">{errors.code && errors.code.message}</span>
          </FormGroup>

          <FormGroup className="form-row">
            <Label htmlFor="code">{t('Parent')}</Label>
            <PositionInput
              className="form-control btn-square"
              name="parentId"
              defaultValue={position && position.parentId}
              innerRef={register}
              excludeId={position && position.id}
            />
          </FormGroup>

          <FormGroup className="form-row mb-0">
            <Label htmlFor="code">{t('Work Unit')}</Label>
            <WorkUnitInput
              className="form-control btn-square"
              name="workUnitId"
              defaultValue={position && position.workUnitId}
              innerRef={register}
            />
          </FormGroup>

          <h5 className="mt-5 mb-3 pb-3">{t('Access Rights')}</h5>
          <Row>
            <Col sm="4">
              <Label className="d-block" for="chk-manage_work_unit">
                <Input className="checkbox_animated" id="chk-manage_work_unit" type="checkbox" checked={canManageWorkUnit} onChange={(e) => setCanManageWorkUnit(!canManageWorkUnit)} /> {t('accessRight_manage_work_unit')}
              </Label>
              <Label className="d-block" for="chk-manage_position">
                <Input className="checkbox_animated" id="chk-manage_position" type="checkbox" checked={canManagePosition} onChange={(e) => setCanManagePosition(!canManagePosition)}/> {t('accessRight_manage_position')}
              </Label>
              <Label className="d-block" for="chk-manage_user">
                <Input className="checkbox_animated" id="chk-manage_user" type="checkbox" checked={canManageUser} onChange={(e) => setCanManageUser(!canManageUser)}/> {t('accessRight_manage_user')}
              </Label>
              <Label className="d-block" for="chk-manage_courier">
                <Input className="checkbox_animated" id="chk-manage_courier" type="checkbox" checked={canManageCourier} onChange={(e) => setCanManageCourier(!canManageCourier)}/> {t('accessRight_manage_courier')}
              </Label>
              <Label className="d-block" for="chk-manage_service_type">
                <Input className="checkbox_animated" id="chk-manage_service_type" type="checkbox" checked={canManageServiceType} onChange={(e) => setCanManageServiceType(!canManageServiceType)}/> {t('accessRight_manage_service_type')}
              </Label>
              <hr />
              <Label className="d-block" for="chk-manage_general_setting">
                <Input className="checkbox_animated" id="chk-manage_general_setting" type="checkbox" checked={canManageGeneralSetting} onChange={(e) => setCanManageGeneralSetting(!canManageGeneralSetting)}/> {t('accessRight_manage_general_setting')}
              </Label>
              <Label className="d-block" for="chk-can_access_user_location_info">
                <Input className="checkbox_animated" id="chk-can_access_user_location_info" type="checkbox" checked={canAccessUserLocationInfo} onChange={(e) => setCanAccessUserLocationInfo(!canAccessUserLocationInfo)}/> {t('accessRight_can_access_user_location_info')}
              </Label>
              <Label className="d-block" for="chk-can_access_ptsp">
                <Input className="checkbox_animated" id="chk-can_access_ptsp" type="checkbox" checked={canAccessPTSP} onChange={(e) => setCanAccessPTSP(!canAccessPTSP)}/> {t('accessRight_can_access_ptsp')}
              </Label>
            </Col>
            <Col>
              <Label className="d-block" for="chk-letter_administration">
                <Input className="checkbox_animated" id="chk-letter_administration" type="checkbox" checked={canLetterAdministration} onChange={(e) => setCanLetterAdministration(!canLetterAdministration)}/> {t('accessRight_letter_administration')}
              </Label>
              <Label className="d-block" for="chk-meeting_administration">
                <Input className="checkbox_animated" id="chk-meeting_administration" type="checkbox" checked={canMeetingAdministration} onChange={(e) => setCanMeetingAdministration(!canMeetingAdministration)}/> {t('accessRight_meeting_administration')}
              </Label>
              <Label className="d-block" for="chk-document_administration">
                <Input className="checkbox_animated" id="chk-document_administration" type="checkbox" checked={canDocumentAdministration} onChange={(e) => setCanDocumentAdministration(!canDocumentAdministration)}/> {t('accessRight_document_administration')}
              </Label>
              <Label className="d-block" for="chk-letter_number_administration">
                <Input className="checkbox_animated" id="chk-letter_number_administration" type="checkbox" checked={canLetterNumberAdministration} onChange={(e) => setCanLetterNumberAdministration(!canLetterNumberAdministration)}/> {t('accessRight_letter_number_administration')}
              </Label>
              <Label className="d-block" for="chk-temple_administration">
                <Input className="checkbox_animated" id="chk-temple_administration" type="checkbox" checked={canTempleAdministration} onChange={(e) => setCanTempleAdministration(!canTempleAdministration)}/> {t('accessRight_temple_administration')}
              </Label>
              <Label className="d-block" for="chk-news_administration">
                <Input className="checkbox_animated" id="chk-news_administration" type="checkbox" checked={canNewsAdministration} onChange={(e) => setCanNewsAdministration(!canNewsAdministration)}/> {t('accessRight_news_administration')}
              </Label>
              <Label className="d-block" for="chk-attendance_administration">
                <Input className="checkbox_animated" id="chk-attendance_administration" type="checkbox" checked={canAttendanceAdministration} onChange={(e) => setCanAttendanceAdministration(!canAttendanceAdministration)}/> {t('accessRight_attendance_administration')}
              </Label>
            </Col>
            <Col>
              <Label className="d-block" for="chk-ikm_report_administration">
                <Input className="checkbox_animated" id="chk-ikm_report_administration" type="checkbox" checked={canIKMReportAdministration} onChange={(e) => setCanIKMReportAdministration(!canIKMReportAdministration)}/> {t('accessRight_ikm_report_administration')}
              </Label>
              <Label className="d-block" for="chk-guest_book_report_administration">
                <Input className="checkbox_animated" id="chk-guest_book_report_administration" type="checkbox" checked={canGuestBookReportAdministration} onChange={(e) => setCanGuestBookReportAdministration(!canGuestBookReportAdministration)}/> {t('accessRight_guest_book_report_administration')}
              </Label>
              <Label className="d-block" for="chk-service_application_report_administration">
                <Input className="checkbox_animated" id="chk-service_application_report_administration" type="checkbox" checked={canServiceApplicationReportAdministration} onChange={(e) => setCanServiceApplicationReportAdministration(!canServiceApplicationReportAdministration)}/> {t('accessRight_service_application_report_administration')}
              </Label>
              <Label className="d-block" for="chk-public_complaint_report_administration">
                <Input className="checkbox_animated" id="chk-public_complaint_report_administration" type="checkbox" checked={canPublicComplaintReportAdministration} onChange={(e) => setCanPublicComplaintReportAdministration(!canPublicComplaintReportAdministration)}/> {t('accessRight_public_complaint_report_administration')}
              </Label>
              <Label className="d-block" for="chk-religious_consultation_report_administration">
                <Input className="checkbox_animated" id="chk-religious_consultation_report_administration" type="checkbox" checked={canReligiousConsultationReportAdministration} onChange={(e) => setCanReligiousConsultationReportAdministration(!canReligiousConsultationReportAdministration)}/> {t('accessRight_religious_consultation_report_administration')}
              </Label>
              <Label className="d-block" for="chk-service_queue_report_administration">
                <Input className="checkbox_animated" id="chk-service_queue_report_administration" type="checkbox" checked={canServiceQueueReportAdministration} onChange={(e) => setCanServiceQueueReportAdministration(!canServiceQueueReportAdministration)}/> {t('accessRight_service_queue_report_administration')}
              </Label>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>{t('buttons_Cancel')}</Button>
        <Button color="primary" onClick={handleSubmit(onSubmitHandler)}>{t('buttons_Save')}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default translate(EditModal);
