import React from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { changePassword } from '../userSlice';
import { showErrorAlert, showSuccessAlert } from '../../../app/alertResponse.handler';

const EditPasswordModal = ({ isOpen, user, onClose, t })  => {
  const { register, handleSubmit, getValues, errors } = useForm();
  const dispatch = useDispatch();

  const onSubmitHandler = (data) => {
    const { id } = user;
    const { password } = data;

    dispatch(changePassword({ id, newPassword: password })).then(({ error }) => {
      if (error) {
        showErrorAlert(error, { defaultMessage: 'Change password is failed' })
      } else {
        showSuccessAlert('Password has been changed successfully')
          .then(() => {
            onClose();
          });
      }
    })
    .catch((error) => {
      // TODO: need to show error here
      console.log(error);
    });
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalHeader toggle={onClose}>
        {t('Edit Password')}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
          <FormGroup className="form-row">
            <Label htmlFor="inputPassword1">{t('New Password')} <span className="text-danger">(*)</span></Label>
            <Input
              className="form-control btn-square"
              name="password"
              type="password"
              maxLength={50}
              innerRef={
                register({
                  required: t('This field must be filled'),
                  minLength: { value: 6, message: t('Password length must 6 characters minimum') }
                })
              }
            />
            <span color="danger">{errors.password && errors.password.message}</span>
          </FormGroup>
          <FormGroup className="form-row mb-0">
            <Label htmlFor="inputPassword2">{t('Repeat new password')} <span className="text-danger">(*)</span></Label>
            <Input
              className="form-control btn-square"
              name="password2"
              type="password"
              maxLength={50}
              innerRef={
                register({
                  validate: value => value === getValues('password') || t(`Confirm Password doesn't match`),
                  required: t('This field must be filled'),
                })
              }
              />
            <span>{errors.password2 && errors.password2.message}</span>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>{t('buttons_Cancel')}</Button>
        <Button color="primary" onClick={handleSubmit(onSubmitHandler)}>{t('buttons_Save')}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default translate(EditPasswordModal);
