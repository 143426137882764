import axios from 'axios';

import { apiUrl } from '../../app/config';
import * as RequestHelper from '../../util/RequestHelper';

/**
 * public complaint service
 */
class PublicComplaintService {
  static async updatePublicComplaint(id, data) {
    const url = `${apiUrl}/public-complaints/${id}`;

    const response = await axios.put(url, data);
    return response.data;
  }

  /**
   * fetch thread list
   */
  static async fetchTaskPublicComplaints(queryParams = {}, cancelToken = undefined) {
    const queryString = RequestHelper.getQueryStringFromParams(queryParams);

    const url = queryString ? `${apiUrl}/tasks/publicComplaint?${queryString}` : `${apiUrl}/tasks/publicComplaint`;

    const options = {};
    if (cancelToken) {
      options.cancelToken = cancelToken;
    }

    const response = await axios.get(url, options);
    return response.data;
  }

  static async fetchThreads(id) {
    const url = `${apiUrl}/public-complaints/threads/${id}`;

    const response = await axios.get(url);
    return response.data;
  }

  /**
   * create new reply
   * @param {*} id
   * @param {*} data
   */
  static async createThread(id, data) {
    const url = `${apiUrl}/public-complaints/thread/${id}`;

    const response = await axios.post(url, data);
    return response.data;
  }
  /**
   * get report by daily or month
   * @param string timeScale (daily or monthly)
   * @param object queryParams
   *  - startDate (format DD/MM/YYYY)
   *  - endDate (format DD/MM/YYYY)
   *  - format = 'json' | 'xls',
   *  - status
   *  - showTotalOnly return total only
   *  - page
   *  - pagesize
   */
   static async reportIndex(timeScale, queryParams = {}, cancelToken = undefined) {
    const { format } = queryParams;

    const queryString = RequestHelper.getQueryStringFromParams(queryParams);

    const url = `${apiUrl}/public-complaints/report/${timeScale}?${queryString}`;

    const options = {};
    if (cancelToken) {
      options.cancelToken = cancelToken;
    }

    if (format === 'xls') {
      options.responseType = 'blob';
    }

    const response = await axios.get(url, options);
    return response.data;
  }
}

export default PublicComplaintService;
