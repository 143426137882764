import axios from 'axios';

import { apiUrl } from '../../app/config';
import * as RequestHelper from '../../util/RequestHelper';

/**
 * fetch meetings data from api
 */
class MeetingService {
  /**
   * fetch meetings list
   */
  static async fetchMeetings(queryParams = {}, cancelToken = undefined) {
    const queryString = RequestHelper.getQueryStringFromParams(queryParams);

    const url = queryString ? `${apiUrl}/meetings?${queryString}` : `${apiUrl}/meetings`;

    const options = {};
    if (cancelToken) {
      options.cancelToken = cancelToken;
    }

    const response = await axios.get(url, options);
    return response.data;
  }

  /**
   * fetch meeting info
   * @param string|number id meetings.id or meetings.code
   */
  static async fetchMeeting(id) {
    const url = `${apiUrl}/meetings/${id}`;

    const response = await axios.get(url);
    return response.data;
  }

  static async addMeeting(data) {
    const url = `${apiUrl}/meetings`;

    const response = await axios.post(url, data);
    return response.data;
  }

  static async updateMeeting(id, data) {
    const url = `${apiUrl}/meetings/${id}`;

    const response = await axios.put(url, data);
    return response.data;
  }

  static async destroyMeeting(id) {
    const url = `${apiUrl}/meetings/${id}`;

    const response = await axios.delete(url);
    return response.data;
  }

  /**
   * fetch meeting histories list
   */
  static async fetchMeetingPoints(id) {
    const url = `${apiUrl}/meeting-points/${id}`;

    const response = await axios.get(url);
    return response.data;
  }

  static async addMeetingPoint(meetingId, data) {
    const url = `${apiUrl}/meeting-points/${meetingId}`;

    const response = await axios.post(url, data);
    return response.data;
  }

  static async editMeetingPoint(meetingId, id, data) {
    const url = `${apiUrl}/meeting-points/${meetingId}/${id}`;

    const response = await axios.put(url, data);
    return response.data;
  }

  static async destroyMeetingPoint(meetingId, id) {
    const url = `${apiUrl}/meeting-points/${meetingId}/${id}`;

    const response = await axios.delete(url);
    return response.data;
  }

  /**
   * fetch meetings list
   */
  static async fetchTaskMeetings() {
    const url = `${apiUrl}/tasks/meeting`;

    const response = await axios.get(url);
    return response.data;
  }
}

export default MeetingService;
