import axios from 'axios';

import { apiUrl } from '../../app/config';

/**
 * fetch news data from api
 */
class NewsService {
  /**
   * fetch news list
   */
  static async fetchNews() {
    const url = `${apiUrl}/news`;

    const response = await axios.get(url);
    return response.data;
  }

  /**
   * fetch news info
   * @param string|number id news.id
   */
  static async fetchNewsById(id) {
    const url = `${apiUrl}/news/${id}`;

    const response = await axios.get(url);
    return response.data;
  }

  static async addNews(data) {
    const url = `${apiUrl}/news`;

    const response = await axios.post(url, data);
    return response.data;
  }

  static async updateNews(id, data) {
    const url = `${apiUrl}/news/${id}`;

    const response = await axios.put(url, data);
    return response.data;
  }

  static async destroyNews(id) {
    const url = `${apiUrl}/news/${id}`;

    const response = await axios.delete(url);
    return response.data;
  }
}

export default NewsService;
