import React, { useEffect, useState, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { translate } from 'react-switch-lang';
import { useDispatch, useSelector } from 'react-redux';

import generateTableColumns from './settings/document-table-column';
import tableStyles from '../../app/general-table-styles';
import { Breadcrumb } from '../../components/layout';
import { fetchTaskDocuments } from '../document/documentSlice';
import { DocumentEditModal as EditModal } from './modals';

const DocumentIndex = (props) => {
  const documents = useSelector(state => state.document.taskDocuments);

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const dispatch = useDispatch();

  const editHandler = (row) => {
    setSelectedRow(row);
    setIsOpenEditModal(true);
  }

  const editModalOnCloseHandler = (success) => {
    setIsOpenEditModal(false);
  }

  const tableColumns = useMemo(() => {
    return generateTableColumns();
  }, [])

  useEffect(() => {
    dispatch(fetchTaskDocuments());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Breadcrumb parent={props.t('Task')} headerTitle={props.t('Document Agenda Management')} title={props.t('Document Agenda')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTable
                  data={documents}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  selectableRows={false}
                  selectableRowsHighlight
                  noHeader
                  pagination
                  paginationServer
                  onRowClicked={editHandler}
                  highlightOnHover={true}
                  pointerOnHover={true}
                  customStyles={tableStyles}
                  noDataComponent={props.t('There are no records to display')}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <EditModal isOpen={isOpenEditModal} document={selectedRow} onClose={editModalOnCloseHandler} />
      </Container>
    </React.Fragment>
  )
}

export default translate(DocumentIndex);
