import React, { useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { t } from 'react-switch-lang';

import TypeaheadService from '../../features/typeahead/typeahead.service';

const UserTypeaheadInput = ({ defaultValue, name, onBlur, onChange }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = async (query) => {
    if (!query) {
      return;
    }
    setIsLoading(true);

    try {
      const result = await TypeaheadService.fetchTypeaheadUsers(query);
      setOptions(result);
    } catch (error) {
      setOptions([]);
    }

    setIsLoading(false);
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  const onBlurFn = onBlur ? onBlur : () => {};
  const onChangeFn = onChange ? onChange : (e) => {};

  return (
    <AsyncTypeahead
      id="user-typeahead"
      onBlur={onBlurFn}
      onChange={onChangeFn}
      filterBy={filterBy}
      isLoading={isLoading}
      labelKey="name"
      name={name}
      clearButton
      multiple={false}
      onSearch={handleSearch}
      options={options}
      placeholder={t('Input User Search')}
      defaultInputValue={defaultValue}
    />
  )
}

export default UserTypeaheadInput;
