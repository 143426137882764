import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import MeetingService from './meeting.service';
import { thunkHandler } from '../../app/thunk.handler';

export const fetchMeetings = createAsyncThunk('meetings/fetchMeetings', async (payload, thunkAPI) => {
  return thunkHandler(MeetingService.fetchMeetings(payload), thunkAPI);
});

export const destroyMeeting = createAsyncThunk('meetings/destroy', async (id, thunkAPI) => {
  return thunkHandler(MeetingService.destroyMeeting(id), thunkAPI);
});

export const addMeeting = createAsyncThunk('meetings/addMeeting', async (payload, thunkAPI) => {
  return thunkHandler(MeetingService.addMeeting(payload), thunkAPI);
});

export const updateMeeting = createAsyncThunk('meetings/updateMeeting', async (payload, thunkAPI) => {
  const { id, ...data } = payload;

  return thunkHandler(MeetingService.updateMeeting(id, data), thunkAPI);
});

export const fetchTaskMeetings = createAsyncThunk('meetings/fetchTaskMeetings', async (payload, thunkAPI) => {
  return thunkHandler(MeetingService.fetchTaskMeetings(payload), thunkAPI);
});

export const updateTaskMeeting = createAsyncThunk('meetings/updateTaskMeeting', async (payload, thunkAPI) => {
  const { meetingId, id, ...data } = payload;

  return thunkHandler(MeetingService.editMeetingPoint(meetingId, id, data), thunkAPI);
});

const updateMeetingsState = (meetings, meeting) => {
  const updateMeetings = meetings.reduce((result, item) => {
    if (meeting.id && item.id === meeting.id) {
      result.push(meeting);
    } else {
      result.push(item);
    }

    return result;
  }, []);

  return updateMeetings;
}

const updateTaskMeetingsState = (taskMeetings, meeting) => {
  const updateMeetings = taskMeetings.reduce((result, item) => {
    if (meeting.id && item.id === meeting.id) {
      result.push(meeting);
    } else {
      result.push(item);
    }

    return result;
  }, []);

  return updateMeetings;
}

const meetingSlice = createSlice({
  name: 'meeting',
  initialState: {
    meetings: [],
    totalMeeting: 0,
    taskMeetings: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchMeetings.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchMeetings.fulfilled]: (state, action) => {
      state.loading = false;
      state.meetings = action.payload.result;
      state.totalMeeting = action.payload.total;
    },
    [fetchMeetings.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [destroyMeeting.pending]: (state, action) => {
      state.loading = true;
    },
    [destroyMeeting.fulfilled]: (state, action) => {
      state.loading = false;
      state.meetings = state.meetings.filter((item) => item.id !== action.payload.id);
    },
    [destroyMeeting.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [addMeeting.pending]: (state, action) => {
      state.loading = true;
    },
    [addMeeting.fulfilled]: (state, action) => {
      state.loading = false;
      state.meetings.unshift(action.payload);
    },
    [addMeeting.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [updateMeeting.pending]: (state, action) => {
      state.loading = true;
    },
    [updateMeeting.fulfilled]: (state, action) => {
      state.loading = false;
      state.meetings = updateMeetingsState(state.meetings, action.payload);
    },
    [updateMeeting.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [fetchTaskMeetings.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchTaskMeetings.fulfilled]: (state, action) => {
      state.loading = false;
      state.taskMeetings = action.payload.result ? action.payload.result : []
    },
    [fetchTaskMeetings.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [updateTaskMeeting.pending]: (state, action) => {
      state.loading = true;
    },
    [updateTaskMeeting.fulfilled]: (state, action) => {
      state.loading = false;
      state.meetings = updateTaskMeetingsState(state.taskMeetings, action.payload);
    },
    [updateTaskMeeting.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  }
})

export default meetingSlice.reducer;
