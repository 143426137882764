import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AUTH_JWT_TOKEN } from '../../app/constants';
import UserService from '../user/user.service';

export const getUserInfo = createAsyncThunk('auth/getUserInfo', async (username) => {
  return UserService.fetchUser(username);
})

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    jwtToken: localStorage.getItem(AUTH_JWT_TOKEN),
    userInfo: undefined,
    error: null,
  },
  reducers: {
    setJwt(state, action) {
      const jwtToken = action.payload
      state.jwtToken = jwtToken;
      // TODO: donot store in local storage
      localStorage.setItem('jwtToken', jwtToken);
    },
    deleteJwt(state, action) {
      delete state.jwtToken;
      delete state.userInfo;
      localStorage.removeItem(AUTH_JWT_TOKEN);
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setUserInfo(state, action) {
      state.userInfo = action.payload;
    }
  },
  extraReducers: {
    [getUserInfo.pending]: (state, action) => {
      state.loading = true;
    },
    [getUserInfo.fulfilled]: (state, action) => {
      state.loading = false;
      state.userInfo = action.payload;
    },
    [getUserInfo.rejected]: (state, action) => {
      state.loading = false;
    }
  }
})

export const { setJwt, deleteJwt, setError, setUserInfo } = authSlice.actions

export default authSlice.reducer;
