import React from 'react';
import { Input } from 'reactstrap';

const YearLimitedInput = ({ onChange, ...props }) => {
  const items = React.useMemo(() => {
    const firstYear = 2021;
    const lastYear = new Date().getFullYear();

    const years = [];
    for (let year = firstYear; year <= lastYear; year += 1) {
      years.push({
        id: year,
        name: year,
      });
    }

      return years;
  }, []);

  const onChangeHandler = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  }

  return (
    <Input
      type="select"
      onChange={onChangeHandler}
      { ...props }
    >
      {items.map(item =>
        <option key={item.id} value={item.id}>{item.name}</option>
      )};
    </Input>
  )
}

export default YearLimitedInput;
