import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import ServiceApplicationService from './serviceApplication.service';
import { thunkHandler } from '../../app/thunk.handler';

export const fetchTaskServiceApplications = createAsyncThunk('serviceApplications/fetchTaskServiceApplications', async (payload, thunkAPI) => {
  return thunkHandler(ServiceApplicationService.fetchTaskServiceApplications(payload), thunkAPI);
});

export const updateTaskServiceApplication = createAsyncThunk('serviceApplications/updateTaskServiceApplication', async (payload, thunkAPI) => {
  const { id, ...data } = payload;

  return thunkHandler(ServiceApplicationService.updateServiceApplication(id, data), thunkAPI);
});

const updateTaskServiceApplicationsState = (taskServiceApplications, serviceApplication) => {
  const updateServiceApplications = taskServiceApplications.reduce((result, item) => {
    if (serviceApplication.id && item.id === serviceApplication.id) {
      result.push(serviceApplication);
    } else {
      result.push(item);
    }

    return result;
  }, []);

  return updateServiceApplications;
}

const serviceApplicationSlice = createSlice({
  name: 'serviceApplication',
  initialState: {
    taskServiceApplications: [],
    totalTaskServiceApplications: 0,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchTaskServiceApplications.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchTaskServiceApplications.fulfilled]: (state, action) => {
      state.loading = false;
      state.taskServiceApplications = action.payload.result ? action.payload.result : [];
      state.totalTaskServiceApplications = action.payload.total ? action.payload.total : 0;
    },
    [fetchTaskServiceApplications.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [updateTaskServiceApplication.pending]: (state, action) => {
      state.loading = true;
    },
    [updateTaskServiceApplication.fulfilled]: (state, action) => {
      state.loading = false;
      state.taskServiceApplications = updateTaskServiceApplicationsState(state.taskServiceApplications, action.payload);
    },
    [updateTaskServiceApplication.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  }
})

export default serviceApplicationSlice.reducer;
