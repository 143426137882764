import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Input } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useDispatch } from 'react-redux';

import { updateTaskMeeting } from '../../meeting/meetingSlice';
import { showConfirmationAlert, showErrorAlert, showSuccessAlert} from '../../../app/alertResponse.handler';

const MeetingPointMarkDoneModal = ({ isOpen, meetingId, id, onClose, t })  => {
  const [responseText, setResponseText] = useState('');
  const dispatch = useDispatch();

  const onMarkDoneClickHandler = () => {
    showConfirmationAlert('Are you sure to mark this meeting point as done?')
    .then(({isConfirmed}) => {
      if (isConfirmed) {
        dispatch(updateTaskMeeting({ meetingId, id, responseText, action: 'markdone' })).then((payload) => {
          const { error } = payload;
          if (error) {
            showErrorAlert(error, { defaultMessage: 'Update data is failed' });
          } else {
            showSuccessAlert('This meeting point has been mark done successfully')
              .then(() => {
                onClose(true);
              });
          }
        })
        .catch((error) => {
          // TODO: need to show error here
          console.log(error);
        });
      }
    });
  }

  return (
    <Modal isOpen={isOpen} centered>
      <ModalBody className="p-4">
        <h4>{t('Your Response')}</h4>
        <Input type="textarea" placeholder={t('Can be left blank')} value={responseText} onChange={e => setResponseText(e.target.value)}></Input>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => onClose(false)}>{t('buttons_Cancel')}</Button>
        <Button color="success" onClick={onMarkDoneClickHandler}>{t('buttons_Mark_Done')}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default translate(MeetingPointMarkDoneModal);
