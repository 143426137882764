import React from 'react';
import { Input } from 'reactstrap';
import { t } from 'react-switch-lang';

// service application type
const ReligiousConsultationTypeFilterInput = ({ onChange, ...props }) => {
  const items = React.useMemo(() => {
    return [{
      id: 'all',
      name: t('All')
    }, {
      id: 'hajj',
      name: t('All Hajj Service')
    }, {
      id: 'nothajj',
      name: t('Other Than Hajj Service')
    }];
  }, []);

  const onChangeHandler = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  }

  return (
    <Input
      type="select"
      onChange={onChangeHandler}
      { ...props }
    >
      {items.map(item =>
        <option key={item.id} value={item.id}>{item.name}</option>
      )};
    </Input>
  )
}

export default ReligiousConsultationTypeFilterInput;
