import React, { useEffect, useState, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useDispatch, useSelector } from 'react-redux';

import generateTableColumns from './settings/table-column';
import { Breadcrumb } from '../../components/layout';
import { DataTable } from '../../components/ui-kits';
import { fetchCouriers } from './courierSlice';
import { EditModal } from './modals';

const CourierIndex = (props) => {
  const couriers = useSelector(state => state.courier.couriers);

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCouriers());
  }, [dispatch]);

  const tableColumns = useMemo(() => {
    return generateTableColumns();
  }, [])

  const editHandler = (row) => {
    setSelectedRow(row);
    setIsOpenEditModal(true);
  }

  const editModalOnCloseHandler = () => {
    setIsOpenEditModal(false);
  }

  const onRowClicked = (row) => {
    editHandler(row);
  }

  return (
    <React.Fragment>
      <Breadcrumb parent={props.t('Courier')} headerTitle={props.t('Courier Management')} title={props.t('Index')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTable
                  data={couriers}
                  columns={tableColumns}
                  onRowClicked={onRowClicked}
                  highlightOnHover={true}
                  pointerOnHover={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <EditModal isOpen={isOpenEditModal} courier={selectedRow} onClose={editModalOnCloseHandler} />
      </Container>
    </React.Fragment>
  )
}

export default translate(CourierIndex);
