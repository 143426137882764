import React from 'react';
import { Input } from 'reactstrap';
import { t } from 'react-switch-lang';

const CONSTANTS = require('../../app/constants');

const LetterStatusInput = (props) => {
  const { defaultLabel, ...otherProps } = props;

  const items = [
    { id: CONSTANTS.LETTER_CREATED, label: t('letterStatus_CREATED') },
    { id: CONSTANTS.LETTER_DISPOSITED, label: t('letterStatus_DISPOSITED') },
    { id: CONSTANTS.LETTER_DONE, label: t('letterStatus_DONE') },
  ];

  return (
    <Input
      type="select"
      { ...otherProps }
      placeholder="Test"
    >
      <option key={0} value="">{defaultLabel || t('Please Select')}</option>
      {items.map(item =>
        <option key={item.id} value={item.id}>{item.label}</option>
      )};
    </Input>
  )
}

export default LetterStatusInput;
