import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import NewsService from './news.service';
import { thunkHandler } from '../../app/thunk.handler';

export const fetchNews = createAsyncThunk('news/fetchNews', async (payload, thunkAPI) => {
  return thunkHandler(NewsService.fetchNews(), thunkAPI);
});

export const destroyNews = createAsyncThunk('news/destroy', async (id, thunkAPI) => {
  return thunkHandler(NewsService.destroyNews(id), thunkAPI);
});

export const addNews = createAsyncThunk('news/addNews', async (payload, thunkAPI) => {
  return thunkHandler(NewsService.addNews(payload), thunkAPI);
});

export const updateNews = createAsyncThunk('news/updateNews', async (payload, thunkAPI) => {
  const { id, ...data } = payload;

  return thunkHandler(NewsService.updateNews(id, data), thunkAPI);
});

const updateNewsState = (newsList, document) => {
  const updateNews = newsList.reduce((result, item) => {
    if (document.id && item.id === document.id) {
      result.push(document);
    } else {
      result.push(item);
    }

    return result;
  }, []);

  return updateNews;
}

const documentSlice = createSlice({
  name: 'document',
  initialState: {
    newsList: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchNews.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchNews.fulfilled]: (state, action) => {
      state.loading = false;
      state.newsList = action.payload;
    },
    [fetchNews.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [destroyNews.pending]: (state, action) => {
      state.loading = true;
    },
    [destroyNews.fulfilled]: (state, action) => {
      state.loading = false;
      state.newsList = state.newsList.filter((item) => item.id !== action.payload.id);
    },
    [destroyNews.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [addNews.pending]: (state, action) => {
      state.loading = true;
    },
    [addNews.fulfilled]: (state, action) => {
      state.loading = false;
      state.newsList.unshift(action.payload);
    },
    [addNews.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [updateNews.pending]: (state, action) => {
      state.loading = true;
    },
    [updateNews.fulfilled]: (state, action) => {
      state.loading = false;
      state.newsList = updateNewsState(state.newsList, action.payload);
    },
    [updateNews.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  }
})

export default documentSlice.reducer;
