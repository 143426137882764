import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { t } from 'react-switch-lang';
import { Info } from 'react-feather';
import nl2br from 'react-nl2br';
import path from 'path';

import { StatusInfo } from '../../components/ui-kits';
import AttachmentList from '../attachments/AttachmentList';
import { displayDateTime } from '../../util/FormaterHelper';

const DetailServiceApplication = (props) => {
  const { serviceApplication } = props;
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    if (serviceApplication && serviceApplication.id) {
      const { originalname, filename, mimetype } = serviceApplication;
      setAttachments([{
        originalname, filename: path.basename(filename), mimetype
      }]);
    }
  }, [serviceApplication]);

  return (
    <>
      { serviceApplication &&
        <Row>
          <Col sm="7">
            <Row>
              <Col sm="8">
              <p><b>{t('Registration Number')}</b> { serviceApplication.registrationNumber }</p>
              <StatusInfo status={serviceApplication.status} />
              </Col>
              <Col sm="4" className="text-right">
              <div className="border-bottom">{ displayDateTime(serviceApplication.createdAt) }</div>
              <small>{t('Input Date')}</small>
              </Col>
            </Row>

            <Row>
              <Col sm="12" className="mt-3">
                <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                <b>{t('Full Name')}</b><br />
                {serviceApplication.fullName}
              </Col>
            </Row>

            <Row>
              <Col sm="12" className="mt-3">
                <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                <b>{t('Address')}</b><br />
                {serviceApplication.address}
              </Col>
            </Row>

            <Row>
              <Col sm="12" className="mt-3">
                <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                <b>{t('Phone')}</b><br />
                {serviceApplication.phone}
              </Col>
            </Row>

            <Row>
              <Col sm="12" className="mt-3">
                <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                <b>{t('Email')}</b><br />
                {serviceApplication.email}
              </Col>
            </Row>

            <Row>
              <Col sm="12" className="mt-3">
                <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                <b>{t('Religion')}</b><br />
                {serviceApplication.religion}
              </Col>
            </Row>

            <Row>
              <Col sm="12" className="mt-3">
                <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                <b>{t('Application Type')}</b><br />
                {serviceApplication.applicationType}
              </Col>
            </Row>

            { serviceApplication?.finishedAt &&
            <Row>
              <Col sm="12" className="mt-3">
                <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                <div className="float-left">
                <b>{t('Finished Date')}</b><br />
                { displayDateTime(serviceApplication.finishedAt) }
                </div>
              </Col>
            </Row>
            }

            { serviceApplication?.responseText &&
            <Row>
              <Col sm="12" className="mt-3">
                <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
                <div className="float-left">
                <b>{t('Last Respond')}</b><br />
                {nl2br(serviceApplication.responseText)}
                </div>
              </Col>
            </Row>
            }
          </Col>
          <Col sm="5">
            <AttachmentList
              attachments={attachments}
              module="serviceApplication"
              moduleId={serviceApplication && serviceApplication.id}
            />
          </Col>
        </Row>
      }
    </>
  )
}

export default DetailServiceApplication;
