import React, { useState } from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { t } from 'react-switch-lang';

import { SearchUserModal } from './modals';

const UserInput = ({ users, onChange, canRemove, canEdit }) => {
  const [isOpenSearchUserModal, setIsOpenSearchUserModal] = useState(false);
  const onSelectHandler = () => {
    if (canEdit === true) {
      setIsOpenSearchUserModal(true);
    }
  }

  const onRemoveButtonHandler = (id) => {
    if (onChange) {
      onChange(null);
    }
  }

  const onCloseSearchUserModal = (result) => {
    if (result && onChange) {
      onChange(result);
    }

    setIsOpenSearchUserModal(false);
  }

  const showUserList = () => {
    if (!Array.isArray(users) || users.length === 0) {
      return (
        <ListGroup className="col-12">
          <ListGroupItem className="cursor-pointer list-group-item-action" key={0} onClick={() => onSelectHandler()}>
          <b>{t('Click to select user')}</b>
          </ListGroupItem>
        </ListGroup>
      )
    }

    return (
      <ListGroup className="col-12 px-0">
        {users.map(({ id, fullName }) =>
          <ListGroupItem className="cursor-pointer list-group-item-action" key={id} onClick={() => onSelectHandler()}>
            <i className="fa fa-user"></i> <b>{fullName}</b>
            {canRemove ? <button type="button" className="float-right" onClick={(e) => onRemoveButtonHandler(id)}>{t('buttons_Delete')}</button> : ''}
          </ListGroupItem>
        )}
      </ListGroup>
    )
  }

  return (
    <>
      {showUserList()}
      <SearchUserModal isOpen={isOpenSearchUserModal} onClose={onCloseSearchUserModal} />
    </>
  );
}

export default UserInput;
