import React from 'react';
import { Button, Card, CardBody, CardFooter, Container, Col, Form, FormGroup, Input, Label, Row, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';

import { addUser } from './userSlice';
import { Breadcrumb } from '../../components/layout';
import { GenderInput, PositionInput } from '../../components/ui-kits';
import { showErrorAlert, showSuccessAlert } from '../../app/alertResponse.handler';

const AddUser = ({ t }) => {
  const { register, reset, handleSubmit, control, errors } = useForm();
  const dispatch = useDispatch();

  const onSubmitHandler = (data, e) => {
    dispatch(addUser(data)).then(({ error }) => {
      if (error) {
        showErrorAlert(error, { defaultMessage: 'Add data is failed' });
      } else {
        showSuccessAlert('Data has been created successfully')
          .then(() => {
            reset(); // reset after form submit
          });
      }
    })
    .catch((error) => {
      // TODO: need to show error here
      console.log(error);
    });
  }

  return (
    <React.Fragment>
      <Breadcrumb parent={t('User')} headerTitle={t('Add User')} title={t('Add User')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="code">{t('Position')} <span className="text-danger">(*)</span></Label>
                    <Col sm="9">
                      <PositionInput
                        className="form-control btn-square"
                        name="positionId"
                        innerRef={
                          register({
                            required: t('This field must be filled')
                          })
                        }
                      />
                      <span color="danger">{errors.positionId && errors.positionId.message}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="username">{t('Username')} <span className="text-danger">(*)</span></Label>
                    <Col sm="9">
                      <Input
                        className="form-control btn-square"
                        name="username"
                        type="text"
                        maxLength={50}
                        innerRef={
                          register({
                            required: t('This field must be filled'),
                          })
                        }
                      />
                      <span color="danger">{errors.username && errors.username.message}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="password">{t('Password')} <span className="text-danger">(*)</span></Label>
                    <Col sm="9">
                      <Input
                        className="form-control btn-square"
                        name="password"
                        type="password"
                        maxLength={50}
                        innerRef={
                          register({
                            required: t('This field must be filled'),
                            minLength: { value: 6, message: t('Password length must 6 characters minimum') }
                          })
                        }
                      />
                      <span color="danger">{errors.password && errors.password.message}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="username">{t('Gender')} <span className="text-danger">(*)</span></Label>
                    <Col sm="9">
                      <GenderInput
                        className="form-control btn-square"
                        name="gender"
                        innerRef={
                          register({
                            required: t('This field must be filled')
                          })
                        }
                      />
                      <span color="danger">{errors.gender && errors.gender.message}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="firstname">{t('Firstname')} <span className="text-danger">(*)</span></Label>
                    <Col sm="9">
                      <Input
                        className="form-control btn-square"
                        name="firstname"
                        type="text"
                        maxLength={50}
                        innerRef={
                          register({
                            required: t('This field must be filled')
                          })
                        }
                      />
                      <span color="danger">{errors.firstname && errors.firstname.message}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="lastname">{t('Lastname')}</Label>
                    <Col sm="9">
                      <Input
                        className="form-control btn-square"
                        name="lastname"
                        type="text"
                        maxLength={50}
                        innerRef={register}
                        />
                    </Col>
                  </FormGroup>

                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="lastname">{t('Email')} <span className="text-danger">(*)</span></Label>
                    <Col sm="9">
                      <Input
                        className="form-control btn-square"
                        name="email"
                        type="text"
                        maxLength={150}
                        innerRef={
                          register({
                            required: t('This field must be filled'),
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: t('Invalid email address')
                            }
                          })
                        }
                        />
                      <span color="danger">{errors.email && errors.email.message}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup className="form-row mb-0">
                    <Label className="col-sm-3 col-form-label" htmlFor="phone">{t('Phone')}</Label>
                    <Col sm="9">
                      <Input
                        className="form-control"
                        name="phone"
                        type="text"
                        maxLength={15}
                        innerRef={register}
                        />
                    </Col>
                  </FormGroup>

                  <hr />

                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="subject">{t('Periodic Salary Increase Date')}</Label>
                    <Col sm="9">
                      <Controller
                        control={control}
                        render={({ onChange, onBlur, value, name, ref }) => (
                          <DatePicker
                            selected={value}
                            onChange={e => onChange(e)}
                            name={name}
                            className="form-control digits"
                            dateFormat="dd/MM/yyyy"
                            />
                        )}
                        name="periodicSalaryIncreaseAt"
                        defaultValue=""
                        innerRef={register}
                      />
                      <span className="text-danger">{errors.periodicSalaryIncreaseAt && errors.periodicSalaryIncreaseAt.message}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="totalSalary">{t('Salary')}</Label>
                    <Col sm="9">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Rp</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="number"
                          name="totalSalary"
                          innerRef={register}
                        />
                      </InputGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label className="col-sm-3 col-form-label" htmlFor="totalAllowance">{t('Allowance')}</Label>
                    <Col sm="9">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Rp</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="number"
                          name="totalAllowance"
                          innerRef={register}
                        />
                      </InputGroup>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button color="secondary" className="mr-2" onClick={() => reset()}>{t('buttons_Cancel')}</Button>
                <Button color="primary" onClick={handleSubmit(onSubmitHandler)}>{t('buttons_Save')}</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default translate(AddUser);
