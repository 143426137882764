import React from 'react';
import { Button } from 'reactstrap';
import { t } from 'react-switch-lang';

const generateTableColumns = (editHandler, deleteHandler) => [
  {
    name: 'Id',
    selector: 'id',
    sortable: false,
    center: false,
    width: '65px',
  },
  {
    name: t('Temple Name'),
    selector: 'name',
    sortable: false,
    center: false,
    grow: 1,
  },
  {
    name: t('Temple Type'),
    selector: 'templeType',
    sortable: false,
    center: false,
    width: '120px',
  },
  {
    name: t('District'),
    selector: 'district',
    sortable: false,
    center: false,
    width: '120px',
  },
  {
    name: t('Sub-district'),
    selector: 'subDistrict',
    sortable: false,
    center: false,
    width: '120px',
  },
  {
    name: t('Actions'),
    sortable: false,
    cell: row => (
      <React.Fragment>
        <Button onClick={() => editHandler(row)} className="btn-square mr-1" color="primary" size="xs"><i className={`fa fa-pencil`}></i> {t('buttons_Edit')}</Button>
        <Button onClick={() => deleteHandler(row)} className="btn-square mr-1" color="danger" size="xs"><i className={`fa fa-trash`}></i> {t('buttons_Delete')}</Button>
      </React.Fragment>
    ),
    wrap: true,
    width: '200px'
  }
];

export default generateTableColumns;
