import React, { useState } from 'react';
import { Button, Card, CardBody, Row, Col, Input } from 'reactstrap';
import { t } from 'react-switch-lang';

import { WorkUnitInput, PositionInput } from '../../components/ui-kits';

const UserSearchFilter = ({ onSearch }) => {
  const [keyword, setKeyword] = useState('');
  const [workUnitId, setWorkUnitId] = useState('');
  const [positionId, setPositionId] = useState('');

  const onSearchHandler = () => {
    if (onSearch) {
      onSearch({ keyword, workUnitId, positionId });
    }
  }

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      onSearchHandler();
    }
  }

  return (
    <Card className="rounded-0">
      <CardBody className="py-4">
        <Row>
          <Col>
            {t('Staff Name')}
            <Input
              className="btn-square"
              name="keyword"
              type="text"
              onChange={(e) => setKeyword(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </Col>
          <Col>
            {t('Work Unit')}
            <WorkUnitInput
              className="btn-square"
              defaultLabel={t('All')}
              onChange={(e) => setWorkUnitId(e.target.value)}
            />
          </Col>
          <Col>
            {t('Position')}
            <PositionInput
              className="btn-square"
              defaultLabel={t('All')}
              onChange={(e) => setPositionId(e.target.value)}
            />
          </Col>
          <Col>
            <Button color="info" style={{width: '100%', marginTop: 21}} onClick={onSearchHandler}>{t('buttons_Search')}</Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default UserSearchFilter;
