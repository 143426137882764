import React from 'react';
import { Col, Row, Button } from 'reactstrap';
import { translate } from 'react-switch-lang';
import nl2br from 'react-nl2br';

import { SERVICE_QUEUE_IN_PROGRESS, SERVICE_QUEUE_DONE } from '../../app/constants';
import StatusInfo from './ServiceQueueStatusInfo';

const DetailServiceQueue = ({ t, serviceQueue, onNextClick, isFormEnable }) => {
  const getStatusText = (status) => {
    if (status === SERVICE_QUEUE_IN_PROGRESS) {
      return <span className="text-info">{ t('serviceQueue_IN_PROGRESS') }</span>;
    } else if (status === SERVICE_QUEUE_DONE) {
      return <span className="text-success">{ t('serviceQueue_DONE') }</span>;
    } else {
      return <span className="text-danger">{ t('serviceQueue_CREATED') }</span>;
    }
  }
  return (
    <>
      <Row>
        <Col sm="3"><b>{t('Registration Number')}: </b></Col>
        <Col>{serviceQueue?.registrationNumber}</Col>
      </Row>
      <Row>
        <Col sm="3"><b>{t('Status')}: </b></Col>
        <Col><StatusInfo status={serviceQueue?.status} /></Col>
      </Row>
      <Row>
        <Col sm="3"><b>{t('Fullname')}: </b></Col>
        <Col>{serviceQueue?.fullname}</Col>
      </Row>
      <Row>
        <Col sm="3"><b>{t('Necessity')}: </b></Col>
        <Col>
          {nl2br(serviceQueue?.necessity)}
        </Col>
      </Row>
      { onNextClick && serviceQueue?.status !==SERVICE_QUEUE_DONE &&
      <Row>
        <Col>
          <hr />
          <Button color='primary' onClick={() => onNextClick()} disabled={!isFormEnable}>{t('buttons_Next')}</Button>
        </Col>
      </Row>
      }
    </>
  );
}

export default translate(DetailServiceQueue);
