import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import TempleService from './temple.service';
import { thunkHandler } from '../../app/thunk.handler';

export const fetchTemples = createAsyncThunk('temples/fetchTemples', async (payload, thunkAPI) => {
  return thunkHandler(TempleService.fetchTemples(), thunkAPI);
});

export const destroyTemple = createAsyncThunk('temples/destroy', async (id, thunkAPI) => {
  return thunkHandler(TempleService.destroyTemple(id), thunkAPI);
});

export const addTemple = createAsyncThunk('temples/addTemple', async (payload, thunkAPI) => {
  return thunkHandler(TempleService.addTemple(payload), thunkAPI);
});

export const updateTemple = createAsyncThunk('temples/updateTemple', async (payload, thunkAPI) => {
  const { id, ...data } = payload;

  return thunkHandler(TempleService.updateTemple(id, data), thunkAPI);
});

const updateTemplesState = (temples, temple) => {
  const updateTemples = temples.reduce((result, item) => {
    if (temple.id && item.id === temple.id) {
      result.push(temple);
    } else {
      result.push(item);
    }

    return result;
  }, []);

  return updateTemples;
}

const templeSlice = createSlice({
  name: 'temple',
  initialState: {
    temples: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchTemples.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchTemples.fulfilled]: (state, action) => {
      state.loading = false;
      state.temples = action.payload;
    },
    [fetchTemples.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [destroyTemple.pending]: (state, action) => {
      state.loading = true;
    },
    [destroyTemple.fulfilled]: (state, action) => {
      state.loading = false;
      state.temples = state.temples.filter((item) => item.id !== action.payload.id);
    },
    [destroyTemple.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },


    [addTemple.pending]: (state, action) => {
      state.loading = true;
    },
    [addTemple.fulfilled]: (state, action) => {
      state.loading = false;
      state.temples.unshift(action.payload);
    },
    [addTemple.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [updateTemple.pending]: (state, action) => {
      state.loading = true;
    },
    [updateTemple.fulfilled]: (state, action) => {
      state.loading = false;
      state.temples = updateTemplesState(state.temples, action.payload);
    },
    [updateTemple.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    }
  }
})

export default templeSlice.reducer;
