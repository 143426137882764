import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import TypeaheadService from './typeahead.service';
import { thunkHandler } from '../../app/thunk.handler';

export const fetchTypeaheadSenders = createAsyncThunk('typeahead/fetchTypeaheadSenders', async (payload, thunkAPI) => {
  return thunkHandler(TypeaheadService.fetchTypeaheadSenders(payload), thunkAPI);
});

export const addTypeaheadSenders = createAsyncThunk('typeahead/addTypeaheadSenders', async (payload, thunkAPI) => {
  return thunkHandler(TypeaheadService.addTypeaheadSenders(payload), thunkAPI);
});

const typeaheadSlice = createSlice({
  name: 'typeahead',
  initialState: {
    senders: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchTypeaheadSenders.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchTypeaheadSenders.fulfilled]: (state, action) => {
      state.loading = false;
      state.senders = action.payload;
    },
    [fetchTypeaheadSenders.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [addTypeaheadSenders.pending]: (state, action) => {
      state.loading = true;
    },
    [addTypeaheadSenders.fulfilled]: (state, action) => {
      state.loading = false;
      state.senders.push(action.payload);
    },
    [addTypeaheadSenders.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  }
})

export default typeaheadSlice.reducer;
