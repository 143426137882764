import React from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { updateWorkUnit } from '../workUnitSlice';
import { WorkUnitInput } from '../../../components/ui-kits';
import { showErrorAlert, showSuccessAlert } from '../../../app/alertResponse.handler';

const EditModal = ({ isOpen, workUnit, onClose, t })  => {
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();

  const onSubmitHandler = (data) => {
    const { id } = workUnit;

    dispatch(updateWorkUnit({ id, ...data })).then(({ error }) => {
      if (error) {
        showErrorAlert(error, { defaultMessage: 'Change data is failed' })
      } else {
        showSuccessAlert('Data has been changed successfully')
          .then(() => {
            onClose();
          });
      }
    })
    .catch((error) => {
      // TODO: need to show error here
      console.log(error);
    });
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalHeader toggle={onClose}>
        {t('Edit Work Unit')}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
          <FormGroup className="form-row">
            <Label htmlFor="name">{t('Work Unit Name')} <span className="text-danger">(*)</span></Label>
            <Input
              className="form-control btn-square"
              name="name"
              type="text"
              defaultValue={workUnit && workUnit.name}
              innerRef={
                register({
                  required: t('This field must be filled')
                })
              }
            />
            <span color="danger">{errors.name && errors.name.message}</span>
          </FormGroup>
          <FormGroup className="form-row">
            <Label htmlFor="code">{t('Work Unit Code')} <span className="text-danger">(*)</span></Label>
            <Input
              className="form-control btn-square"
              name="code"
              type="text"
              defaultValue={workUnit && workUnit.code}
              innerRef={
                register({
                  required: t('This field must be filled')
                })
              }
            />
            <span color="danger">{errors.code && errors.code.message}</span>
          </FormGroup>
          <FormGroup className="form-row mb-0">
            <Label htmlFor="code">{t('Parent')}</Label>
            <WorkUnitInput
              className="form-control btn-square"
              name="parentId"
              defaultValue={workUnit && workUnit.parentId}
              innerRef={register}
              excludeId={workUnit && workUnit.id}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>{t('buttons_Cancel')}</Button>
        <Button color="primary" onClick={handleSubmit(onSubmitHandler)}>{t('buttons_Save')}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default translate(EditModal);
