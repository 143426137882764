import { t } from 'react-switch-lang';

import { displayDateTime } from '../../../util/FormaterHelper';

const generateTableColumns = (keyName, showTotalOnly = true) => {
  if (showTotalOnly) {
    return [
      {
        name: keyName,
        selector: 'keyName',
        sortable: false,
        center: false,
        grow: 1,
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: false,
        center: true,
        width: '120px',
      }
    ];
  }

  return [
    {
      name: 'Id',
      selector: 'id',
      sortable: false,
      center: false,
      width: '65px',
    },
    {
      name: t('Registration Number'),
      selector: 'registrationNumber',
      sortable: false,
      center: false,
      grow: 1,
    },
    {
      name: t('Subject'),
      selector: 'subject',
      sortable: false,
      center: false,
      grow: 1,
    },
    {
      name: t('Receiver'),
      selector: 'receiverName',
      sortable: false,
      center: false,
      grow: 1,
    },
    {
      name: t('Date'),
      selector: (row) => displayDateTime(row.createdAt),
      sortable: false,
      center: false,
      grow: 1,
    },
  ];
};

export default generateTableColumns;
