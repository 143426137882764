import axios from 'axios';

import { apiUrl } from '../../app/config';

/**
 * fetch workUnits data from api
 */
class WorkUnitService {
  /**
   * fetch workUnits list
   */
  static async fetchWorkUnits() {
    const url = `${apiUrl}/work-units`;

    const response = await axios.get(url);
    return response.data;
  }

  /**
   * fetch workUnit info
   * @param string|number id workUnits.id or workUnits.code
   */
  static async fetchWorkUnit(id) {
    const url = `${apiUrl}/work-units/${id}`;

    const response = await axios.get(url);
    return response.data;
  }

  static async addWorkUnit(data) {
    const url = `${apiUrl}/work-units`;

    const response = await axios.post(url, data);
    return response.data;
  }

  static async updateWorkUnit(id, data) {
    const url = `${apiUrl}/work-units/${id}`;

    const response = await axios.put(url, data);
    return response.data;
  }

  static async activateWorkUnit(id) {
    const url = `${apiUrl}/work-units/${id}`;

    const response = await axios.put(url, { active: true });
    return response.data;
  }

  static async deactivateWorkUnit(id) {
    const url = `${apiUrl}/work-units/${id}`;

    const response = await axios.delete(url);
    return response.data;
  }
}

export default WorkUnitService;
