import axios from 'axios';

import { apiUrl } from '../../app/config';
import * as RequestHelper from '../../util/RequestHelper';

/**
 * fetch users data from api
 */
class UserService {
  /**
   * fetch users list
   */
  static async fetchUsers(queryParams = {}, cancelToken = undefined) {
    const queryString = RequestHelper.getQueryStringFromParams(queryParams);

    const url = queryString ? `${apiUrl}/users?${queryString}` : `${apiUrl}/users`;

    const options = {};
    if (cancelToken) {
      options.cancelToken = cancelToken;
    }

    const response = await axios.get(url, options);
    return response.data;
  }

  /**
   * fetch user info
   * @param string|number id users.id or users.username
   */
  static async fetchUser(id) {
    const url = `${apiUrl}/users/${id}`;

    const response = await axios.get(url);
    return response.data;
  }

  static async addUser(data) {
    const url = `${apiUrl}/users`;

    const response = await axios.post(url, data);
    return response.data;
  }

  static async updateUser(id, data) {
    const url = `${apiUrl}/users/${id}`;

    const response = await axios.put(url, data);
    return response.data;
  }

  static async activateUser(id) {
    const url = `${apiUrl}/users/${id}`;

    const response = await axios.put(url, { active: true });
    return response.data;
  }

  static async deactivateUser(id) {
    const url = `${apiUrl}/users/${id}`;

    const response = await axios.delete(url);
    return response.data;
  }

  static async changePassword(id, newPassword) {
    const url = `${apiUrl}/users/${id}`;

    const response = await axios.put(url, { newPassword });
    return response.data;
  }
}

export default UserService;
