import React from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { t } from 'react-switch-lang';
import nl2br from 'react-nl2br';
import { Info, Navigation, Send } from 'react-feather';

import LetterStatusInfo from './LetterStatusInfo';
import { getWorkUnitName, getPositionName } from '../../util/ModelReferenceHelper';
import AttachmentList from '../attachments/AttachmentList';
import { displayDateTime } from '../../util/FormaterHelper';

const DetailLetter = (props) => {
  const { letter } = props;
  const attachments = Array.isArray(props.attachments) ? props.attachments : [];

  const workUnits = useSelector(state => state.workUnit.workUnits);
  const positions = useSelector(state => state.position.positions);

  const showDispositionInformation = () => {
    if (letter && (letter.dispoWorkUnitId > 0 || letter.dispoPositionId > 0 || letter.dispoUserId > 0)) {
      let dispoName = '';
      if (letter.dispoWorkUnitId > 0) {
        dispoName = `${t('Work Unit')}: ${getWorkUnitName(letter.dispoWorkUnitId, workUnits)}`;
      } else if (letter.dispoWorkUnitId > 0) {
        dispoName = `${t('Position')}: ${getPositionName(letter.dispoWorkUnitId, positions)}`;
      } else if (letter.dispoUserId > 0 && letter.dispoUser?.fullName) {
        dispoName = letter.dispoUser.fullName;
      } else {
        dispoName = t('Unknown');
      }
      return (
        <Row>
          <Col sm="12" className="mt-3 text-info">
            <div style={{ width: 40, fontSize: 20}} className="float-left"><Navigation /></div>
            <b>{t('Transfer to')}</b><br />
            {dispoName}
          </Col>
        </Row>
      );
    }
  }

  return (
    <>
    { letter &&
      <Row>
        <Col sm="7">
          <Row>
            <Col sm="8">
            <p><b>{t('Registration Number')}</b> { letter.registrationNumber }</p>
            <LetterStatusInfo status={letter.status} />
            </Col>
            <Col sm="4" className="text-right">
            <div className="border-bottom">{ displayDateTime(letter.createdAt) }</div>
            <small>{t('Received Date')}</small>
            </Col>
          </Row>

          <Row>
            <Col sm="12" className="mt-3">
              <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
              <b>{t('Subject')}</b><br />
              {letter.subject}
            </Col>
          </Row>

          <Row>
            <Col sm="12" className="mt-3">
              <div style={{ width: 40, fontSize: 20}} className="float-left"><Send /></div>
              <b>{t('Sender')}</b><br />
              {letter.senderName}
            </Col>
          </Row>

          { letter?.responseText &&
          <Row>
            <Col sm="12" className="mt-3">
              <div style={{ width: 40, fontSize: 20}} className="float-left"><Info /></div>
              <div className="float-left">
              <b>{t('Last Respond')}</b><br />
              <blockquote>{nl2br(letter.responseText)}</blockquote>
              </div>
            </Col>
          </Row>
          }

          {showDispositionInformation()}
        </Col>
        <Col sm="5">
          <AttachmentList
            attachments={attachments}
            module="letter"
            moduleId={letter && letter.id}
            canRemove={false}
            loading={props.isLoadingAttachment}
          />
        </Col>
      </Row>
    }
    </>
  )
}

export default DetailLetter;
