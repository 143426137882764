import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { t } from 'react-switch-lang';
import { Document, Page, pdfjs } from 'react-pdf';

import { apiUrl } from '../../../app/config';

// https://github.com/wojtekmaj/react-pdf/issues/321
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewerModal = ({ isOpen, module, moduleId, filename, onClose })  => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const url = module ? `${apiUrl}/images/${module}/${moduleId}/${filename}` : `${apiUrl}/images/undefined/undefined/${filename}`;

  if (!filename) {
    return '';
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const changePage = (offset) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  const previousPage = () => {
    changePage(-1);
  }

  const nextPage = () => {
    changePage(1);
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="lg" centered>
      <ModalBody className="p-4 align-items-center">
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} renderAnnotationLayer={false} />
        </Document>

      </ModalBody>
      <ModalFooter>
        <Button color="light" disabled={pageNumber <= 1} onClick={previousPage}>&laquo; {t('buttons_Previous_Page')}</Button>
        <p>
          Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        </p>
        <Button color="light" disabled={pageNumber >= numPages} onClick={nextPage}>{t('buttons_Next_Page')} &raquo;</Button>
        <Button color="secondary" onClick={onClose}>{t('buttons_Close')}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default PdfViewerModal;
