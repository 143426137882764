import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useDispatch } from 'react-redux';

import { PublicComplaintReplyModal as ReplyModal } from './';
import * as CONSTANTS from '../../../app/constants';
import DetailPublicComplaint from '../../publicComplaint/DetailPublicComplaint';
import PublicComplaintService from '../../publicComplaint/publicComplaint.service';
import { updateStatusById } from '../../publicComplaint/publicComplaintSlice';

const EditModal = ({ isOpen, publicComplaint, onClose, t })  => {
  const [isReplyModalOpen, setIsReplyModalOpen] = useState(false);

  const dispatch = useDispatch();

  const onReplyClickHandler = () => {
    setIsReplyModalOpen(true);
  }

  const replyModalOnCloseHandler = (newThread) => {
    setIsReplyModalOpen(false);

    if (newThread) {
      if (newThread?.id) {
        setThreads([...threads, newThread]);
        dispatch(updateStatusById({ id: publicComplaint.id, status: 1 }));
      }
    }
  }

  const [isLoadingThread, setIsLoadingThread] = useState(false);
  const [threads, setThreads] = useState([]);

  useEffect(() => {
    const fetchThreads = async () => {
      const { id } = publicComplaint ? publicComplaint : {};

      if (id) {
        setIsLoadingThread(true);
        try {
          const result = await PublicComplaintService.fetchThreads(id);
          setThreads(result);
        } catch (error) {
          // TODO: do we need to show error here
        }

        setIsLoadingThread(false);
      }
    }

    fetchThreads();
  }, [publicComplaint]);

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={onClose} size="lg" centered style={{maxWidth: '1000px', width: '80%'}}>
        <ModalHeader toggle={onClose}>
          {t('Public Complaint')}
        </ModalHeader>
        <ModalBody className="p-4">
          <DetailPublicComplaint publicComplaint={publicComplaint} isLoadingThread={isLoadingThread} threads={threads} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClose}>{t('buttons_Close')}</Button>
          { publicComplaint?.status !== CONSTANTS.STATUS_DONE &&
          <React.Fragment>
            <Button color="success" onClick={onReplyClickHandler}>{t('buttons_Reply')}</Button>
          </React.Fragment>
          }
        </ModalFooter>
      </Modal>

      <ReplyModal isOpen={isReplyModalOpen} id={publicComplaint && publicComplaint.id} onClose={replyModalOnCloseHandler} />
    </React.Fragment>
  );
}

export default translate(EditModal);
