import React from 'react';
import { Button, Card, CardBody, CardFooter, Container, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { addWorkUnit } from './workUnitSlice';
import { Breadcrumb } from '../../components/layout';
import { WorkUnitInput } from '../../components/ui-kits';
import { showErrorAlert, showSuccessAlert } from '../../app/alertResponse.handler';

const AddWorkUnit = ({ t }) => {
  const { register, reset, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();

  const onSubmitHandler = (data, e) => {
    dispatch(addWorkUnit(data)).then(({ error }) => {
      if (error) {
        showErrorAlert(error, { defaultMessage: 'Add data is failed' })
      } else {
        showSuccessAlert('Data has been created successfully')
          .then(() => {
            reset(); // reset after form submit
          });
      }
    })
    .catch((error) => {
      // TODO: need to show error here
      console.log(error);
    });
  }

  return (
    <React.Fragment>
      <Breadcrumb parent={t('Work Unit')} headerTitle={t('Add Work Unit')} title={t('Add Work Unit')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitHandler)}>
                  <FormGroup className="form-row">
                    <Label className="col-sm-2 col-form-label" htmlFor="name">{t('Work Unit Name')} <span className="text-danger">(*)</span></Label>
                    <Col sm="10">
                      <Input
                        className="form-control btn-square"
                        name="name"
                        type="text"
                        innerRef={
                          register({
                            required: t('This field must be filled')
                          })
                        }
                      />
                      <span color="danger">{errors.name && errors.name.message}</span>
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row">
                    <Label className="col-sm-2 col-form-label" htmlFor="code">{t('Work Unit Code')} <span className="text-danger">(*)</span></Label>
                    <Col sm="10">
                      <Input
                        className="form-control btn-square"
                        name="code"
                        type="text"
                        innerRef={
                          register({
                            required: t('This field must be filled')
                          })
                        }
                      />
                      <span color="danger">{errors.code && errors.code.message}</span>
                    </Col>
                  </FormGroup>
                  <FormGroup className="form-row mb-0">
                    <Label className="col-sm-2 col-form-label" htmlFor="code">{t('Parent')}</Label>
                    <Col sm="10">
                      <WorkUnitInput
                        className="form-control btn-square"
                        name="parentId"
                        innerRef={register}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button color="secondary" className="mr-2" onClick={() => reset()}>{t('buttons_Cancel')}</Button>
                <Button color="primary" onClick={handleSubmit(onSubmitHandler)}>{t('buttons_Save')}</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default translate(AddWorkUnit);
