import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { Provider } from 'react-redux';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import store from './app/store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { Login, Logout } from './features/auth';
import AppRoutes from './components/AppRoutes';
import { setTranslations, setDefaultLanguage } from 'react-switch-lang';
import id from './i18n/id.json';
import axiosJwtInterceptor from './app/axios.jwt.interceptor';

axiosJwtInterceptor();

// Do this two lines only when setting up the application
setTranslations({ id });
setDefaultLanguage('id');

dayjs.extend(utc);

const Root = () => {
  return (
    <React.Fragment>
      <Provider store={store}>
        <Router basename="/">
          <Switch>
            <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />
            <Route path={`${process.env.PUBLIC_URL}/logout`} component={Logout} />

            <AppRoutes />

            <Redirect exact from={`${process.env.PUBLIC_URL}`} to={`${process.env.PUBLIC_URL}/dashboard`} />
          </Switch>
        </Router>
      </Provider>
    </React.Fragment>
  );
}

ReactDOM.render(<Root/>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
