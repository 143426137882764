import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { translate } from 'react-switch-lang';

import { Breadcrumb } from '../../components/layout';

const NotFound = (props) => {
  return (
    <React.Fragment>
      <Breadcrumb parent="Home" headerTitle={props.t('Not Found')} title={props.t('Index')} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                {props.t('The page that you are requested is not found!')}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default translate(NotFound);
