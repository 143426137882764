import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import LetterService from './letter.service';
import { thunkHandler } from '../../app/thunk.handler';

export const fetchLetters = createAsyncThunk('letters/fetchLetters', async (payload, thunkAPI) => {
  return thunkHandler(LetterService.fetchLetters(payload), thunkAPI);
});

export const destroyLetter = createAsyncThunk('letters/destroy', async (id, thunkAPI) => {
  return thunkHandler(LetterService.destroyLetter(id), thunkAPI);
});

export const addLetter = createAsyncThunk('letters/addLetter', async (payload, thunkAPI) => {
  return thunkHandler(LetterService.addLetter(payload), thunkAPI);
});

export const updateLetter = createAsyncThunk('letters/updateLetter', async (payload, thunkAPI) => {
  const { id, ...data } = payload;

  return thunkHandler(LetterService.updateLetter(id, data), thunkAPI);
});

export const fetchTaskLetters = createAsyncThunk('letters/fetchTaskLetters', async (payload, thunkAPI) => {
  return thunkHandler(LetterService.fetchTaskLetters(payload), thunkAPI);
});

const updateLettersState = (letters, letter) => {
  const updateLetters = letters.reduce((result, item) => {
    if (letter.id && item.id === letter.id) {
      result.push(letter);
    } else {
      result.push(item);
    }

    return result;
  }, []);

  return updateLetters;
}

const letterSlice = createSlice({
  name: 'letter',
  initialState: {
    letters: [],
    totalLetter: 0,
    taskLetters: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchLetters.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchLetters.fulfilled]: (state, action) => {
      state.loading = false;
      state.letters = action.payload.result;
      state.totalLetter = action.payload.total;
    },
    [fetchLetters.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [destroyLetter.pending]: (state, action) => {
      state.loading = true;
    },
    [destroyLetter.fulfilled]: (state, action) => {
      state.loading = false;
      state.letters = state.letters.filter((item) => item.id !== action.payload.id);
    },
    [destroyLetter.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [addLetter.pending]: (state, action) => {
      state.loading = true;
    },
    [addLetter.fulfilled]: (state, action) => {
      state.loading = false;
      state.letters.unshift(action.payload);
    },
    [addLetter.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [updateLetter.pending]: (state, action) => {
      state.loading = true;
    },
    [updateLetter.fulfilled]: (state, action) => {
      state.loading = false;
      state.letters = updateLettersState(state.letters, action.payload);
      state.taskLetters = updateLettersState(state.taskLetters, action.payload);
    },
    [updateLetter.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [fetchTaskLetters.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchTaskLetters.fulfilled]: (state, action) => {
      state.loading = false;
      state.taskLetters = action.payload.result ? action.payload.result : [];
    },
    [fetchTaskLetters.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  }
})

export default letterSlice.reducer;
