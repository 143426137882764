import React from 'react';
import { Button } from 'reactstrap';
import { t } from 'react-switch-lang';

const generateTableColumns = (loginUser, activateHandler, deactivateHandler, editPasswordHandler) => [
  {
    name: 'Id',
    selector: 'id',
    sortable: false,
    center: false,
    width: '80px',
  },
  {
    name: t('Fullname'),
    selector: (row, index) => `${row.firstname} ${row.lastname}`.trim(),
    sortable: false,
    center: false,
    grow: 1,
  },
  {
    name: t('Username'),
    selector: 'username',
    sortable: false,
    center: false,
    grow: 1,
  },
  {
    name: t('Position'),
    selector: (row, index) => `${row.Position ? row.Position.name : ''}`,
    sortable: false,
    center: false,
    grow: 1,
  },
  {
    name: 'Status',
    selector: 'isActive',
    cell: (row) => row.deletedAt ? <i className="fa fa-times fa-lg text-danger" title={t('InActive')}></i> : <i className="fa fa-check fa-lg text-success" title={t('Active')}></i>,
    sortable: false,
    center: true,
    width: '80px',
  },
  {
    name: t('Actions'),
    sortable: false,
    // login user cannot deactivate himself
    cell: row => (
      <React.Fragment>
        { row.deletedAt &&
        <Button onClick={() => activateHandler(row)} className="btn-square mr-1" color="success" size="xs"><i className={`fa fa-check`}></i> {t('buttons_Activate')}</Button>
        }
        { !row.deletedAt && (loginUser && row.id !== loginUser.id) &&
        <Button onClick={() => deactivateHandler(row)} className="btn-square mr-1" color="danger" size="xs"><i className={`fa fa-trash`}></i> {t('buttons_Deactivate')}</Button>
        }
        <Button onClick={() => editPasswordHandler(row)} className="btn-square" color="warning" size="xs"><i className={`fa fa-key`}></i> {t('buttons_Change Password')}</Button> &nbsp;
      </React.Fragment>
    ),
    wrap: true,
    width: '220px',
  }
];

export default generateTableColumns;
