import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { translate } from 'react-switch-lang';

import { MeetingPointMarkDoneModal as MarkDoneModal } from './';
import { STATUS_DONE } from '../../../app/constants';
import DetailMeetingPoint from '../../meeting/DetailMeetingPoint';

const MeetingPointEditModal = ({ isOpen, meetingPoint, onClose, t })  => {
  const [isMarkDoneModalOpen, setIsMarkDoneModalOpen] = useState(false);

  const onMarkDoneClickHandler = () => {
    setIsMarkDoneModalOpen(true);
  }

  const markDownModalOnCloseHandler = (success) => {
    setIsMarkDoneModalOpen(false);

    if (success) {
      onClose(true);
    }
  }

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={onClose} size="lg" centered style={{maxWidth: '1000px', width: '80%'}}>
        <ModalHeader toggle={onClose}>
          {t('Meeting Point')}
        </ModalHeader>
        <ModalBody className="p-4">
          <DetailMeetingPoint meetingPoint={meetingPoint} canUploadAttachment={true} canRemoveAttachment={true} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClose}>{t('buttons_Close')}</Button>
          { meetingPoint?.status !== STATUS_DONE &&
          <React.Fragment>
            <Button color="success" onClick={onMarkDoneClickHandler}>{t('buttons_Mark_Done')}</Button>
          </React.Fragment>
          }
        </ModalFooter>
      </Modal>

      <MarkDoneModal isOpen={isMarkDoneModalOpen} meetingId={meetingPoint && meetingPoint.meetingId} id={meetingPoint && meetingPoint.id} onClose={markDownModalOnCloseHandler} />
    </React.Fragment>
  );
}

export default translate(MeetingPointEditModal);
