import { Dashboard } from '../components/pages';
import AddUser from '../features/user/AddUser';
import UserIndex from '../features/user';
import AddCourier from '../features/courier/AddCourier';
import CourierIndex from '../features/courier';
import AddLetter from '../features/letter/AddLetter';
import LetterIndex from '../features/letter';
import LetterReportIndex from '../features/letter/reportIndex';
import AddPosition from '../features/position/AddPosition';
import PositionIndex from '../features/position';
import AddWorkUnit from '../features/workUnit/AddWorkUnit';
import WorkUnitIndex from '../features/workUnit';
import TaskLetterIndex from '../features/task/letterIndex';
import MyAccount from '../features/user/MyAccount';
import AddMeeting from '../features/meeting/AddMeeting';
import MeetingIndex from '../features/meeting';
import MeetingReportIndex from '../features/meeting/reportIndex';
import TaskMeetingIndex from '../features/task/meetingIndex';
import AddDocument from '../features/document/AddDocument';
import DocumentIndex from '../features/document';
import DocumentReportIndex from '../features/document/reportIndex';
import TaskDocumentIndex from '../features/task/documentIndex';
import AddLetterNumber from '../features/letterNumber/AddLetterNumber';
import LetterNumberIndex from '../features/letterNumber';
import LetterNumberReportIndex from '../features/letterNumber/reportIndex';
import AddTemple from '../features/temple/AddTemple';
import TempleIndex from '../features/temple';
import SatisfactionIndexReportIndex from '../features/satisfactionIndex/reportIndex';
import GuestBookReportIndex from '../features/guestBook/reportIndex';
import ServiceApplicationReportIndex from '../features/serviceApplication/reportIndex';
import TaskServiceApplicationIndex from '../features/task/serviceApplicationIndex';
import PublicComplaintReportIndex from '../features/publicComplaint/reportIndex';
import TaskPublicComplaintIndex from '../features/task/publicComplaintIndex';
import ReligiousConsultationReportIndex from '../features/religiousConsultation/reportIndex';
import TaskReligiousConsultationIndex from '../features/task/religiousConsultationIndex';
import TaskStaffOffdayIndex from '../features/task/staffOffdayIndex';
import SettingIndex from '../features/settings';
import AddNews from '../features/news/AddNews';
import NewsIndex from '../features/news';
import AddHoliday from '../features/holiday/AddHoliday';
import HolidayIndex from '../features/holiday';
import AddStaffOffday from '../features/staffOffday/AddStaffOffday';
import StaffOffdayIndex from '../features/staffOffday';
import AttendanceReportIndex from '../features/attendance/reportIndex';
import AddServiceTypeApplication from '../features/serviceTypeApplication/AddServiceTypeApplication';
import ServiceTypeApplicationIndex from '../features/serviceTypeApplication';
import AddServiceTypeConsultation from '../features/serviceTypeConsultation/AddServiceTypeConsultation';
import ServiceTypeConsultationIndex from '../features/serviceTypeConsultation';
import ServiceQueueReportIndex from '../features/serviceQueues/reportIndex';

const routes = [
  { path: '/dashboard', Component: Dashboard },
  { path: '/couriers/add', Component: AddCourier, accessRights: ['canManageCourier'] },
  { path: '/couriers', Component: CourierIndex, accessRights: ['canManageCourier'] },
  { path: '/letters/add', Component: AddLetter, accessRights: ['canLetterAdministration'] },
  { path: '/letters', Component: LetterIndex, accessRights: ['canLetterAdministration'] },
  { path: '/letters-report', Component: LetterReportIndex, accessRights: ['canLetterAdministration'] },
  { path: '/positions/add', Component: AddPosition, accessRights: ['canManagePosition'] },
  { path: '/positions', Component: PositionIndex, accessRights: ['canManagePosition'] },
  { path: '/tasks/letter', Component: TaskLetterIndex },
  { path: '/tasks/meeting', Component: TaskMeetingIndex },
  { path: '/tasks/document', Component: TaskDocumentIndex },
  { path: '/tasks/public-complaint', Component: TaskPublicComplaintIndex },
  { path: '/tasks/religious-consultation', Component: TaskReligiousConsultationIndex },
  { path: '/tasks/service-application', Component: TaskServiceApplicationIndex },
  { path: '/tasks/staff-offday', Component: TaskStaffOffdayIndex },
  { path: '/users/add', Component: AddUser, accessRights: ['canManageUser'] },
  { path: '/users', Component: UserIndex, accessRights: ['canManageUser'] },
  { path: '/work-units/add', Component: AddWorkUnit, accessRights: ['canManageWorkUnit'] },
  { path: '/work-units', Component: WorkUnitIndex, accessRights: ['canManageWorkUnit'] },
  { path: '/my-account', Component: MyAccount },
  { path: '/meetings/add', Component: AddMeeting, accessRights: ['canMeetingAdministration'] },
  { path: '/meetings', Component: MeetingIndex, accessRights: ['canMeetingAdministration'] },
  { path: '/meetings-report', Component: MeetingReportIndex, accessRights: ['canMeetingAdministration'] },
  { path: '/documents/add', Component: AddDocument, accessRights: ['canDocumentAdministration'] },
  { path: '/documents', Component: DocumentIndex, accessRights: ['canDocumentAdministration'] },
  { path: '/documents-report', Component: DocumentReportIndex, accessRights: ['canDocumentAdministration'] },
  { path: '/news/add', Component: AddNews, accessRights: ['canDocumentAdministration'] },
  { path: '/news', Component: NewsIndex, accessRights: ['canDocumentAdministration'] },
  { path: '/holidays/add', Component: AddHoliday, accessRights: ['canAttendanceAdministration'] },
  { path: '/holidays', Component: HolidayIndex, accessRights: ['canAttendanceAdministration'] },
  { path: '/staff-offdays/add', Component: AddStaffOffday, accessRights: ['canAttendanceAdministration'] },
  { path: '/staff-offdays', Component: StaffOffdayIndex, accessRights: ['canAttendanceAdministration'] },
  { path: '/attendances-report', Component: AttendanceReportIndex, accessRights: ['canAttendanceAdministration'] },
  { path: '/letter-numbers/add', Component: AddLetterNumber, accessRights: ['canLetterNumberAdministration'] },
  { path: '/letter-numbers', Component: LetterNumberIndex, accessRights: ['canLetterNumberAdministration'] },
  { path: '/letter-numbers-report', Component: LetterNumberReportIndex, accessRights: ['canLetterNumberAdministration'] },
  { path: '/temples/add', Component: AddTemple, accessRights: ['canTempleAdministration'] },
  { path: '/temples', Component: TempleIndex, accessRights: ['canTempleAdministration'] },
  { path: '/satisfaction-indices-report', Component: SatisfactionIndexReportIndex, accessRights: ['canIKMReportAdministration'] },
  { path: '/guest-books-report', Component: GuestBookReportIndex, accessRights: ['canGuestBookReportAdministration'] },
  { path: '/service-applications-report', Component: ServiceApplicationReportIndex, accessRights: ['canServiceApplicationReportAdministration'] },
  { path: '/public-complaints-report', Component: PublicComplaintReportIndex, accessRights: ['canPublicComplaintReportAdministration'] },
  { path: '/religious-consultations-report', Component: ReligiousConsultationReportIndex, accessRights: ['canReligiousConsultationReportAdministration'] },
  { path: '/service-queues-report', Component: ServiceQueueReportIndex, accessRights: ['canServiceQueueReportAdministration'] },
  { path: '/setting', Component: SettingIndex },
  { path: '/service-type-applications/add', Component: AddServiceTypeApplication, accessRights: ['canManageServiceType'] },
  { path: '/service-type-applications', Component: ServiceTypeApplicationIndex, accessRights: ['canManageServiceType'] },
  { path: '/service-type-consultations/add', Component: AddServiceTypeConsultation, accessRights: ['canManageServiceType'] },
  { path: '/service-type-consultations', Component: ServiceTypeConsultationIndex, accessRights: ['canManageServiceType'] },
];

export default routes;
