import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ListGroup, ListGroupItem } from 'reactstrap';
import { translate } from 'react-switch-lang';
import nl2br from 'react-nl2br';

import * as CONSTANTS from '../../../app/constants';
import LetterService from '../../letter/letter.service';
import { displayDateTime } from '../../../util/FormaterHelper';

const HistoryModal = ({ isOpen, id, onClose, t })  => {
  const [isLoading, setIsLoading] = useState(false);
  const [histories, setHistories] = useState([]);

  useEffect(() => {
    const fetchHistories = async () => {
      if (id) {
        setIsLoading(true);
        try {
          const result = await LetterService.fetchLetterHistories(id);
          setHistories(result);
        } catch (error) {
          // TODO: do we need to show error here
        }

        setIsLoading(false);
      }
    }

    fetchHistories();
  }, [id]);

  const showCreationHistory = (history) => {
    return (
      <React.Fragment>
      <div className="d-flex w-100 justify-content-between">
        <h5 className="mb-1">{t('Register Letter')}</h5><small>{displayDateTime(history.createdAt)}</small>
      </div>
      <small>{history.User?.fullName}</small>
      </React.Fragment>
    )
  }

  const showAddAttachmentHistory = (history) => {
    return (
      <React.Fragment>
      <div className="d-flex w-100 justify-content-between">
        <h5 className="mb-1">{t('Adding Attachment')}</h5><small>{displayDateTime(history.createdAt)}</small>
      </div>
      <p className="mb-1"><b>{t('Filename')}</b>: {history.payload?.filename}</p>
      <small>{history.User?.fullName}</small>
      </React.Fragment>
    )
  }

  const showDestroyAttachmentHistory = (history) => {
    return (
      <React.Fragment>
      <div className="d-flex w-100 justify-content-between">
        <h5 className="mb-1">{t('Deleting Attachment')}</h5><small>{displayDateTime(history.createdAt)}</small>
      </div>
      <p className="mb-1"><b>{t('Filename')}</b>: {history.payload?.filename}</p>
      <small>{history.User?.fullName}</small>
      </React.Fragment>
    )
  }

  const showDispositedHistory = (history) => {
    return (
      <React.Fragment>
      <div className="d-flex w-100 justify-content-between">
        <h5 className="mb-1">{t('Forwarding Letter')}</h5><small>{displayDateTime(history.createdAt)}</small>
      </div>
      <p className="mb-1"><b>{t('Instruction')}</b>: {nl2br(history.payload?.responseText)}</p>
      <small>{history.User?.fullName}</small>
      </React.Fragment>
    )
  }

  const showMarkDoneHistory = (history) => {
    return (
      <React.Fragment>
      <div className="d-flex w-100 justify-content-between">
        <h5 className="mb-1">{t('Mark As Done')}</h5><small>{displayDateTime(history.createdAt)}</small>
      </div>
      <p className="mb-1"><b>{t('Response')}</b>: {nl2br(history.payload?.responseText)}</p>
      <small>{history.User?.fullName}</small>
      </React.Fragment>
    )
  }

  const showUpdateHistory = (history) => {
    return (
      <React.Fragment>
      <div className="d-flex w-100 justify-content-between">
        <h5 className="mb-1">{t('Updating Letter Data')}</h5><small>{displayDateTime(history.createdAt)}</small>
      </div>
      <small>{history.User?.fullName}</small>
      </React.Fragment>
    )
  }

  const showHistoryList = () => {
    if (histories.length === 0) {
      return (
        <ListGroup className="mb-3 col-12">
          <ListGroupItem className="list-group-item-action">
          <b>{isLoading ? t('Loading') : t('No history data')}</b>
          </ListGroupItem>
        </ListGroup>
      )
    }

    return (
      <ListGroup className="mb-3 col-12">
        {histories.map(({ id, actionType, ...history }) =>
          <ListGroupItem key={id} className="list-group-item-action">
            { actionType === CONSTANTS.LETTER_HISTORY_CREATED && showCreationHistory(history) }
            { actionType === CONSTANTS.LETTER_HISTORY_ADD_ATTACHMENT && showAddAttachmentHistory(history)}
            { actionType === CONSTANTS.LETTER_HISTORY_DESTROY_ATTACHMENT && showDestroyAttachmentHistory(history)}
            { actionType === CONSTANTS.LETTER_HISTORY_DISPOSITED && showDispositedHistory(history)}
            { actionType === CONSTANTS.LETTER_HISTORY_MARK_DONE && showMarkDoneHistory(history)}
            { actionType === CONSTANTS.LETTER_HISTORY_UPDATE && showUpdateHistory(history)}
          </ListGroupItem>
        )}
      </ListGroup>
    )
  }

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={onClose} centered>
        <ModalHeader toggle={onClose}>
          {t('Activity Log')}
        </ModalHeader>
        <ModalBody className="p-4">
          {showHistoryList()}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClose}>{t('buttons_Close')}</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default translate(HistoryModal);
