import axios from 'axios';

import { apiUrl } from '../../app/config';

/**
 * fetch temples data from api
 */
class TempleService {
  /**
   * fetch temples list
   */
  static async fetchTemples() {
    const url = `${apiUrl}/temples`;

    const response = await axios.get(url);
    return response.data;
  }

  /**
   * fetch temple info
   * @param string|number id temples.id or temples.code
   */
  static async fetchTemple(id) {
    const url = `${apiUrl}/temples/${id}`;

    const response = await axios.get(url);
    return response.data;
  }

  static async addTemple(data) {
    const url = `${apiUrl}/temples`;

    const response = await axios.post(url, data);
    return response.data;
  }

  static async updateTemple(id, data) {
    const url = `${apiUrl}/temples/${id}`;

    const response = await axios.put(url, data);
    return response.data;
  }

  static async destroyTemple(id) {
    const url = `${apiUrl}/temples/${id}`;

    const response = await axios.delete(url);
    return response.data;
  }
}

export default TempleService;
