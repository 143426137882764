const generateTableColumns = (keyName) => [
  {
    name: keyName,
    selector: 'keyName',
    sortable: false,
    center: false,
    grow: 1,
  },
  {
    name: 'Total Sangat Puas',
    selector: 'totalRating5',
    sortable: false,
    center: true,
    width: '120px',
  },
  {
    name: 'Total Puas',
    selector: 'totalRating4',
    sortable: false,
    center: true,
    width: '120px',
  },
  {
    name: 'Total Biasa',
    selector: 'totalRating3',
    sortable: false,
    center: true,
    width: '120px',
  },
  {
    name: 'Total Kurang Puas',
    selector: 'totalRating2',
    sortable: false,
    center: true,
    width: '120px',
  },
  {
    name: 'Total Tidak Puas',
    selector: 'totalRating1',
    sortable: false,
    center: true,
    width: '120px',
  },
  {
    name: 'Total',
    selector: (row) => `${row.totalRating1 + row.totalRating2 + row.totalRating3 + row.totalRating4 + row.totalRating5}`,
    sortable: false,
    center: true,
    width: '120px',
  }
];

export default generateTableColumns;
